import { useLayoutEffect } from 'react';

import { MENU_BAR_HEIGHT } from 'app/utils/consts';
import { scrollTo } from 'app/utils/scrollTo';

export const useScrollStylistProfile = (condition?: boolean, dependencies: any[] = []) => {
  useLayoutEffect(() => {
    const profileElement = document.getElementById('stylist-profile');
    const additionalCheck = typeof condition === 'undefined' || condition;

    if (additionalCheck && profileElement) {

      const boundingRect: ClientRect = profileElement.getBoundingClientRect();
      const bodyWidth = document.body.offsetWidth;

      if (boundingRect.width !== bodyWidth || window.innerWidth > 990) {
        scrollTo(0)
        return;
      }

      if (boundingRect.height) {
        const wrapperTop = boundingRect.height - MENU_BAR_HEIGHT;
        scrollTo(wrapperTop);
      }
    }
  }, dependencies);
};

export default useScrollStylistProfile;
