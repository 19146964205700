import { useStylistRepository } from 'app/repository/StylistRepository';
import { UserContext } from 'app/context/UserContext';
import { ROUTES } from 'app/utils/routes';
import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'app/components/button/Button';
import { UserRadioView } from './UserRadioView';
import styled, { css } from 'app/theme'
import firebase from 'firebase/app';
import { normalizePhone, phoneParseSimple } from 'app/utils/phoneParse';

export const UserSelectionFormStyles = styled.div`
    ${({ theme: { variables } }) => css`
        background-color: ${variables.colors.white};
        width: 100%;
        max-width: 475px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 24px;
        border: 1px solid ${variables.colors.neutral1};
        border-radius: 4px;
        display: flex;
        flex-direction: column;

        .header-title {
          color: ${variables.colors.infoGray};
          font-size: 14px;
          font-weight: 600;
          line-height: 1.5;
          padding: 24px;
          border-bottom: 1px solid ${variables.colors.neutral1};

          span {
            color: ${variables.colors.black};
          }

        }
    `}
`;

export type UserSelectionFormProps = {
  showSignOutButton?: boolean,
  onUserSelected: (profile: any) => void,
  onClickSignOut: () => void
}

export const UserSelectionForm = ({ 
  showSignOutButton = true,
  onUserSelected,
  onClickSignOut
}: UserSelectionFormProps) => {

  const history = useHistory()
  const stylistRepo = useStylistRepository()
  const username = stylistRepo.getStylistUsername()

  const { currentProfile, setCurrentProfile, profiles } = useContext(UserContext)

  useEffect(() => {
    onUserSelected(currentProfile)
  }, [currentProfile])

  const phoneNumber = () => {
    const user = firebase.auth().currentUser
    if (user && user.phoneNumber) {
      const simplePhone = phoneParseSimple(user.phoneNumber).substring(1)
      return normalizePhone(simplePhone)
    } else {
      return '---'
    }
  }

  return (
    <UserSelectionFormStyles>
      <p className="header-title">Logged in with <span>{phoneNumber()}</span><br></br>Booking appointments for:</p>
      <form>
        {profiles.map((profile) => {
          return (
            <UserRadioView 
              key={profile.id}
              profile={profile}
              checked={currentProfile.id === profile.id}
              onChange={(profile) => setCurrentProfile(profile)} />
          )
        })}
      </form>
      <Button 
        type="button" 
        outlined={true}
        style={{
          marginTop: '24px',
          marginLeft: '24px',
          marginRight: '24px',
          marginBottom: showSignOutButton ? '0px' : '24px'
        }}
        onClick={() => {
          history.push(ROUTES.USER_ADD_USER.replace(':stylist', username))
        }}>
        BOOK FOR ANOTHER PERSON
      </Button>
      {showSignOutButton && (
        <Button 
          type="button" 
          outlined={true}
          style={{
            marginTop: '12px',
            marginLeft: '24px',
            marginRight: '24px',
            marginBottom: '24px'
          }}
          onClick={() => {
            onClickSignOut()
          }}>
          Sign out
        </Button>
      )}
    </UserSelectionFormStyles>
  )

}
