import React, { useCallback, useContext } from 'react';
import { format, utcToZonedTime } from 'date-fns-tz';
import { Link } from 'react-router-dom';

import { formatTimezoneShort } from 'app/components/calendar/utils/formatTimezone';
import { Button } from 'app/components/button/Button';
import { phoneParse } from 'app/utils/phoneParse';
import { Block, ButtonWrapper, Header, Status, Wrapper } from '../styles/ListItemStyles';
import { StylistContext } from 'app/context/StylistContext';
import { ShortServiceItemView } from 'app/components/ServiceItemView';
import { APPOINTMENT_TYPES } from '../utils/consts';
import { HOUR_FORMAT } from './calendar/consts';

export type ListItemProps = {
  canBookOnline: boolean;
  item: any;
  listType: string;
  locationURL: string;
  locationArray: string[];
  scheduleAgain: (items: string[]) => void;
  stylistName: string;
  stylistPhone: string;
  timeZone: string;
};

export const ListItem = ({
  canBookOnline,
  item,
  listType,
  locationURL,
  locationArray,
  scheduleAgain,
  stylistPhone,
  timeZone,
}: ListItemProps) => {
  const formattedDate = format(new Date(item.startingAt), 'EEEE, LLLL do, yyyy');
  const startingHour = format(utcToZonedTime(new Date(item.startingAt), timeZone), HOUR_FORMAT);
  const endingHour = format(utcToZonedTime(new Date(item.endingAt), timeZone), HOUR_FORMAT);
  const formattedHour = `${startingHour} - ${endingHour}, ${formatTimezoneShort(timeZone)}`;

  const isCanceled = item.canceledAt !== null;
  const isPaid = item.finalizedAt !== null;

  const canScheduleAgain = canBookOnline && listType === APPOINTMENT_TYPES.PAST;

  const handleScheduleAgainClick = useCallback(() => {
    const servicesIds = item.services.map((serviceItem: any) => serviceItem.id);
    scheduleAgain(servicesIds);
  }, [item, scheduleAgain]);

  const stylistContext = useContext(StylistContext)
  const stylist = stylistContext.stylist
  const phone = stylist.profile.contact.phoneNumber || ''

  return (
    <Wrapper>
      <Header isFilled={true}>
        <h4>{formattedDate}</h4>
        <p>{formattedHour}</p>
        {isCanceled && <Status isCanceled={true}>Canceled</Status>}
        {isPaid && <Status isPaid={true}>Paid</Status>}
      </Header>
      <Block>
        <a href={locationURL} rel="noopener noreferrer" title="Go to Google map" target="_blank">
          {locationArray.map((line, key) => (
            <p key={key}>
              {line}
            </p>
        ))}
        </a>
      </Block>
      <Block>
        {phone ? (
          <>
            <a href={`tel:${phone}`}>
              <p className="name">{`${stylist.profile.firstName} ${stylist.profile.lastName}`}</p>
              <p>{phoneParse(phone)}</p>
            </a>
          </>
        ) : (
          <p className="title">{`${stylist.profile.firstName} ${stylist.profile.lastName}`}</p>
        )}
      </Block>
      <div>
        {item.services.map((service: any) => (
          <ShortServiceItemView
            key={service.id}
            service={service} />
        ))}
      </div>
      <ButtonWrapper>
        {canScheduleAgain ? (
          <Button type="button" outlined={true} onClick={handleScheduleAgainClick}>
            <span>Schedule Again</span>
          </Button>
        ) : (
          <Button type="button" outlined={true}>
            <Link to={`/a/${item.id}`}>View Details</Link>
          </Button>
        )}
      </ButtonWrapper>
    </Wrapper>
  );
};
