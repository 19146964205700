import React from 'react';
import { getEnvironmentVar } from 'app/utils';

import { Avatar } from '../avatar/Avatar';
import { ProfileHours } from './hours/ProfileHours';
import { Navbar } from './navbar/Navbar';
import { ProfileContent, ProfileWrapper } from './Profile.styles';
import { ProfileInformation } from './profileInformation/ProfileInformation';
import { Social } from './social/Social';

export type StylistProfileProps = {
  stylist: any;
}

export const StylistProfile = ({ 
  stylist
}: StylistProfileProps) => {
  const HOST = getEnvironmentVar('REACT_APP_API_URL')

  if (!stylist || !stylist.profile) {
    return null;
  }

  const { backgroundImage } = stylist.profile;
  const backgroundImageURL = backgroundImage && backgroundImage.largeUrl ? `${HOST}${backgroundImage.largeUrl}` : '';

  return (
    <ProfileWrapper bgImage={backgroundImageURL} className="profile-wrapper">
      <Navbar
        location={stylist.profile.location.address}
        name={`${stylist.profile.firstName} ${stylist.profile.lastName}`}
        phoneNumber={stylist.profile.contact.phoneNumber}
      />
      <ProfileContent>
        {stylist.profile.profileImage && (
          <Avatar image={stylist.profile.profileImage && HOST + stylist.profile.profileImage.smallUrl} />
        )}
        <ProfileInformation
          location={stylist.profile.location.address}
          name={stylist.profile.location.name}
          noBackground={!backgroundImageURL}
          firstName={stylist.profile.firstName}
          lastName={stylist.profile.lastName}
          contact={stylist.profile.contact}
        />
        <Social
          social={stylist.profile.social}
          emailAddress={stylist.profile.contact.emailAddress}
          noBackground={!backgroundImageURL}
        />
        <ProfileHours hours={stylist.profile.hours} colors={backgroundImageURL ? 'light' : 'dark'} />
      </ProfileContent>
    </ProfileWrapper>
  );
};
