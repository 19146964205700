import React, { ReactNode } from 'react'
import styled, { css } from 'app/theme'

export const CheckOptionViewStyles = styled.div`
    ${({ theme: { variables } }) => css`
        position: relative;
        font-size: 14px;

        input {
            display: none;
        }

        label {
            min-height: 50px;
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            padding-left: 24px;
            padding-right: 72px;

            p {
                width: 100%;
                padding-top: 12px;
                padding-bottom: 12px;
                color: ${variables.colors.infoGray};
            }

            span {
                color: ${variables.colors.primary};
                :hover {
                    text-decoration: underline;
                }
            }

        }

        :hover {
            background-color: ${variables.colors.neutral1};
        }

        .check-container {
            width: 22px;
            height: 22px;
            right: 24px;
            top: 0;
            bottom: 0;
            margin: auto;
            position: absolute;
            border-radius: 50%;
            border: 1px solid ${variables.colors.blackTransparent};
        }

        .check {
            width: 22px;
            height: 22px;
            right: 24px;
            top: 0;
            bottom: 0;
            margin: auto;
            position: absolute;
            border-radius: 50%;
            border: 1px solid transparent;
            background-image: url(${variables.icons.select});
            background-repeat: no-repeat;
            background-position: center;
            background-color: ${variables.colors.primary};
        }

    `}
`;

export type CheckOptionViewProps = {
    name: string,
    checked: boolean,
    required?: boolean,
    onChange: (value: boolean) => void,
    children: ReactNode,
    isForm?: boolean
}

export const CheckOptionView = ({
    name,
    checked,
    required = true,
    onChange,
    children,
    isForm: showChildren = true
}: CheckOptionViewProps) => {

    return (
        <CheckOptionViewStyles >
            <label>
                {showChildren && (
                <input 
                    type="checkbox"
                    name={name}
                    required={required}
                    checked={checked}
                    onChange={() => onChange(!checked)} />)}
                    {children}
                    {showChildren && (
                    <>
                        <div className="check-container" />
                        {checked && <div className="check" />}
                    </>
                    )}
              </label>
        </CheckOptionViewStyles>
    )

}