import { CardBox, CardWrapper, ProductDetails } from 'app/styles/CardStyles';
import styled, { css, to } from 'app/theme';

export const Wrapper = styled.div`
  ${({ theme: { variables } }) => css`
    align-items: stretch;
    border: 1px solid ${variables.colors.neutral1};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 24px;
    width: 100%;
    overflow: hidden;

    &:first-child {
      margin-top: 24px;
    }

    ${ProductDetails} {
      padding-left: ${variables.margins.marginMedium};
    }

    ${CardBox} {
      border-color: ${variables.colors.neutral1};
      border-left: 0;
      border-radius: 0;
      border-right: 0;
      margin-bottom: 0;
    }

    ${CardWrapper} {
      &:first-of-type {
        ${CardBox} {
          border-top: 0;
        }
      }

      &:last-of-type {
        ${CardBox} {
          border-bottom-color: ${variables.colors.lightGray};
        }
      }
    }
  `}
`;

export const Header = styled.div<{ isFilled: boolean }>`
  ${({ isFilled, theme: { variables } }) => css`
    background-color: ${isFilled ? variables.colors.neutral1 : 'transparent'};
    color: ${variables.colors.black};
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: ${isFilled ? '16px' : '24px'};
    padding-bottom: ${isFilled ? '16px' : '24px'};
    position: relative;
    border-bottom: 1px solid ${variables.colors.neutral1};

    h4 {
      font-weight: 600;
      margin-bottom: 2px;
    }

    p {
      font-weight: 500;
    }
  `}
`;

export const Block = styled.div`
  ${({ theme: { variables } }) => css`
    border-bottom: 1px solid ${variables.colors.neutral1};
    cursor: pointer;

    color: ${variables.colors.black};
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    display: block;

    .title {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .name {
      color: ${variables.colors.black};
    }

    a {
      color: ${variables.colors.primary};
      display: block;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .extended {
      padding: 24px;
    }

    :hover {
      background-color: ${variables.colors.neutral1};
    }

  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme: { variables } }) => css`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 24px;
    width: 100%;

    button {
      box-sizing: border-box;
      border-radius: 200px;
      cursor: pointer;
      font-size: 13px;
      font-weight: 600;
      padding: 0;
      height: 40px;
      width: 100%;

      a,
      span {
        display: block;
      }
    }
  `}
`;

export const Status = styled.div<{ isCanceled?: boolean, isPaid?: boolean }>`
  ${({ isCanceled, isPaid, theme: { variables } }) => css`
    background-color: ${variables.colors.white};
    border-radius: 3px;
    color: ${variables.colors.black};
    font-size: 9px;
    padding: 2px 8px;
    position: absolute;
    right: 24px;
    top: 25px;
    height: 18px;
    line-height: 18px;
    display: block;
    text-transform: uppercase;

    ${isCanceled &&
      css`
        background-color: ${variables.colors.lightRed};
    `}

    ${isPaid &&
      css`
        background-color: ${variables.colors.lightGreen};
    `}
  `}
`;
