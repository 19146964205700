import { format, utcToZonedTime } from 'date-fns-tz';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router';

import { Calendar } from './Calendar';
import { StylistContext } from 'app/context/StylistContext';
import { BookingContext } from 'app/context/BookingContext';
import { useStylistRepository } from 'app/repository/StylistRepository';
import { Form } from 'react-final-form';
import { ErrorView } from 'app/components/ErrorView';
import { Wrapper } from 'app/styles/BookingWrapperStyles';
import { LoadingView } from 'app/components/LoadingView';
import { getUniq } from 'app/utils/getUniq';
import { catchError } from 'app/utils/analytics';

export type CalendarContainerProps = {
  onTimeSelect: () => void
}

export const CalendarContainer = ({
  onTimeSelect
}: CalendarContainerProps) => {

  const stylistRepo = useStylistRepository()
  const username = stylistRepo.getStylistUsername()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<any>()
  const [availabilities, setAvailabilities] = useState<any>(null)

  const bookingContext = useContext(BookingContext)
  const stylistContext = useContext(StylistContext)

  // Redirect to step 1 unsafe
  if (bookingContext.services.length === 0) {
    return <Redirect to={`/${username}/booking/1/`} />;
  }

  // Grab the duration of the selected services
  const selectedServices = stylistContext.services.services.filter((service: any) => {
    return bookingContext.services.includes(service.id)
  })
  const uniqSelected = getUniq(selectedServices, 'id')
  const minDuration = uniqSelected.reduce((totalDuration: number, nextService: any) => {
    return totalDuration + nextService.duration
  }, 0)

  // Fetch the availability data
  useEffect(() => {

    // Reset the booking date
    // This happens because we want to ensure data
    // is safe to move forward
    bookingContext.setDate(null)

    // Gets the availability and controls loading
    const username = stylistRepo.getStylistUsername()
    stylistRepo.getAvailability(username, minDuration)
      .then(res => {
        setIsLoading(false)
        setAvailabilities(res)
      })
      .catch(error => {
        setIsLoading(false)
        setError(catchError('caledar_container_get_availability', error.message))
      })
       
  }, [])

  const timeZone = stylistContext.stylist.profile.timeZone || ''

  const formatTimeZoned = (date: string, timeZone: string) => {
    return format(utcToZonedTime(new Date(date), timeZone), 'yyyy-MM-dd HH:mm');
  }

  const datesWithTimeZone = () => {
    if (!availabilities) return []
    return availabilities.availabilities.map((el: any) => ({
      ...el,
      endingAt: formatTimeZoned(el.endingAt, timeZone),
      startingAt: formatTimeZoned(el.startingAt, timeZone),
    }))
  }

  const closeDays = () => {
    return Object.entries(stylistContext.stylist.profile.hours)
      .filter(([_1, value]) => value === null)
      .flat()
      .filter(Boolean)
  }

  return (
    <Wrapper>
      {error && <ErrorView message={error.message} />}
      {isLoading && <LoadingView />}
      {availabilities && (
        <Form
          onSubmit={() => {}}
          mutators={{
            setHourToNull: (args, state, utils) => {
              utils.changeValue(state, 'hour', () => '');
            },
          }}
          render={({ form: { mutators }, values }) => {
            return (
              <form>
                <Calendar
                  dates={datesWithTimeZone()}
                  selectedDay={values && values.startingAt}
                  closeDays={closeDays()}
                  duration={minDuration}
                  timeZone={timeZone}
                  setIsLoading={{}}
                  onTimeSelect={(hour) => {

                    // Update the booking context date
                    bookingContext.setDate({
                      hour: hour,
                      startingAt: values.startingAt
                    })

                    onTimeSelect()

                  }}
                  mutators={mutators}/>
              </form>
            )
          }}
        />
      )}
    </Wrapper>
  )
}
