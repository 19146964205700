import React, { Fragment } from 'react';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { Theme } from 'app/theme';
import { GlobalStyle } from 'app/theme/Global.styles';

import './firebase';

import { App } from './app/App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <ThemeProvider theme={Theme}>
    <Fragment>
      <GlobalStyle />
      <App />
    </Fragment>
  </ThemeProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
