import styled, { to } from 'app/theme';

export const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${props => props.theme.variables.margins.marginBig};

  ${to('medium')} {
    &:empty {
      margin-top: 0;
    }
  }
`;

export const SocialIcon = styled.span<{ colors: string; size?: string }>`
  align-items: center;
  background-color: ${props => (props.colors === 'light' ? `rgba(34, 36, 42, 0.1)` : `rgba(34, 36, 42, 0.5)`)};
  border-radius: 50%;
  display: flex;
  height: 30px;
  justify-content: center;
  margin: 0 ${props => props.theme.variables.margins.marginNormal};
  vertical-align: middle;
  width: 30px;

  :hover {
    background-color: ${props => (props.colors === 'light' ? `rgba(34, 36, 42, 0.4)` : `rgba(34, 36, 42, 0.8)`)};
  }

  img {
    height: ${props => (props.size === 'big' ? `15px` : `12px`)};
  }
`;
