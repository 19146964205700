export const timeParse = (time: number) => {
  const hours = Math.floor(time / 100);
  const minutes = time % 100;

  const rolledHours = hours % 12;
  const ampmStr = hours < 12 ? 'AM' : 'PM';
  const hoursStr = rolledHours === 0 ? '12' : `${rolledHours}`;
  const paddedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const minutesStr = minutes > 0 ? `:${paddedMinutes}` : '';

  return `${hoursStr}${minutesStr} ${ampmStr}`;
};
