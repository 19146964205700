import { Box as GridBox, Flex } from '@rebass/grid';
import styled, { css } from 'app/theme';
import { getValuesWithMediaQueries } from 'app/utils';

import { BoxProps, ColumnProps, RowProps } from './Grid.types';

export const Row = styled(Flex)<RowProps>`
  box-sizing: border-box;
  flex: ${props => props.flex || '0 1 auto'};
  flex-wrap: ${props => props.flexWrap || 'wrap'};
  margin-right: 0;
  margin-left: 0;
  ${props => (props.height ? `height: ${props.height}` : '')};
`;

export const Column = styled(GridBox)<ColumnProps>`
  ${props =>
    props.size &&
    getValuesWithMediaQueries(props.theme, props.size).map(
      ([mediaQuery, value]) => css`
        ${mediaQuery} {
          flex-basis: ${(value / props.theme.gridSize) * 100}%;
          max-width: ${(value / props.theme.gridSize) * 100}%;
        }
      `,
    )};

  box-sizing: border-box;
  flex: ${props => props.flex || '0 0 auto'};
  flex-grow: ${props => props.flexGrow || 1};
  flex-basis: ${props => props.flexBasis || 0};
`;

export const Box = styled(GridBox)<BoxProps>`
  margin: 0;
`;
