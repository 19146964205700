import { Theme as ThemeInterface } from './Theme.types';
import { variables } from './variables/variables';

export const Theme: ThemeInterface = {
  breakpoints: [
    variables.breakpoints.small,
    variables.breakpoints.medium,
    variables.breakpoints.large,
    variables.breakpoints.xlarge,
    variables.breakpoints.xsmall,
  ],
  gridGap: 1.5,
  gridSize: 12,
  space: ['0', '1rem', '1.5rem', '3rem', '4.5rem', '6rem', '7.5rem'],
  variables,
};
