export const durationParse = (duration: number) => {
  const durationHours = duration / 60;
  const hours = Math.floor(durationHours);
  const durationMinutes = (durationHours - hours) * 60;
  const minutes = Math.floor(durationMinutes);

  const hoursStr = hours > 0 ? `${hours}H` : '';
  const minutesStr = minutes > 0 ? `${minutes}M` : '';
  const separator = hoursStr && minutesStr ? ' ' : '';

  return `${hoursStr}${separator}${minutesStr}`;
};
