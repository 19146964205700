import { MENU_BAR_HEIGHT_WITH_PADDING } from 'app/utils/consts';
import React, { ReactNode, useRef } from 'react';

export type FocusInputProps = {
  children?: ReactNode;
  className?: string;
  inputClassName?: string;
  inputProps: any;
  label: string;
  wrapperClassName?: string;
  autofocus: boolean
}

export const FocusInput = ({
  children,
  className,
  inputClassName,
  inputProps,
  label,
  wrapperClassName,
  autofocus
}: FocusInputProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const onFocus = () => {
    if (inputProps.onFocus) {
      inputProps.onFocus();
    }
    if (wrapperRef.current) {
      window.scrollTo(0, wrapperRef.current.offsetTop - MENU_BAR_HEIGHT_WITH_PADDING);
    }
  };

  return (
    <div className={wrapperClassName} ref={wrapperRef}>
      <label>{label}</label>
      <div className={className}>
        <input {...inputProps} className={inputClassName} onFocus={onFocus} autoFocus={autofocus} />
        {children}
      </div>
    </div>
  );
};
