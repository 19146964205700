
import Modal from 'app/components/modal/Modal';
import React, { ReactNode, useCallback, useMemo } from 'react'
import { Field } from 'react-final-form';
import useModal from 'app/utils/useModal';
import styled, { css, to } from 'app/theme'
import { CheckBox } from './CheckBox';
import { getEnvironmentVar } from 'app/utils';
import { ModalContent, ModalImage, ProductDetails, ProductImage, ProductImageBox } from '../styles/CardStyles';
import { durationParse } from 'app/utils/durationParse';
import { priceParse } from 'app/utils/priceParse';

export const ServiceItemViewStyles = styled.div<{ isSelectable: boolean, short?: boolean }>`
    ${({ isSelectable, short, theme: { variables } }) => css`
        position: relative;
        font-size: 14px;
        border: ${short ? `0` : `1px solid ${variables.colors.neutral1}`};
        border-radius: ${short ? `0` : `4px`};
        margin-bottom: ${short ? `0` : `24px`};
        width: 100%;
        overflow: hidden;

        input {
            display: none;
        }

        label {
            min-height: 120px;
            display: flex;
            flex-direction: row;
            padding-right: 40px;
            align-items: center;
            cursor: ${isSelectable ? 'pointer' : 'default'};
        }

        ${isSelectable && (
            !short && (
                `:hover {
                    background-color: ${variables.colors.neutral1};
                }`
            )
        )}

        .short {
            min-height: 80px;
            border: 0;
            border-bottom: 1px solid ${variables.colors.neutral1};
            border-radius: 0px;
            margin-bottom: 0px;
        }

        .row {
            display: flex;
            flex-direction: row;
            width: 100%;
        }

        .column {
            padding-top: 12px;
            padding-bottom: 12px;
            justify-content: center;
            padding-left: 24px;
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        h3 {
            color: ${variables.colors.black};
            font-size: ${short ? `14px` : `18px`};
            font-weight: 500;
            margin-bottom: 8px;
        }

        p {
            color: ${variables.colors.infoGray};
            font-size: 14px;
            font-weight: 500;
        }

        .text {
            display: flex;
            flex-direction: row;

            p {
                ::before {
                    content: "·";
                    margin-right: 6px;
                    margin-left: 6px;
                    color: ${variables.colors.infoGray};
                }
                &:first-child {
                    ::before {
                        content: "";
                        margin-right: 0px;
                        margin-left: 0px;
                    }
                }
            }
        }

        .price {
            color: ${variables.colors.black};
        }

        .link {
            cursor: pointer;
            color: ${variables.colors.primary};
            :hover {
                text-decoration: underline;
            }
        }

        ${to('large')} {

            margin-bottom: 12px;

            h3 {
                font-size: 14px;
            }

            label {
                min-height: 90px;
            }

        }

    `}
`;

export type ServiceItemViewProps = {
    service: any,
    isSelectable?: boolean
}

const renderPrice = (priceDisplayMode: string, price: number): ReactNode => {
    const priceDisplayModeMap: any = {
      full_price: <span>{priceParse(price)}</span>,
      min_price: <span>Starts at {priceParse(price)}</span>,
      no_price: null,
      variable_price: <span>Price Varies</span>,
    };
  
    return priceDisplayModeMap[priceDisplayMode];
}

export const ServiceItemView = ({
    service,
    isSelectable = true
}: ServiceItemViewProps) => {

    const { isShowing, toggle } = useModal()
    const HOST = getEnvironmentVar('REACT_APP_API_URL')

    const modalToggle = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault();
          toggle();
        },
        [toggle],
      );

    const priceToDisplay = useMemo(() => renderPrice(service.priceDisplayMode, service.price), [service.price, service.priceDisplayMode])

    const getModal = () => {
        return (
            <ProductDetails>
                <div className="product-wrapper">
                    <div>
                        <Modal isShowing={isShowing} hide={toggle}>
                            <ModalContent>
                            {service.image && <ModalImage backgroundImage={HOST + service.image.mediumUrl} />}
                            <div className="modal__content-wrapper">
                                <div className="modal__product-title">{service.name}</div>
                                <div className="product_details">
                                <div className="modal__product-time">{durationParse(service.duration)}</div>
                                {priceToDisplay && (
                                    <div className="modal__product-wrapper modal__product-wrapper__price">
                                    <div className="modal__product-title">{priceToDisplay}</div>
                                    </div>
                                )}
                                </div>
                                <div className="modal__product-description">{service.description}</div>
                            </div>
                            </ModalContent>
                        </Modal>
                    </div>
                </div>
            </ProductDetails>
        )
    }

    const photo = useMemo(() =>
          service.image && (
            <ProductImage>
              <ProductImageBox>
                <img src={HOST + service.image.thumbUrl} alt="" />
              </ProductImageBox>
            </ProductImage>
          ), [service.image]);

    const content = () => {
        return (
            <>
                <div className='row'>
                    {photo}
                    <div className='column'>
                        <h3>{service.name}</h3>
                        <div className='text'>
                            <p>{durationParse(service.duration)}</p>
                            {priceToDisplay && <p className='price'><span>{priceToDisplay}</span></p>}
                            <p><span className='link' onClick={modalToggle}>Info</span></p> 
                        </div>
                    </div>
                </div>
                {getModal()}
            </>
        )
    }

    const wrapper = () => {
        if (isSelectable) {
            return (
                <Field
                    name="services"
                    component="input"
                    type="checkbox"
                    value={service.id}>
                    {props => {
                        return (
                            <>
                                <input type="checkbox" {...props.input} />
                                <CheckBox
                                    style={{
                                        position: 'absolute',
                                        right: '24px'
                                    }}
                                    checked={props.input.checked || false} />
                                    {content()}
                            </>
                        )
                    }}
                </Field>
            )
        } else {
            return content()
        }
    }

    return (
        <ServiceItemViewStyles isSelectable={isSelectable}>
            <label>
                {wrapper()}
            </label>
        </ServiceItemViewStyles>
    )

}

export const ShortServiceItemView = ({
    service
}: ServiceItemViewProps) => {

    const { isShowing, toggle } = useModal()
    const HOST = getEnvironmentVar('REACT_APP_API_URL')

    const modalToggle = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault();
          toggle();
        },
        [toggle],
      );

    const priceToDisplay = useMemo(() => renderPrice(service.priceDisplayMode, service.price), [service.price, service.priceDisplayMode])

    const getModal = () => {
        return (
            <ProductDetails>
                <div className="product-wrapper">
                    <div>
                        <Modal isShowing={isShowing} hide={toggle}>
                            <ModalContent>
                            {service.image && <ModalImage backgroundImage={HOST + service.image.mediumUrl} />}
                            <div className="modal__content-wrapper">
                                <div className="modal__product-title">{service.name}</div>
                                <div className="product_details">
                                <div className="modal__product-time">{durationParse(service.duration)}</div>
                                {priceToDisplay && (
                                    <div className="modal__product-wrapper modal__product-wrapper__price">
                                    <div className="modal__product-title">{priceToDisplay}</div>
                                    </div>
                                )}
                                </div>
                                <div className="modal__product-description">{service.description}</div>
                            </div>
                            </ModalContent>
                        </Modal>
                    </div>
                </div>
            </ProductDetails>
        )
    }

    return (
        <ServiceItemViewStyles isSelectable={false} short={true}>
            <div className='row short'>
                <div className='column'>
                    <h3>{service.name}</h3>
                    <div className='text'>
                        <p>{durationParse(service.duration)}</p>
                        {priceToDisplay && <p className='price'><span>{priceToDisplay}</span></p>}
                        <p><span className='link' onClick={modalToggle}>Info</span></p> 
                    </div>
                </div>
            </div>
            {getModal()}
        </ServiceItemViewStyles>
    )

}