import { useStylistRepository } from 'app/repository/StylistRepository';
import React from 'react';
import { Redirect } from 'react-router';
import { ROUTES } from './routes';

export const RedirectToBooking = () => {
  const stylistRepo = useStylistRepository()
  const username = stylistRepo.getStylistUsername()
  return <Redirect to={ROUTES.BOOKING.replace(':stylist', username).replace(':step', '1')} />
}
