import styled, { css } from 'app/theme';

export const Wrapper = styled.div`
  ${({ theme: { variables } }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  `}
`;

export const EmptyList = styled.div`
  ${({ theme: { variables } }) => css`
    font-size: 14px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: center;
    line-height: 2;
    color: ${variables.colors.infoGray};
  `}
`;