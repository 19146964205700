import React, { useContext } from 'react';

import { CheckOptionView } from 'app/components/CheckOptionView';
import { StylistContext } from 'app/context/StylistContext';
import useModal from 'app/utils/useModal';
import { ModalContent } from 'app/styles/CardStyles';
import Modal from './modal/Modal';

export type CancellationPolicyProps = {
  canCancelOnline: boolean,
  days: number,
  percentage: number
}

export const CancellationPolicy = ({ canCancelOnline, days, percentage }: CancellationPolicyProps) => {
  const { isShowing, toggle } = useModal();
  const { stylist } = useContext(StylistContext)

  return (
    <>
      <CheckOptionView 
        name="agreeCancellationPolicy"
        isForm={false}
        checked={false} 
        onChange={(value) => {}}>
          <p style={{ width: '100%' }}>
            View the <span onClick={(e) => {
              e.preventDefault()
              toggle()
            }}>cancellation policy</span>
          </p>
      </CheckOptionView>
      <Modal hide={toggle} isShowing={isShowing} title={`${stylist.profile.firstName} ${stylist.profile.lastName}'s Cancellation Policy`}>
        <ModalContent>
          <p style={{ marginBottom: '12px' }}>You have <strong>{days}</strong> days before your appointment to cancel or alter your scheduled services without penalty. If you must cancel your appointment within {days} days, you will be charged a <strong>{percentage}%</strong> cancellation fee and can be charged to your credit card on file, if provided.</p>
          <p style={{ marginBottom: '12px' }}>You may cancel your appointment online by following the link provided in your confirmation email or SMS that you will receive upon booking the appointment.</p>
          <p>If you have questions or concerns regarding this cancellation policy, please contact {stylist.profile.firstName} {stylist.profile.lastName} directly.</p>
          {canCancelOnline && (
            <p>
              <strong>You can cancel online</strong> by visiting your appointment’s URL from the confirmation email or
              SMS that you’ll receive.
            </p>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
