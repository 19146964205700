export const prepareTitle = (selectedServices: any[], stylist?: any) => {
  if (selectedServices.length === 0) {
    return '';
  }

  const serviceNames = selectedServices.reduce<string>((accServiceNames, { name }, index, originalArray) => {
    if (originalArray.length === 1 || index === 0) {
      return name;
    }

    if (index === originalArray.length - 1) {
      return `${accServiceNames} and ${name}`;
    }

    return `${accServiceNames}, ${name}`;
  }, '');

  if (!stylist || !stylist.profile) {
    return serviceNames;
  }

  return `${serviceNames} with ${stylist.profile.firstName} ${stylist.profile.lastName}`;
};
