import React, { useMemo } from 'react';

import { getSocialMediaArray } from './utils';

import { SocialIcon, SocialWrapper } from './Social.styles';
import { ProfileSocial } from './Social.types';

export const Social = ({ social, emailAddress, noBackground }: ProfileSocial) => {
  const socialIcons = useMemo(() => getSocialMediaArray(emailAddress, social), [social]);

  return (
    <SocialWrapper className="profile__social">
      {socialIcons.map(({ alt, darkIcon, lightIcon, url }) => (
        <a href={url} target="_blank" rel="noopener noreferrer" key={alt}>
          <SocialIcon colors={noBackground ? 'light' : 'dark'}>
            <img src={noBackground ? darkIcon : lightIcon} alt={alt} />
          </SocialIcon>
        </a>
      ))}
    </SocialWrapper>
  );
};
