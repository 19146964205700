export function getUniq<T extends {}>(array: T[], field: keyof T): T[] {
  return array.reduce<T[]>((uniqueArray, item) => {
    if (uniqueArray.some(orgItem => orgItem[field] === item[field])) {
      return uniqueArray;
    }

    uniqueArray.push(item);

    return uniqueArray;
  }, []);
}
