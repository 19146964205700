import styled, { css, to } from 'app/theme';

export const AddCardWrapper = styled.div<{ hidden: boolean }>`
  ${({ hidden }) => css`

    margin: 0 auto;
    width: 100%;
    visibility: ${hidden ? `hidden` : `visible`}

    form {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 475px;
    }

  `}

`;

export const Content = styled.div`
  ${({ theme: { variables } }) => css`
    box-sizing: border-box;
    margin-top: ${variables.margins.marginNormalTwice};

    .primary {
      border: 0;
      border-radius: 0 !important;
      font-family: ${variables.typography.primaryFont};
      font-size: ${variables.fonts.size.smaller1};
      font-weight: bold;
      height: 50px;
      margin-top: 20px;
      width: 100%;
    }
  `}
`;
