import React, { useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { prepareLocationArray } from 'app/utils/prepareLocationArray';

import { EmptyList, Wrapper } from '../styles/ListStyles';
import { ListItem } from './ListItem';
import { StylistContext } from 'app/context/StylistContext';
import { useStylistRepository } from 'app/repository/StylistRepository';
import { BookingContext } from 'app/context/BookingContext';
import { EMPTY_LIST_MESSAGES } from '../utils/consts';
import { prepareLocationURL } from 'app/utils/prepareLocationUrl';

export type ListProps = {
  type: string,
  list: any[]
}

export const List = ({ list, type }: ListProps) => {

  const bookingContext = useContext(BookingContext)
  const stylistContext = useContext(StylistContext)
  const stylistRepo = useStylistRepository()
  const username = stylistRepo.getStylistUsername()
  const stylist = stylistContext.stylist

  const stylistName = `${stylist.profile.firstName} ${stylist.profile.lastName}`;
  const stylistPhone = stylist.profile.contact.phoneNumber || '';
  const location = stylist.profile.location.address;
  const history = useHistory();

  const canBookOnline = stylist.profile.bookingSettings.onlineBookings;

  const locationURL = useMemo(() => {
    if (location) {
      return prepareLocationURL(location);
    }

    return '';
  }, [location]);

  const locationArray = useMemo(() => {
    return location ? prepareLocationArray(location) : [];
  }, [location]);

  function scheduleAgain(serviceIds: string[]) {
    bookingContext.setServices(serviceIds)
    history.push(`/${username}/booking/1/`)
  }

  return (
    <Wrapper>
      {list && list.length === 0 ? (
        <EmptyList>{EMPTY_LIST_MESSAGES[type]}</EmptyList>
      ) : (
        list.map((appointment: any) => (
          <ListItem
            canBookOnline={canBookOnline}
            key={appointment.id}
            item={appointment}
            listType={type}
            locationArray={locationArray}
            locationURL={locationURL}
            scheduleAgain={scheduleAgain}
            stylistName={stylistName}
            stylistPhone={stylistPhone}
            timeZone={stylist.profile.timeZone}
          />
        ))
      )}
    </Wrapper>
  )
}
