import React from 'react';
import { Link } from 'react-router-dom';

import { MenuItemProps } from './Menu.types';

const isExternalLink = (link: string) => !link.startsWith('/');

export const MenuItem = ({ className, link, label, onClick, ...otherProps }: MenuItemProps) => (
  <li className={className}>
    {isExternalLink(link) ? (
      <a href={link} {...otherProps} onClick={onClick}>
        {label}
      </a>
    ) : (
      <Link to={link} {...otherProps} onClick={onClick}>
        {label}
      </Link>
    )}
  </li>
);
