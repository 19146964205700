import { InputStyles } from 'app/styles/InputStyles';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { Button } from 'app/components/button/Button';
import { FocusInput } from 'app/components/focusInput/FocusInput';

export type UserSignUpFormProps = {
    onUserSignUpSubmit: (values: any) => void
}
  
export const UserSignUpForm = ({ 
    onUserSignUpSubmit: onUserSignUpSumbit
}: UserSignUpFormProps) => {
  
    return (
        <InputStyles>
            <Form
                onSubmit={onUserSignUpSumbit}
                render={({ handleSubmit }) => {
                    return (
                    <form onSubmit={handleSubmit}>
                        <Field
                            component="input"
                            type="email"
                            required={true}
                            name="emailAddress"
                            >
                                {({ input, meta: { error, touched }, ...rest }) => (
                                    <FocusInput
                                    className="field-validation"
                                    inputProps={{ ...input, ...rest }}
                                    label="Email"
                                    wrapperClassName="field-wrapper"
                                    autofocus={true}
                                    />
                                )}
                        </Field>
                        <Field
                            component="input"
                            type="text"
                            required={true}
                            name="givenName"
                            >
                                {({ input, meta: { error, touched }, ...rest }) => (
                                    <FocusInput
                                    className="field-validation"
                                    inputProps={{ ...input, ...rest }}
                                    label="First name"
                                    wrapperClassName="field-wrapper"
                                    autofocus={false}
                                    />
                                )}
                        </Field>
                        <Field
                            component="input"
                            type="text"
                            required={true}
                            name="familyName"
                            >
                                {({ input, meta: { error, touched }, ...rest }) => (
                                    <FocusInput
                                    className="field-validation"
                                    inputProps={{ ...input, ...rest }}
                                    label="Last Name"
                                    wrapperClassName="field-wrapper"
                                    autofocus={false}
                                    />
                                )}
                        </Field>
                        <Button
                            style={{
                                width: '100%'
                            }}
                            type="submit">
                            Save
                        </Button>
                    </form>
                    )
                }}
            />
        </InputStyles>
    )
  
}