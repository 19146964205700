import firebase from "firebase/app"
import { getEnvironmentVar } from "app/utils"

const HOST = getEnvironmentVar('REACT_APP_API_URL')

const getHeaders = async (isAuthorized: boolean): Promise<any> => {
    if (isAuthorized) {
        try {

            // Ensure there is a user
            const currentUser = firebase.auth().currentUser
            if (!currentUser) throw new Error('User not found')

            // Grab token and pass to request
            const token = await currentUser.getIdToken(true)
            return {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            
        } catch (error) {
            throw error
        }
    } else {
        return {
            'Content-Type': 'application/json'
        }
    }
}

export const get = async (path: string, isAuthorized: boolean = false) => {
    return fetch(`${HOST}/${path}`, {
            method: 'GET',
            headers: await getHeaders(isAuthorized)
        })
        .then(res => res.json())
        .then(json => {
            if (!json.success) {
                throw json.error
            } else {
                return json
            }
        })
}

export const post = async (path: string, body: any, isAuthorized: boolean = false) => {
    return fetch(`${HOST}/${path}`, {
            method: 'POST',
            headers: await getHeaders(isAuthorized),
            body: JSON.stringify(body)
        })
        .then(res => res.json())
        .then(json => {
            if (!json.success) {
                throw json.error
            } else {
                return json
            }
        })
}

export const del = async (path: string, isAuthorized: boolean = false) => {
    return fetch(`${HOST}/${path}`, {
            method: 'DELETE',
            headers: await getHeaders(isAuthorized)
        })
        .then(res => res.json())
        .then(json => {
            if (!json.success) {
                throw json.error
            } else {
                return json
            }
        })
}