import { ProfileBusinessLocation } from 'app/components/profile/Profile.types';

export const prepareLocation = (address: ProfileBusinessLocation, separator?: string): string | null => {
  const { address1, address2, city, state, zip } = address;

  if (!address1 && !address2 && !city && !state && !zip) {
    return null;
  }

  const locationArray = [];

  if (address1 || address2) {
    locationArray.push(`${address1 || ''}${address2 ? `${address1 ? ' ' : ''}${address2}` : ''}`);
  }

  if (city) {
    locationArray.push(city);
  }

  if (state && city) {
    locationArray[locationArray.length - 1] = `${locationArray[locationArray.length - 1]} ${state}`;
  }

  if (state && !city) {
    locationArray.push(state);
  }

  if (zip) {
    locationArray.push(zip);
  }

  return locationArray.join(separator || '\\, ');
};
