import React, { useEffect, useState } from 'react';

import close from 'assets/icons/icon-close.svg';
import menuIconDark from 'assets/icons/menu-dark.svg';

import { phoneParse } from 'app/utils/phoneParse';

import { MenuItem } from './MenuItem';
import { MENU_ITEMS, SIGNED_USER_MENU_ITEMS } from './consts';

import { MenuCloseActionWrapper, MenuButtonWrapper, MenuList, MenuWrapper } from './Menu.styles';
import { MenuProps } from './Menu.types';

export const Menu = ({ isUserSigned, phoneNumber, stylistId }: MenuProps) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const phoneMenuItem = phoneNumber ? (
    <MenuItem
      className="primary-color"
      label={`Call ${phoneParse(phoneNumber)}`}
      link={`tel:${phoneNumber}`}
      onClick={toggleMenu}
    />
  ) : null;

  return (
    <MenuWrapper>
      {showMenu && <MenuCloseActionWrapper onClick={toggleMenu} />}
      <MenuButtonWrapper>
        <img alt="Open menu" className={showMenu ? 'hide-on-mobile' : ''} onClick={toggleMenu} src={menuIconDark} />
        {showMenu && (
          <img
            alt="Close"
            src={close}
            className="hide-on-desktop close-button"
            aria-label="Close"
            onClick={toggleMenu}
          />
        )}
      </MenuButtonWrapper>
      {showMenu && (
        <MenuList>
          <ul>
            {MENU_ITEMS.map(({ id, link, ...props }) => (
              <MenuItem key={id} link={link.replace(':stylistId', stylistId)} onClick={toggleMenu} {...props} />
            ))}
            {phoneMenuItem}
          </ul>
          {isUserSigned && (
            <ul>
              {SIGNED_USER_MENU_ITEMS.map(({ id, link, ...props }) => (
                <MenuItem key={id} link={link.replace(':stylistId', stylistId)} onClick={toggleMenu} {...props} />
              ))}
            </ul>
          )}
        </MenuList>
      )}
    </MenuWrapper>
  );
};
