import { UserContext } from 'app/context/UserContext';
import { InputStyles } from 'app/styles/InputStyles';
import React, { useContext, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Button } from 'app/components/button/Button';
import { FocusInput } from 'app/components/focusInput/FocusInput';
import { normalizePhone } from 'app/utils/phoneParse';

export type PhoneNumberFormProps = {
  onSubmitPhone: (values: any) => void
}

export const PhoneNumberForm = ({ onSubmitPhone }: PhoneNumberFormProps) => {

  const userContext = useContext(UserContext)
  const [isValid, setIsValid] = useState(false)

  return (
    <InputStyles>
      <Form
        initialValues={{
          phoneNumber: userContext.currentPhoneNumber
        }}
        onSubmit={onSubmitPhone}
        render={({ handleSubmit, values }) => {
          
          // React to the change
          if (values.phoneNumber) {
            setIsValid(values.phoneNumber.length >= 14)
          } else {
            setIsValid(false)
          }

          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="phoneNumber"
                type="tel"
                component="input"
                minLength="10"
                pattern="(\+1)?[\s]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-/\s.]?[0-9]{4}"
                placeholder="(999) 999-9999"
                parse={normalizePhone}
                required={true}
              >
                {({ input, meta: { error, touched }, ...rest }) => (
                  <FocusInput
                    className="field-validation"
                    inputClassName={`${error && touched && `field-error`}`}
                    inputProps={{ ...input, ...rest }}
                    label="Mobile phone"
                    wrapperClassName="field-wrapper"
                    autofocus={true}
                  />
                )}
              </Field>
              <Button
                style={{
                  width: '100%'
                }}
                disabled={!isValid}
                type="submit">
                  Next
              </Button>
            </form>
          )
        }}
      />
    </InputStyles>
  )

}
