const AREA_CODE_LENGTH = 3;
const LANDLINE_NUMBER_LENGTH = 7;
const CELL_NUMBER_LENGTH = 10;

export const phoneParse = (phoneNumber: string) => {
  const plainNumber = phoneNumber.replace(/[^0-9]/g, '');

  if (plainNumber.length === LANDLINE_NUMBER_LENGTH) {
    return `${plainNumber.slice(0, 3)}-${plainNumber.slice(3)}`;
  }

  if (plainNumber.length === CELL_NUMBER_LENGTH) {
    return `(${plainNumber.slice(0, 3)}) ${plainNumber.slice(3, 6)}-${plainNumber.slice(6)}`;
  }

  // number contains country prefix
  return `+${plainNumber.slice(0, 1)} ${plainNumber.slice(1, 4)} ${plainNumber.slice(4, 7)}-${plainNumber.slice(7)}`;
};

export const phoneParseSimple = (phoneNumber: string) => phoneNumber.replace(/[^0-9]/g, '');

export const normalizePhone = (phoneNumber: string) => {
  if (!phoneNumber) {
    return phoneNumber;
  }

  const onlyNumbers = phoneNumber.replace(/[^\d]/g, '');
  if (onlyNumbers.length <= AREA_CODE_LENGTH) {
    return onlyNumbers;
  }

  if (onlyNumbers.length <= LANDLINE_NUMBER_LENGTH) {
    return `(${onlyNumbers.slice(0, AREA_CODE_LENGTH)}) ${onlyNumbers.slice(AREA_CODE_LENGTH, LANDLINE_NUMBER_LENGTH)}`;
  }

  return `(${onlyNumbers.slice(0, AREA_CODE_LENGTH)}) ${onlyNumbers.slice(
    AREA_CODE_LENGTH,
    LANDLINE_NUMBER_LENGTH - 1,
  )}-${onlyNumbers.slice(LANDLINE_NUMBER_LENGTH - 1, CELL_NUMBER_LENGTH)}`;
};
