export const scrollTo = (yValue: number) => {
  try {
    document.body.scrollTo({ top: yValue });
  } catch (error) {}; // tslint:disable-line: no-empty
  try {
    document.body.scrollTo(0, yValue);
  } catch (error) {}; // tslint:disable-line: no-empty
  try {
    document.body.scrollTop = yValue;
  } catch (error) {}; // tslint:disable-line: no-empty
};
