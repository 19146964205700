import { useAppointmentRepository } from 'app/repository/AppointmentRepository'
import { useStylistRepository } from 'app/repository/StylistRepository'
import React, {
    createContext,
    FunctionComponent,
    useEffect,
    useState,
} from 'react'
import { Loader } from 'app/components/Loader'
import { createCaptchaVerifier, getAppointment as getAppointmentId } from 'app/utils/utils'
import { Wrapper } from 'app/styles/BookingWrapperStyles'
import { ErrorView } from 'app/components/ErrorView'
import { catchError } from 'app/utils/analytics'

export interface StylistContextType {
    stylist: any,
    services: any
}

export const StylistContext = createContext<StylistContextType>({
    stylist: null,
    services: null
})

export const StylistProvider: FunctionComponent = ({ children }) => {

    // State
    const [stylist, setStylist] = useState<any>(null)
    const [services, setServices] = useState<any>(null)
    const [error, setError] = useState<any>(null)

    // Repos
    const stylistRepo = useStylistRepository()
    const appointmentRepo = useAppointmentRepository()

    // Grab the stylist and update the state
    async function load() {
        try {

            // Grab the stylist username
            // This will change if an appointmentId exists
            let username = window.location.pathname.split('/')[1]

            // See if we need to grab an appointment
            const appointmentId = getAppointmentId()
            if (appointmentId) {
                const appointmentRes = await appointmentRepo.getAppointment(appointmentId)
                username = appointmentRes.appointment.stylist.username
            }

            // Save the username to localstorage
            // This will get overwritten when a client views a new stylist page
            // So there is no need to clear this out
            stylistRepo.setStylistUsername(username)

            // Grab both the profile and the services
            const fetches = await Promise.all([
                stylistRepo.getStylist(username),
                stylistRepo.getServices(username),
                createCaptchaVerifier()
            ])

            const profileDetails = fetches[0]
            const serviceDetails = fetches[1]

            // Ensure state is set
            if (!profileDetails || !serviceDetails) {
                setError(catchError('stylist_context_missing_profile_or_services', 'Error getting styist data'))
                return
            }

            // Clean payloads
            profileDetails.profile.username = username
            delete profileDetails.success
            delete serviceDetails.success

            setStylist(profileDetails)
            setServices(serviceDetails)

        } catch (error) {
            setError(catchError('stylist_context', 'Stylist not found'))
        }
    }

    // Call once on load
    useEffect(() => {
        load()
    }, [])

    return (
        <StylistContext.Provider value={{ 
            stylist, services
        }}>
            {error ? (
                <Wrapper>
                    <ErrorView message={error.message} />
                </Wrapper>
            ) : stylist && services ? children : <Loader />}
        </StylistContext.Provider>
    )

}