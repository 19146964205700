import React, { useCallback, useState } from 'react';
import styled, { css } from 'app/theme'

import { buildUrl, downloadBlob, CalendarType } from '../utils/calendar-utils';
import { Button } from 'app/components/button/Button';
import { SELECTED_CALENDAR } from 'app/utils/LocalStorageKeys';

export const AddToCalendarStyles = styled.div`
  ${({ theme: { variables } }) => css`

    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${variables.colors.neutral1};
  
    h3 {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      line-height: 1.5;
      padding: 24px;
      margin-top: 24px;
      margin-bottom: 12px;
    }

  `}
`;

export const ButtonStackStyles = styled.div`
  ${({ theme: { variables } }) => css`

    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    button {
      width: calc(50% - 12px);
      margin-bottom: 24px;

      &:nth-of-type(2n) {
        margin-left: 24px;
      }
    }

  `}
`;

export type AddToCalendarProps = {
  params: any
}

export const AddToCalendar = ({ params }: AddToCalendarProps) => {

  // Available calendars
  const calendars = [
    { title: 'Google', url: buildUrl(params, CalendarType.GOOGLE) },
    { title: 'Apple', url: buildUrl(params, CalendarType.APPLE) },
    { title: 'Outlook', url: buildUrl(params, CalendarType.OUTLOOK) },
    { title: 'Yahoo', url: buildUrl(params, CalendarType.YAHOO) }
  ]

  const currentCalendar = () => {
    const item = localStorage.getItem(SELECTED_CALENDAR)
    return item ? JSON.parse(item) : null
  }

  // Saves the last used calendar to local storage
  const [selectedCalendar, setCalendar] = useState(currentCalendar)

  // When a selected calendar is clicked
  const handleCalendarClick = useCallback(calendar => {
    localStorage.setItem(SELECTED_CALENDAR, JSON.stringify(calendar))
    setCalendar(calendar);
    downloadBlob(calendar.url)
  }, [])

  // Returns the buttons to show
  function getButtons() {
    if (!selectedCalendar) {
      return (
        <ButtonStackStyles>
          {calendars.map((calendar, i) => {
            return (
              <Button key={i} outlined={true} onClick={() => { handleCalendarClick(calendar) }}>
                {calendar.title}
              </Button>
            )
          })}
        </ButtonStackStyles>
      )
    } else {
      return (
        <>
          <Button style={{ marginBottom: '24px' }} outlined={true} onClick={() => handleCalendarClick(selectedCalendar)}>
            {selectedCalendar.title}
          </Button>
          <Button style={{ marginBottom: '24px' }} outlined={true} onClick={() => setCalendar(null)}>
            Choose another calendar
          </Button>
        </>
      )
    }
  }

  return (
    <AddToCalendarStyles>
      <h3>Add To Your Calendar</h3>
      {getButtons()}
    </AddToCalendarStyles>
  )
}
