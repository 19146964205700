import styled, { css, to } from 'app/theme';

export const TimeSelectWrapper = styled.div`
  ${({ theme: { variables } }) => css`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
    min-width: 400px;
    padding-top: 30px;
    position: relative;

    &:before {
      border-top: 1px solid ${variables.colors.neutral1};;
      content: '';
      margin: 0 auto;
      position: relative;
      top: -30px;
      width: calc(100% - 40px);
    }

    ${to('xsmall')} {
      min-width: 320px;
    }

    .day-header {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33%;
      font-size: ${variables.fonts.size.smaller1};
      font-weight: ${variables.fonts.weight.semiBold};
      flex-direction: column;
      flex: 1;

      ${to('large')} {
        padding-bottom: 75px;
      }
    }
    .day-hours {
      display: flex;
      flex-direction: column;
    }

    .radio-custom {
      margin: 10px;
    }

    .hours-na {
      padding: 10px;
      margin-bottom: 10px;
      color: ${variables.colors.gray};
    }

    .radio-custom input[type='radio'] {
      display: none;
    }

    .radio-custom label {
      background-color: ${variables.colors.white};
      border: 1px solid ${variables.colors.mediumGray};
      border-radius: 30px;
      color: ${variables.colors.mediumGray};
      cursor: pointer;
      display: inline-block;
      font-size: ${variables.fonts.size.smaller2};
      line-height: 30px;
      height: 30px;
      margin-bottom: 10px;
      text-transform: uppercase;
      width: 95px;

      ${to('xsmall')} {
        font-size: 11px;
      }
    }

    .radio-custom label:hover {
      background-color: ${variables.colors.primary};
      color: ${variables.colors.white};
      border: 1px solid ${variables.colors.primary};
    }

    input:checked + label {
      background-color: ${variables.colors.primary};
      color: ${variables.colors.white};
      border: 1px solid ${variables.colors.primary};
    }

    input:disabled + label {
      display: none;
    }
  `}
`;


export const TimeZoneInformation = styled.div`
  ${({ theme: { variables } }) => css`
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: ${variables.colors.gray};
    margin-bottom: 30px;

    span {
      color: ${variables.colors.black};
    }
  `}
`;
