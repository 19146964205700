import styled, { css, from, to } from 'app/theme';

export const ProfileHoursWrapper = styled.div<{ show: boolean; colors: string }>`
  ${({ colors, show, theme: { variables } }) => css`
    background-color: ${colors === 'dark' ? `rgba(34, 36, 42, 0.1)` : `rgba(34, 36, 42, 0.5)`};
    border-radius: ${variables.radius.primaryRadius};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: ${show ? 'auto' : '50px'};
    justify-content: flex-start;
    margin: ${variables.margins.marginBig} auto 0;
    padding: 0;
    text-align: center;
    width: 290px;

    ${from('large')} {
      height: auto;
    }

    ${to('large')} {
      width: 100%;
    }

    .toggle {
      height: ${show ? 'auto' : '0px'};
      visibility: ${show ? 'visible' : 'hidden'};
      opacity: ${show ? 1 : 0};
      padding: 0 ${variables.margins.marginBig};
      transition: visibility 0s, opacity 0.5s linear;

      ${from('large')} {
        visibility: visible;
        opacity: 1;
        height: auto;
      }
    }
  `}
`;

export const ProfileHoursDays = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${props => props.theme.variables.fonts.size.smaller1};

  &:last-of-type {
    margin-bottom: 20px;
  }

  .hours__date {
    display: flex;
    justify-content: space-between;
    width: 100%;
    line-height: 23px;

    &--closed {
      color: ${props => props.theme.variables.colors.manatee};
    }
  }
`;

export const ProfileHoursHeader = styled.div<{ show: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 50px;
  height: 50px;

  h2 {
    font-size: ${props => props.theme.variables.fonts.size.smaller1};
  }

  img {
    margin-left: 10px;
    transform: ${props => (!props.show ? 'rotate(180deg)' : 'rotate(360deg)')};
    transition: all 0.4s;

    ${from('large')} {
      display: none;
    }

    &.dark {
      transform: ${props => (!props.show ? 'rotate(0)' : 'rotate(180deg)')};
    }
  }
`;

export const NoHours = styled.div`
  height: ${props => props.theme.variables.margins.marginBig};
`;
