import styled from 'app/theme';

export const InputStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${props => props.theme.variables.colors.primary};
  width: 100%;

  .sign-in-code {
    text-align: center;
    font-size: 18px !important;
  }

  .field-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    label {
      font-size: 12px;
      font-width: 600;
      text-transform: uppercase;
      color: ${props => props.theme.variables.colors.gray};
      margin-bottom: 12px;
    }

    .field-validation {
      display: flex;
      flex-direction: column;

      span {
        color: ${props => props.theme.variables.colors.red};
        font-size: 12px;
      }
    }

    .field-error {
      border: 1px solid red;
    }

    input {
      box-sizing: border-box;
      padding: 16px 16px;
      background: ${props => props.theme.variables.colors.neutral1};
      border: none;
      color: ${props => props.theme.variables.colors.black};
      font-size: 14px;
      font-family: ${props => props.theme.variables.typography.primaryFont};
      font-weight: 500;
      width: 100%;

      :focus {
        outline-width: 0;
        background: ${props => props.theme.variables.colors.neutral2};
      }
    }
  }
`;
