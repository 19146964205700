export const getUserInitials = (userProfile: any) => {
  if (userProfile.givenName && userProfile.familyName) {
    return `${userProfile.givenName[0]}${userProfile.familyName[0]}`;
  } else if (userProfile.givenName && !userProfile.familyName) {
    return `${userProfile.givenName[0]}`;
  } else if (!userProfile.givenName && userProfile.familyName) {
    return `${userProfile.familyName[0]}`;
  } else {
    return `?`;
  }
};
