export const FIREBASE_CONFIG = {
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'tonto-f5f5e.firebaseapp.com',
  databaseURL: 'https://tonto-f5f5e.firebaseio.com',
  messagingSenderId: '594276096185',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: 'tonto-f5f5e.appspot.com',
  measurementId: process.env.REACT_APP_GA_MEASUREMENT_ID,
};
