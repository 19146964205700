import { CLIENT_ID } from "app/utils/LocalStorageKeys"
import { del, get, post } from "app/utils/Requests"
import firebase from "firebase/app"
import { formatPhoneNumber } from "app/utils/utils"
import { useStylistRepository } from "./StylistRepository"
const phone = require('phone')

export const useUserRepository = () => {

    const stylistRepo = useStylistRepository()
    const username = stylistRepo.getStylistUsername()

    const showLogOutConfirmation = (onConfirmed: () => void) => {
        if (window.confirm('Are you sure you want to sign out?')) {
            onConfirmed()
        }
    }

    const signInWithPhone = async (phoneNumber: string) => {

        // Ensure captcha exists
        // It should be created when the app starts running
        if (!window.recaptchaVerifier) {
            throw new Error('No recaptchaVerifier')
        }

        // Reset the captcha
        if (window.grecaptcha && window.recaptchaWidgetId) {
            window.grecaptcha.reset(window.recaptchaWidgetId);
        }

        // Ensure the number is a valid USA phone number
        // This is very important because the server can only
        // handle USA phone numbers
        const numberValues = phone(phoneNumber, 'USA')
        const isValid = numberValues.length === 2 && numberValues[1] === 'USA'
        if (!isValid) {
            throw new Error('Please try again with a valid United States phone number')
        }

        // Attempt to sign user in
        await firebase.auth().signOut()
        return firebase.auth().signInWithPhoneNumber(
            formatPhoneNumber(phoneNumber),
            window.recaptchaVerifier
        )

    }

    const postFindClient = (phoneNumber: string) => {
        if (!username) throw new Error('Stylist not found')
        return post(`stylists/${username}/findClient`, { 
            phoneNumber
        })
    }

    const postAddName = (givenName: string, familyName: string, clientId: string) => {
        if (!username) throw new Error('Stylist not found')
        return post(`stylists/${username}/clients/${clientId}/addname`, { 
            givenName,
            familyName
        }, true)
    }

    const getSelf = () => {
        if (!username) throw new Error('Stylist not found')
        return get(`stylists/${username}/clients/self`, true)
    }

    const postSignup = (emailAddress: string, givenName: string, familyName: string) => {
        if (!username) throw new Error('Stylist not found')
        return post(`stylists/${username}/clients/signup`, { 
            emailAddress,
            givenName,
            familyName
        }, true)
    }

    const getFullProfile = (clientId: string) => {
        if (!username) throw new Error('Stylist not found')
        return get(`stylists/${username}/clients/${clientId}`, true)
    }

    const postNewSavedCard = (clientId: string, token: string) => {
        if (!username) throw new Error('Stylist not found')
        return post(`stylists/${username}/clients/${clientId}/addsavedcard`, { 
            paymentSourceToken: token
        }, true)
    }

    const deleteSavedCard = (clientId: string, savedCardId: string) => {
        if (!username) throw new Error('Stylist not found')
        return del(`stylists/${username}/clients/${clientId}/removesavedcard/${savedCardId}`, true)
    }

    const postAppointment = (data: any) => {
        if (!username) throw new Error('Stylist not found')
        return post(`stylists/${username}/appointments`, data, true)
    }

    const setCurrentClientId = (clientId: string | null) => {
        if (clientId) {
            localStorage.setItem(CLIENT_ID, clientId)
        } else {
            localStorage.removeItem(CLIENT_ID)
        }
    }

    const getCurrentClientId = () => {
        return localStorage.getItem(CLIENT_ID)
    }

    const logout = async () => {
        setCurrentClientId(null)
        await firebase.auth().signOut()
        window.location.reload()
    }

    return {
        showLogOutConfirmation,
        signInWithPhone,
        postFindClient,
        getSelf,
        postAddName,
        postSignup,
        getFullProfile,
        postNewSavedCard,
        deleteSavedCard,
        postAppointment,
        setCurrentClientId,
        getCurrentClientId,
        logout
    }

}