import React from 'react';
import moment, { Moment } from 'moment';
import { utcToZonedTime } from 'date-fns-tz';

import { DatePicker } from 'app/components/datepicker/Datepicker';

import { DateSelectProps } from './DateSelect.types';

export const DateSelect = ({ input: { onChange }, dates, closeDays, mutators, timeZone }: DateSelectProps) => {
  const nowTimezoned = utcToZonedTime(new Date(), timeZone);
  const startDate = moment(nowTimezoned);
  const endDate = moment(nowTimezoned).add(365, 'days');

  const makeOnDateChange = (key: string) => (newDate: Moment | Date | null) => {
    mutators.setHourToNull();
    onChange({
      ...newDate,
    });
  };

  return (
    <DatePicker
      startDate={startDate}
      endDate={endDate}
      dates={dates}
      onDateChange={makeOnDateChange('startingAt')}
      closeDays={closeDays as []}
      timeZone={timeZone}
    />
  );
};
