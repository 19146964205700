import styled, { css, to } from 'app/theme';

export const Wrapper = styled.div`
  ${({ theme: { variables } }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 475px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    margin-bottom: 24px;
    min-height: 100vh;

    ${to('large')} {
      width: calc(100% - 48px);

      form {
        width: calc(100% - 48px);
        max-width: 475px;
      }

    }

    form {
      width: 100%;
      max-width: 475px;
    }

    .title {
      padding-top: 24px;
      padding-left: 24px;
      padding-right: 24px;
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      color: ${variables.colors.mediumGray};
    }

  `}
`;

export const Tabs = styled.div`
  ${({ theme: { variables } }) => css`
    align-items: flex-start;
    border: solid ${variables.colors.neutral1};
    border-width: 1px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
  `}
`;

export const Tab = styled.div<{ isActive?: boolean }>`
  ${({ isActive, theme: { variables } }) => css`
    border-bottom: ${isActive ? '1px solid' : 'none'};
    color: ${isActive ? variables.colors.primary : variables.colors.infoGray};
    flex: 1;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;

    :hover {
      background-color: ${variables.colors.neutral1};
    }

    a {
      display: block;
      height: 100%;
      height: 56px;
      line-height: 56px;
    }
  `}
`;

export const NewBooking = styled.div`
  ${({ theme: { variables } }) => css`
    width: 100%;

    button {
      background: ${variables.colors.white};
      border: 1px solid ${variables.colors.primary};
      border-radius: 2px;
      color: ${variables.colors.primary};
      cursor: pointer;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.69;
      padding: 0;
      text-align: center;
      width: 100%;

      a {
        display: block;
        padding: ${variables.margins.marginMedium} ${variables.margins.marginBig};
      }

      &:hover {
        background: ${variables.colors.primary};
        color: ${variables.colors.white};
      }
    }
  `}
`;
