import styled, { css } from 'app/theme';

export const ProfileInformationWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
  h1 {
    font-size: ${props => props.theme.variables.fonts.size.bigger1};
    margin-bottom: ${props => props.theme.variables.margins.marginMedium};
  }

  .hidden {
    display: none;
  }
`;

export const ProfileInformationAddress = styled.div`
  font-size: ${props => props.theme.variables.fonts.size.smaller1};
  line-height: 17px;

  .marker {
    margin-right: 5px;
  }
`;

export const ProfileInformationPhone = styled.div`
  font-size: ${props => props.theme.variables.fonts.size.smaller1};
  margin-top: ${props => props.theme.variables.margins.marginMedium};
`;

interface BusinessName {
  noBackground: boolean;
}

export const BusinessName = styled.p<BusinessName>`
  ${({ noBackground, theme: { variables } }) => css`
    color: ${noBackground ? variables.colors.manatee : variables.colors.alto};
    font-size: ${variables.fonts.size.smaller1};
    font-weight: ${variables.fonts.weight.semiBold};
    line-height: 17px;
    margin-bottom: 10px;
`}`;
