import React, { useContext, useState } from 'react';

import { Redirect, useHistory } from 'react-router';
import { BookingContext } from 'app/context/BookingContext';
import { STEPS } from 'app/utils/consts';
import { StylistContext } from 'app/context/StylistContext';
import { Button } from 'app/components/button/Button';
import { useUserRepository } from 'app/repository/UserRepository';
import { useStylistRepository } from 'app/repository/StylistRepository';
import { CreditCardView } from 'app/components/CreditCardView';
import { EmptyList } from 'app/styles/ListStyles';
import { CheckOptionView } from './CheckOptionView';
import useModal from 'app/utils/useModal';
import Modal from 'app/components/modal/Modal';
import { Divider } from './Divider';
import { LoadingView } from './LoadingView';
import { ErrorView } from './ErrorView';
import { Wrapper } from 'app/styles/BookingWrapperStyles';
import { ModalContent } from 'app/styles/CardAuthorizationStyles';
import { ROUTES } from 'app/utils/routes';
import { catchError } from 'app/utils/analytics';

export const PaymentContainer = () => {

  const history = useHistory()
  const userRepo = useUserRepository()
  const stylistRepo = useStylistRepository()
  const bookingContext = useContext(BookingContext)
  const stylistContext = useContext(StylistContext)
  const { isShowing, toggle } = useModal()
  const username = stylistRepo.getStylistUsername()

  // Redirect to step if unsafe
  if (!bookingContext.services) {
    return <Redirect to={`/${username}/booking/${STEPS.SELECT_SERVICE}/`} />
  } else if (!bookingContext.date) {
    return <Redirect to={`/${username}/booking/${STEPS.SELECT_DATE}/`} />
  } else if (!bookingContext.profile) {
    return <Redirect to={`/${username}/booking/${STEPS.SELECT_USER}/`} />
  }

  // Show / Hide values
  const stylistProfile = stylistContext.stylist.profile
  const clientProfile = bookingContext.profile
  const cancellationPolicy = stylistProfile.cancellationSettings
  const onlineBookingsRequireApproval = stylistProfile.bookingSettings.onlineBookingsRequireApproval

  const isCardRequired = () => {

    const onlineBookingsRequireCard = stylistProfile.bookingSettings.onlineBookingsRequireCard
    const isAllRequired = onlineBookingsRequireCard === 'all'

    // Break if card is required by all
    if (isAllRequired) return true

    // If required on client break
    const isClientCardRequired = clientProfile.onlineBookingsRequireCard
    if (isClientCardRequired) return true

    // Check if new client requires card
    const isNewRequired = onlineBookingsRequireCard === 'new'
    return isNewRequired && !clientProfile.lastUsedSavedCard

  }

  // Grab the safest last used saved card
  const lastUsedSavedCard = () => {
    if (!isCardRequired()) return null
    const profile = bookingContext.profile
    return profile ? profile.lastUsedSavedCard : null
  }

  const [selectedSavedCardId, setSelectedSavedCardId] = useState(lastUsedSavedCard())
  const [isCancellationPolicyAccepted, setIsCancellationPolicyAccepted] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>()

  const currentCard = () => {
    const currentCards = savedCards().filter((card: any) => {
      return card.id === selectedSavedCardId
    })
    return currentCards.length ? currentCards[0] : null
  }

  async function bookAppointment(e: any) {
    e.preventDefault()

    try {
      setIsLoading(true)
      setError(null)

      const data = bookingContext.getBookingPayload(currentCard())
      const res = await userRepo.postAppointment(data)
      bookingContext.clear()

      // Open appointment detail
      history.push(`/a/${res.appointment.id}/`)

    } catch (error) {
      setError(catchError('book_appointment', error.message))
      setIsLoading(false)
    }

  }

  const savedCards = () => {
    if (!clientProfile.savedCards) return []
    return clientProfile.savedCards 
  }

  const isButtonEnabled = () => {
    if (isCardRequired()) {
      return selectedSavedCardId !== null && isCancellationPolicyAccepted
    } else {
      return isCancellationPolicyAccepted
    }
  }

  const cancelPolicyViews = () => {

    const settings = stylistProfile.cancellationSettings
    const onlineCancellations = settings.onlineCancellations
    const days = `${settings.cancellationFeeMinHours / 24}`
    const cancelFeePercent = clientProfile.cancellationFeePercent ? clientProfile.cancellationFeePercent : settings.cancellationFeePercent
    const percentage = `${(cancelFeePercent * 100).toFixed(2)}`

    return (
      <>
        <p>
          I agree to the <span onClick={(e) => {
            e.preventDefault()
            toggle()
          }}>cancellation policy</span>
        </p>
        <Modal hide={toggle} isShowing={isShowing} title={`${stylistProfile.firstName} ${stylistProfile.lastName}'s Cancellation Policy`}>
          <ModalContent>
            <p style={{ marginBottom: '12px' }}>You have <strong>{days}</strong> days before your appointment to cancel or alter your scheduled services without penalty. If you must cancel your appointment within {days} days, you will be charged a <strong>{percentage}%</strong> cancellation fee and can be charged to your credit card on file, if provided.</p>
            <p style={{ marginBottom: '12px' }}>You may cancel your appointment online by following the link provided in your confirmation email or SMS that you will receive upon booking the appointment.</p>
            <p>If you have questions or concerns regarding this cancellation policy, please contact {stylistProfile.firstName} {stylistProfile.lastName} directly.</p>
            {onlineCancellations && (
              <p>
                <strong>You can cancel online</strong> by visiting your appointment’s URL from the confirmation email or
                SMS that you’ll receive.
              </p>
            )}
          </ModalContent>
        </Modal>
      </>
    )
  }

  return (
    <Wrapper>
      {error && <ErrorView message={error.message} />}
      {isLoading ? <LoadingView /> : (
        <form onSubmit={bookAppointment}>
          {isCardRequired() && (
            <>
              {savedCards().map((card: any) => {
                  return (
                    <CreditCardView 
                      key={card.id}
                      card={card}
                      checked={selectedSavedCardId === card.id}
                      onChange={(card) => {
                        setSelectedSavedCardId(card.id)
                      }}
                    />
                  )
              })}
              {!savedCards().length && (
                <EmptyList>No saved cards available. Please add one to finish booking.</EmptyList>
              )}
              <Button
                type="button"
                outlined={true}
                style={{ 
                  marginTop: '24px',
                  marginBottom: '24px',
                  width: '100%'
                }}
                onClick={() => {
                  history.push(ROUTES.USER_ADD_CARD.replace(':stylist', username))
                }}>
                Add a card
              </Button>
              <Divider 
                style={{
                  marginBottom: '24px'
                }} />
            </>
          )}
          {cancellationPolicy && (
            <div style={{
              marginBottom: '24px'
            }}>
              <CheckOptionView 
                name="agreeCancellationPolicy"
                checked={isCancellationPolicyAccepted} 
                onChange={(value) => setIsCancellationPolicyAccepted(value)}>
                  {cancelPolicyViews()}
              </CheckOptionView>
            </div>
          )}
          <Button
            type="submit"
            style={{
              width: '100%'
            }}
            disabled={!isButtonEnabled()}>
            {onlineBookingsRequireApproval ? 'Request to Book Appointment' : 'Book Appointment'}
          </Button>
        </form>
      )}
    </Wrapper>
  )
}
