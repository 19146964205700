import React from 'react'
import styled, { css } from 'app/theme'

export const UserRadioViewStyles = styled.div`
    ${({ theme: { variables } }) => css`
        background-color: ${variables.colors.white};
        position: relative;
        border-bottom: 1px solid ${variables.colors.neutral1};
        height: 50px;

        :hover {
            background-color: ${variables.colors.neutral1};
        }

        label {
            cursor: pointer;

            p {
                color: ${variables.colors.black};
                font-size: 14px;
                font-weight: 600;
                height: 100%;
                line-height: 50px;
                padding-left: 24px;
                padding-right: 72px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

        }

        input {
            display: none;
        }

        .check-container {
            width: 22px;
            height: 22px;
            right: 24px;
            top: 0;
            bottom: 0;
            margin: auto;
            position: absolute;
            border-radius: 50%;
            border: 1px solid ${variables.colors.blackTransparent};
        }

        .check {
            width: 22px;
            height: 22px;
            right: 24px;
            top: 0;
            bottom: 0;
            margin: auto;
            position: absolute;
            border-radius: 50%;
            border: 1px solid transparent;
            background-image: url(${variables.icons.select});
            background-repeat: no-repeat;
            background-position: center;
            background-color: ${variables.colors.primary};
        }

    `}
`;

export type UserRadioViewProps = {
    profile: any,
    checked: boolean,
    onChange: (profile: any) => void
}

export const UserRadioView = ({ 
    profile, 
    checked,
    onChange
}: UserRadioViewProps) => {

    return (
        <UserRadioViewStyles>
            <label>
                <input 
                    type="radio" 
                    name="selectedProfileId" 
                    value={profile.id}
                    checked={checked}
                    onChange={() => onChange(profile)} />
                <p>{`${profile.givenName} ${profile.familyName}`}</p>
                <div className="check-container" />
                {checked && <div className="check" />}
            </label>
        </UserRadioViewStyles>
    )

}