import React, {
    createContext,
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import firebase from 'firebase/app'
import { useUserRepository } from 'app/repository/UserRepository';
import { formatPhoneNumber } from 'app/utils/phonePatterns';
import { catchError } from 'app/utils/analytics';

export interface UserContextType {
    profiles: any[],
    setProfiles: (profiles: any[]) => void,
    currentProfile: any,
    setCurrentProfile: (profile: any) => void,
    isLoading: boolean,
    confirmationResult: any,
    setConfirmationResult: (result: any) => void,
    currentPhoneNumber: any,
    setCurrentPhoneNumber: (number: any) => void
}

export const UserContext = createContext<UserContextType>({
    profiles: [],
    setProfiles: () => {},
    currentProfile: null,
    setCurrentProfile: () => {},
    isLoading: true,
    confirmationResult: null,
    setConfirmationResult: () => {},
    currentPhoneNumber: null,
    setCurrentPhoneNumber: () => {}
})

export const NewUserProvider: FunctionComponent = ({ children }) => {

    const userRepo = useUserRepository()
    const [profiles, setProfiles] = useState<any[]>([])
    const [currentProfile, setCurrentProfile] = useState<any>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [confirmationResult, setConfirmationResult] = useState<any>(null)
    const [currentPhoneNumber, setCurrentPhoneNumber] = useState<any>(null)

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async user => {

            setIsLoading(true)

            // When there is a user, we need to try and grab their profiles
            if (user && user.phoneNumber) {
                try {

                    setCurrentPhoneNumber(formatPhoneNumber(user.phoneNumber))

                    const res = await userRepo.postFindClient(user.phoneNumber)

                    if (res.exists) {

                        const self = await userRepo.getSelf()

                        // Set the current profile
                        const profiles = self.profiles
                        setProfiles(profiles)

                        // Attempt to find the matching client
                        const localClientId = userRepo.getCurrentClientId()
                        const matches = profiles.filter((profile: any) => {
                            return profile.id === localClientId
                        })

                        // Set the current profile
                        if (matches.length) {
                            setCurrentProfile(matches[0])
                        } else {
                            setCurrentProfile(profiles[0])
                        }

                    } else {
                        clearProfile()
                    }

                } catch (error) {
                    catchError('user_context', error.message)
                }
            } else {
                clearProfile()
            }

            setIsLoading(false)

        })
    }, [])

    const clearProfile = () => {
        setProfiles([])
        setCurrentProfile(null)
        userRepo.setCurrentClientId(null)
        setCurrentPhoneNumber(null)
    }

    // Called when the current user changes
    useEffect(() => {

        // Update the current selected client id
        if (currentProfile) {
            const id = currentProfile.id
            userRepo.setCurrentClientId(id)
        }

    }, [currentProfile])

    return (
        <UserContext.Provider
            value={{
                profiles: profiles, setProfiles: setProfiles,
                currentProfile: currentProfile, setCurrentProfile: setCurrentProfile,
                isLoading: isLoading,
                confirmationResult: confirmationResult, setConfirmationResult: setConfirmationResult,
                currentPhoneNumber: currentPhoneNumber, setCurrentPhoneNumber: setCurrentPhoneNumber
            }}
        >
          {children}
        </UserContext.Provider>
    )

}