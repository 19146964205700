import React, { Fragment, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { Field } from 'react-final-form';

import { TimeSelectWrapper, TimeZoneInformation } from './TimeSelect.styles';
import { HoursSlotType, PeriodsType, TimeSelectProps } from './TimeSelect.types';

import { formatTimezone } from '../utils/formatTimezone';

import {
  AFTERNOON_END_HOUR,
  DAY_FORMAT,
  HOUR_FORMAT,
  HOUR_FORMAT_LONG,
  MORNING_END_HOUR,
  PERIODS_ORDER,
} from '../consts';

const timeSlotsCount = (startTime: Moment, endTime: Moment) => {
  const timeSlots: string[] = [];

  while (startTime <= endTime) {
    const newTime = moment(startTime).format(HOUR_FORMAT);
    if (!timeSlots.includes(newTime)) {
      timeSlots.push(newTime);
    }
    startTime.add(15, 'm');
  }

  return timeSlots;
};

export const TimeSelect = ({
  dates,
  duration,
  timeZone,
  onTimeSelect,
  selectedDay,
  setIsLoading,
}: TimeSelectProps) => {
  if (!dates || !selectedDay) {
    return null;
  }

  // setIsLoading(false);

  const periods = useMemo(() => {
    const formattedSelectedDay = moment(selectedDay).format(DAY_FORMAT);
    const hoursOfSelectedDay = dates.reduce<HoursSlotType[]>((accumulator, date) => {
      const dateDay = moment(date.startingAt).format(DAY_FORMAT);
      if (dateDay === formattedSelectedDay) {
        accumulator.push({
          hours: timeSlotsCount(moment(date.startingAt), moment(date.endingAt).subtract(duration, 'm')),
        });
      }

      return accumulator;
    }, []);

    return hoursOfSelectedDay
      .flatMap(item => item.hours)
      .reduce<PeriodsType>(
        (accumulator, time) => {
          if (time < MORNING_END_HOUR) {
            accumulator.morning.hours.push(time);
          } else if (time >= AFTERNOON_END_HOUR) {
            accumulator.evening.hours.push(time);
          } else {
            accumulator.afternoon.hours.push(time);
          }

          return accumulator;
        },
        {
          afternoon: {
            hours: [],
            label: 'Afternoon',
          },
          evening: {
            hours: [],
            label: 'Evening',
          },
          morning: {
            hours: [],
            label: 'Morning',
          },
        },
      );
  }, [dates, duration, timeZone, selectedDay]);

  return (
    <TimeSelectWrapper>
      <TimeZoneInformation>
        Displayed in <span>{formatTimezone(timeZone)}</span>
      </TimeZoneInformation>
      {PERIODS_ORDER.map(periodName => {
        const { label, hours } = periods[periodName];

        return (
          <div className="day-header" key={periodName}>
            <p>{label}</p>
            <div className="day-hours radio-custom">
              {hours.length ? (
                hours.map(time => (
                  <Fragment key={time}>
                    <Field name="hour" component="input" type="radio" id={time} value={time} onClick={() => onTimeSelect(time)} />
                    <label htmlFor={time}>{moment(time, HOUR_FORMAT).format(HOUR_FORMAT_LONG)}</label>
                  </Fragment>
                ))
              ) : (
                <div className="hours-na">All Booked</div>
              )}
            </div>
          </div>
        );
      })}
    </TimeSelectWrapper>
  );
};
