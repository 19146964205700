import React from 'react';

import { useHistory } from 'react-router';
import { ROUTES } from 'app/utils/routes';
import { useStylistRepository } from 'app/repository/StylistRepository';
import { CreditCardView } from 'app/components/CreditCardView';
import { Button } from 'app/components/button/Button';
import { EmptyList } from 'app/styles/ListStyles';

export interface CardsListProps {
  cards: any[],
  canDelete: boolean,
  onClickDelete: (card: any) => void
}

export const CardsList = ({ cards, canDelete, onClickDelete }: CardsListProps) => {

  const history = useHistory()
  const stylistRepo = useStylistRepository()
  const username = stylistRepo.getStylistUsername()

  function addCard() {
    history.push(ROUTES.USER_ADD_CARD.replace(':stylist', username))
  }

  return (
    <div style={{ width: '100%' }}>
      {cards.length === 0 ? (
        <EmptyList>You have no saved payment cards</EmptyList>
      ) : (
        <div 
          style={{
            marginTop: '24px',
            marginBottom: '24px'

          }}>
          {cards.map(card => {
            return (
              <CreditCardView 
                key={card.id}
                card={card}
                onDelete={onClickDelete} />
            )
          })}
        </div>
      )}
      <Button 
        style={{
          width: '100%'
        }}
        onClick={() => { addCard() }} 
        type="button">
        Add a card
      </Button>
    </div>
  )
}
