import { createGlobalStyle } from './index';

export const GlobalStyle = createGlobalStyle`

/** google fonts */
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900&display=swap');
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  main, menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  html {
    font-size: ${props => props.theme.variables.fonts.size.root};
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    width: 100vw;
  }

  body {
    color: ${props => props.theme.variables.colors.black};
    font-family: ${props => props.theme.variables.typography.primaryFont};
    font-size: ${props => props.theme.variables.fonts.size.base};
    font-weight: ${props => props.theme.variables.fonts.weight.medium};
  }

  html, body {
    box-sizing: border-box;
    height: 100%;
    margin: 0;
    overflow-x: hidden;
    width: 100%;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  svg {
    display: block;
  }

  /* fix inputs on mobile */

  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  select:focus,
  textarea {
    font-size: inherit;
  }

  button {
    text-transform: uppercase !important;
    font-family: ${props => props.theme.variables.typography.primaryFont} !important;
  }

  input::placeholder {
    color: ${props => props.theme.variables.colors.infoGray};
    font-style: italic;
  }

  #recaptcha-container {
    visibility: hidden;
  }
`;
