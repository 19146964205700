import styled, { css, from, to } from 'app/theme';
import { lineClamp } from 'app/theme/lineClamp';

const CHECKBOX_AREA_WIDTH = '60px';
const DESKTOP_CARD_HEIGHT = '120px';
const DESKTOP_IMAGE_HEIGHT = '120px';
const MOBILE_CARD_HEIGHT = '90px';
const MOBILE_IMAGE_HEIGHT = '89px';

export const CardBox = styled.div`
  ${({ theme: { variables } }) => css`
    align-items: center;
    background: ${variables.colors.white};
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    height: ${DESKTOP_CARD_HEIGHT};
    width: 475px;
    margin-bottom: ${variables.margins.marginBig};

    ${from('large')} {
      border: 1px solid ${variables.colors.lightGray};
    }

    ${to('large')} {
      border-top: 1px solid ${variables.colors.lightGray};
      height: ${MOBILE_CARD_HEIGHT};
      width: auto;
      margin-bottom: 0;
    }
  `}
`;

export const CardWrapper = styled.div`
  ${to('large')} {
    width: 100%;

    &:last-child {
      ${CardBox} {
        border-bottom: 1px solid ${props => props.theme.variables.colors.lightGray};
      }
    }
  }
`;

export const ProductImage = styled.div`
  cursor: pointer;
  background-color: ${props => props.theme.variables.colors.infoGray};
  height: ${DESKTOP_IMAGE_HEIGHT};
  width: ${DESKTOP_IMAGE_HEIGHT};
  background-size: ${DESKTOP_IMAGE_HEIGHT};
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;

  img {
    width: ${DESKTOP_CARD_HEIGHT};
    height: auto;
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  span {
    width: ${DESKTOP_CARD_HEIGHT};
    height: auto;
    display: block;
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f6f6;
    background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
    background-size: 600px 104px;
  }

  ${to('large')} {
    width: ${MOBILE_IMAGE_HEIGHT};
    height: ${MOBILE_IMAGE_HEIGHT};

    img {
      width: ${MOBILE_IMAGE_HEIGHT};
      height: 100%;
    }

    span {
      width: ${MOBILE_IMAGE_HEIGHT};
    }
  }
`;

export const ProductImageBox = styled.div`
  width: ${DESKTOP_IMAGE_HEIGHT};
  height: ${DESKTOP_IMAGE_HEIGHT};
  display: block;

  ${to('large')} {
    height: ${MOBILE_IMAGE_HEIGHT};
    width: ${MOBILE_IMAGE_HEIGHT};
  }
`;

export const ProductDetails = styled.div`
  ${({ theme: { variables } }) => css`
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    max-width: calc(100% - ${CHECKBOX_AREA_WIDTH});
    padding-left: ${variables.margins.marginNormalTwice};
    -webkit-tap-highlight-color: transparent;

    .product-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .product-title {
      font-size: ${variables.fonts.size.bigger0};
      overflow: hidden;
      word-break: keep-all;
      ${lineClamp(2)};

      ${to('large')} {
        font-size: 0.8125rem;
        line-height: ${variables.fonts.size.root};
      }
    }

    .product-time {
      font-size: ${variables.fonts.size.smaller1};
      line-height: 20px;
      color: ${variables.colors.infoGray};
      margin-top: 12px;
    }

    .product-price {
      color: ${variables.colors.black};
      margin-left: 6px;

      &:before {
        content: '·';
        margin-right: 6px;
      }

      &:empty:before {
        display: none;
      }
    }

    .product-info-link {
      color: ${variables.colors.primary};
      margin-left: 6px;

      &:before {
        content: '·';
        margin-right: 6px;
      }
    }

    .product-wrapper__price {
      margin-top: 25px;

      ${to('large')} {
        margin-top: -4px;
      }
    }
    .edit {
      cursor: pointer;
    }

    .product-check {
      margin-right: -4px;
    }
  `}
`;

export const CheckBoxIconWrapper = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  padding-right: 20px;
  width: ${CHECKBOX_AREA_WIDTH};
`;

// modal

export const ModalImage = styled.div<{ backgroundImage: string }>`
  background-image: ${props => `url('${props.backgroundImage}')`};
  width: 100%;
  height: 220px;
  margin-top: 0;
  z-index: 100;
  border-radius: 4px 4px 0 0;
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const ModalContent = styled.div`
  ${({ theme: { variables } }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;

    .modal__content-wrapper {
      align-items: flex-start;
      padding-top: 20px;
      width: 355px;

      ${to('large')} {
        width: 100%;
      }

      &:first-child {
        padding-top: 0;
      }
    }

    .modal__image {
      width: 300px;
      height: 300px;

      img {
        max-width: 300px;
        height: auto;
      }

      span {
        width: 300px;
        height: auto;
      }
    }

    .product_details {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin: 15px 0;
    }

    .modal__product-title {
      font-size: 14px;
      overflow: hidden;
      word-break: break-word;

      ${to('medium')} {
        font-size: ${variables.fonts.size.smaller0};
        line-height: ${variables.fonts.size.root};
      }
    }

    .modal__product-time {
      font-size: 14px;
      color: ${variables.colors.infoGray};

      &:after {
        color: ${variables.colors.infoGray};
        content: '·';
        margin-right: 5px;
        padding-left: 5px;
      }

      &:last-child {
        &:after {
          content: '';
        }
      }
    }

    .modal__product-description {
      font-weight: normal;
      max-height: 300px;
      overflow: scroll;
    }

    p {
      font-size: 14px !important;
      width: 100%;
    }

  `}
`;
