import React, { useMemo } from 'react';
import { phoneParse } from 'app/utils/phoneParse';

import {
  BusinessName,
  ProfileInformationAddress,
  ProfileInformationPhone,
  ProfileInformationWrapper,
} from './ProfileInformation.styles';
import { ProfileInformationType } from './ProfileInformation.types';
import { prepareLocationURL } from 'app/utils/prepareLocationUrl';
import { prepareLocation } from 'app/utils/prepareLocation';

export const ProfileInformation = ({ location, contact, noBackground }: ProfileInformationType) => {
  const locationURL = useMemo(() => {
    if (location) {
      return prepareLocationURL(location);
    }

    return '';
  }, [location]);
  const locationString = useMemo(() => {
    if (location) {
      return prepareLocation(location, ', ');
    }

    return '';
  }, [location]);

  return (
    <ProfileInformationWrapper>
      <ProfileInformationAddress>
        {contact && contact.businessName && (
          <BusinessName noBackground={!!noBackground}>{contact.businessName}</BusinessName>
        )}
        {locationString && (
          <p>
            <a href={locationURL} rel="noopener noreferrer" title="Go to Google map" target="_blank">
              {locationString}
            </a>
          </p>
        )}
      </ProfileInformationAddress>
      <ProfileInformationPhone>
        {contact && contact.phoneNumber && (
          <p>
            <a href={`tel:${contact.phoneNumber}`}>{phoneParse(contact.phoneNumber)}</a>
          </p>
        )}
      </ProfileInformationPhone>
    </ProfileInformationWrapper>
  );
};
