import firebase from 'firebase/app';

export const getAppointment = () => {
  const url = window.location.pathname

  // Ensure there is an appointment id
  if (url.includes('/a/')) {
    return url.replace('/a/','').split('/')[0]
  }

  return null
}

export const getRandomId = (prefix: string) => {
  return Math.random().toString(36).replace('0.', prefix || '')
}

export const isPhoneNumberValid = (phoneNumber: string) => {
  const pattern = /^(\+?1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;

  return phoneNumber.search(pattern) !== -1;
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const plainNumber = phoneNumber.replace(/[^0-9]/g, '');

  if (plainNumber.substring(0, 1) !== '1') {
    return `+1${plainNumber}`;
  }

  return `+${plainNumber}`;
};

export const createCaptchaVerifier = () => {
  return new Promise<void>((resolve, reject) => {

    if (window.recaptchaVerifier) {
      resolve()
      return
    }

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', { size: 'invisible' })
    window.recaptchaVerifier.render().then((id: number) => {
      window.recaptchaWidgetId = id
      resolve()
    })

  })
}
