import React, { useContext, useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import { BookingContext } from 'app/context/BookingContext';
import { ConfirmationCodeForm } from 'app/components/ConfirmationCodeForm';
import firebase from 'firebase/app';
import { UserContext } from 'app/context/UserContext';
import { useStylistRepository } from 'app/repository/StylistRepository';
import { Wrapper } from 'app/styles/BookingWrapperStyles';
import { ErrorView } from 'app/components/ErrorView';
import { LoadingView } from 'app/components/LoadingView';
import { useUserRepository } from 'app/repository/UserRepository';
import { catchError } from 'app/utils/analytics';

export type SignInCodeContainerProps = {
  isUserBooking: boolean,
  onUserSignedIn: () => void,
  onUnknownUserSignIn?: () => void
}

export const SignInCodeContainer = ({
  isUserBooking,
  onUserSignedIn,
  onUnknownUserSignIn
}: SignInCodeContainerProps) => {
  
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>()

  const { services, date } = useContext(BookingContext)
  const userContext = useContext(UserContext)

  const history = useHistory()
  const stylistRepo = useStylistRepository()
  const username = stylistRepo.getStylistUsername()
  const userRepo = useUserRepository()

  // Redirect
  if (isUserBooking) {
    if (!services) {
      return <Redirect to={`/${username}/booking/1/`} />
    } else if (!date) {
      return <Redirect to={`/${username}/booking/2/`} />
    } else if (!userContext.confirmationResult) {
      return <Redirect to={`/${username}/booking/3/`} />
    }
  }

  // User is signed in
  // We can drop
  if (firebase.auth().currentUser) {
    onUserSignedIn()
    return <></>
  }

  async function confirmCode(code: string) {
    setIsLoading(true)
    try {

      // Confirm the code and sign in
      await userContext.confirmationResult.confirm(code)

      // When the user is not booking
      // Lets check to see if they exist in the system
      // This eventually pushes them back to the booking
      // Flow instead of showing them the account management flow
      if (!isUserBooking) {
        const res = await userRepo.postFindClient(userContext.currentPhoneNumber)
        if (!res.exists && onUnknownUserSignIn) {
          onUnknownUserSignIn()
          return
        }
      }

      // Default calllback
      onUserSignedIn()

    } catch (error) {

      // Handle custom message
      if (error.code === 'auth/invalid-verification-code') {
        setError({
          message: 'The verification code you have entered to authenticate your phone number is invalid. Please tap Resend to verify your phone number is correct and try again.'
        })
      } else {
        setError(catchError('confirm_auth_code', error.message))
      }

      setIsLoading(false)
    }
  }

  const getContent = () => {
    return <ConfirmationCodeForm 
      onSubmitCode={(values) => {
        confirmCode(values.confirmationCode)
      }}
      onResendCode={() => {
        userContext.setConfirmationResult(null)
        history.goBack()
      }} />
  }

  return (
    <Wrapper>
      {error && <ErrorView message={error.message}/>}
      {isLoading ? (
        <LoadingView />
      ) : (
        getContent()
      )}
    </Wrapper>
  )
}
