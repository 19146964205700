import facebook from 'assets/icons/facebook.svg';
import instagram from 'assets/icons/instagram.svg';
import mail from 'assets/icons/mail.svg';
import select from 'assets/icons/select.svg';
import twitter from 'assets/icons/twitter.svg';
import close from 'assets/icons/icon-close.svg';

const breakpoints = {
  large: 992,
  medium: 766,
  small: 765,
  xlarge: 1356,
  xsmall: 500,
};

const icons = {
  facebook,
  instagram,
  mail,
  select,
  twitter,
  close
};

const layout = {
  column: {
    count: 12,
    gap: '1.5rem',
    width: '5.4375rem',
  },
};

const typography = {
  primaryFont: '"Montserrat", sans-serif',
};

const colors = {
  alto: '#d8d8d8',
  black: '#22242A',
  blackTransparent: 'rgba(0, 0, 0, .10)',
  gray: '#A7ACC0',
  infoGray: '#B7BCC9',
  lightGray: '#E6E9F0',
  lightGrayTransparent: 'rgba(34, 36, 42, .10)',
  lightGreen: '#89f4f0',
  lightRed: '#fc98a5',
  manatee: '#8A8F99',
  mediumGray: '#8D92A3',
  mediumGrayTransparent: 'rgba(141, 146, 163, .5)',
  neutral1: '#F1F3F8',
  neutral2: '#F7F8FA',
  neutral3: '#CDCDD7',
  primary: '#5B4FF7',
  primaryLight: '#6478D3',
  red: '#EF4B5F',
  redDark: '#E2374C',
  white: '#fff',
};

const fonts = {
  size: {
    base: '1rem',
    bigger0: '1.125rem',
    bigger1: '1.375rem',
    bigger2: '1.625rem',
    bigger3: '2rem',
    bigger4: '2.1875rem',
    bigger5: '21.5625rem',
    bigger6: '3.75rem',
    root: '16px',
    smaller0: '0.875rem',
    smaller1: '0.75rem',
    smaller15: '0.9375rem',
    smaller2: '0.675rem',
    smaller3: '0.5625rem',
  },
  weight: {
    bold: 700,
    extraBold: 800,
    extraLight: 200,
    heavy: 900,
    light: 300,
    medium: 500,
    regular: 400,
    semiBold: 600,
    thin: 100,
  },
};

const radius = {
  primaryRadius: '4px',
};

const margins = {
  marginBig: '20px',
  marginBigger: '24px',
  marginMedium: '10px',
  marginNormal: '16px',
  marginNormalTwice: '30px',
  marginSmall: '5px',
  marginSuperBig: '50px',
};

export const variables = {
  breakpoints,
  colors,
  fonts,
  icons,
  layout,
  margins,
  radius,
  typography,
};
