import { InputStyles } from 'app/styles/InputStyles';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Button } from 'app/components/button/Button';
import { FocusInput } from 'app/components/focusInput/FocusInput';

export type ConfirmationCodeFormProps = {
  onSubmitCode: (values: any) => void,
  onResendCode: () => void
}

export const ConfirmationCodeForm = ({ 
  onSubmitCode,
  onResendCode
}: ConfirmationCodeFormProps) => {

  const [isValid, setIsValid] = useState(false)

  return (
    <InputStyles>
      <Form
        onSubmit={onSubmitCode}
        render={({ handleSubmit, values }) => {

          // React to the change
          if (values.confirmationCode) {
            setIsValid(values.confirmationCode.length >= 6)
          } else {
            setIsValid(false)
          }

          return (
            <form onSubmit={handleSubmit}>
              <Field
                component="input"
                type="tel"
                pattern="[0-9]{6}"
                placeholder="123456"
                maxLength={6}
                required={true}
                name="confirmationCode"
              >
                {({ input, meta: { error, touched }, ...rest }) => (
                  <FocusInput
                    className="field-validation"
                    inputProps={{ ...input, ...rest }}
                    inputClassName="sign-in-code"
                    label="Sign In Code"
                    wrapperClassName="field-wrapper"
                    autofocus={true}
                  />
                )}
              </Field>
              <div style={{
                display: 'flex',
                flexDirection: 'column'
              }}>
                {!isValid ? (
                  <Button
                    type="button"
                    outlined={true}
                    onClick={() => onResendCode()}>
                      Resend code
                  </Button>
                ) : (
                  <Button
                    disabled={!isValid}
                    type="submit">
                      Continue
                  </Button>
                )}
              </div>
            </form>
          )
        }}
      />
    </InputStyles>
  )

}
