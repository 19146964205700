import React from 'react'
import styled, { css } from 'app/theme'

export const LoadingViewStyles = styled.div`
    ${({ theme: { variables } }) => css`

        margin-left: auto;
        margin-right: auto;
        max-width: 475px;
        width: 100%;
        margin-top: 24px;
        margin-bottom: 24px;

        display: flex;
        flex-direction: column;

        .top {
            height: 12px;
            width: 100%;
            margin-bottom: 24px;

            background: linear-gradient(180deg, #E6E9F0, #ffffff);
            background-size: 400% 400%;
            -webkit-animation: Skeleton 2s ease infinite;
            -moz-animation: Skeleton 2s ease infinite;
            animation: Skeleton 2s ease infinite;
        }

        .middle {
            height: 12px;
            width: 90%;
            margin-bottom: 24px;

            background: linear-gradient(180deg, #E6E9F0, #ffffff);
            background-size: 400% 400%;
            -webkit-animation: Skeleton 2s ease infinite;
            -moz-animation: Skeleton 2s ease infinite;
            animation: Skeleton 2s ease infinite;
        }

        .bottom {
            height: 12px;
            width: 25%;

            background: linear-gradient(180deg, #E6E9F0, #ffffff);
            background-size: 400% 400%;
            -webkit-animation: Skeleton 2s ease infinite;
            -moz-animation: Skeleton 2s ease infinite;
            animation: Skeleton 2s ease infinite;
        }
        
        @-webkit-keyframes Skeleton {
            0%{background-position:50% 0%}
            50%{background-position:50% 100%}
            100%{background-position:50% 0%}
        }
        @-moz-keyframes Skeleton {
            0%{background-position:50% 0%}
            50%{background-position:50% 100%}
            100%{background-position:50% 0%}
        }
        @keyframes Skeleton {
            0%{background-position:50% 0%}
            50%{background-position:50% 100%}
            100%{background-position:50% 0%}
        }

    `}
`;

export const LoadingView = () => {

    return (
        <LoadingViewStyles>
            <div className="top" />
            <div className="middle" />
            <div className="bottom" />
        </LoadingViewStyles>
    )

}