import React from 'react';
import { injectStripe } from 'react-stripe-elements';
import { Redirect } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';

import { CalendarContainer } from 'app/components/calendar/CalendarContainer';
import { ServicesContainer } from 'app/components/ServicesContainer';
import { useScrollStylistProfile } from 'app/utils/useScrollStylistProfile';
import { scrollTo } from 'app/utils/scrollTo';

import { STEPS } from '../utils/consts';
import { SignInPhoneContainer } from 'app/components/SignInPhoneContainer';
import { SignInCodeContainer } from 'app/components/SignInCodeContainer';
import { PaymentContainer } from 'app/components/PaymentContainer';
import { useStylistRepository } from 'app/repository/StylistRepository';
import { UserDetailsContainer } from './UserDetailsContainer';
import { HeaderView } from './HeaderView';

export type OrderFormProps = {
  stylist: any
}

const OrderForm = ({ stylist }: OrderFormProps) => {

  const history = useHistory()
  const { step } = useParams()
  const currentStep = parseInt(step || '1', 10)
  const stylistRepo = useStylistRepository()
  const username = stylistRepo.getStylistUsername()

  if (currentStep === STEPS.SELECT_SERVICE) {
    scrollTo(0)
  } else {
    useScrollStylistProfile(true, [step])
  }

  function getContent() {
    switch (currentStep) {
      case STEPS.SELECT_SERVICE: { 

        const isApprovalNeeded = stylist.profile.bookingSettings.onlineBookingsRequireApproval
        const title = isApprovalNeeded ? 'Request to book services' : 'Choose services'

        return <>
          <HeaderView title={title} />
          <ServicesContainer 
            onSubmit={() => {
              history.push(`/${username}/booking/${STEPS.SELECT_DATE}/`)
            }} />
        </>

      }
      case STEPS.SELECT_DATE: {
        return <>
          <HeaderView title={'Choose date & time'} />
          <CalendarContainer 
            onTimeSelect={() => {
              history.push(`/${username}/booking/${STEPS.ENTER_PHONE_NUMBER}/`)
            }} />
        </>
      }
      case STEPS.ENTER_PHONE_NUMBER: {
        return  <>
          <HeaderView title={'Enter your contact info'} />
          <SignInPhoneContainer
            isUserBooking={true}
            onPhoneNumberSubmit={() => {
              history.push(`/${username}/booking/${STEPS.ENTER_SIGNIN_CODE}/`)
            }}
            onUserSignedIn={() => {
              history.replace(`/${username}/booking/${STEPS.SELECT_USER}/`)
            }} />
        </>
      }
      case STEPS.ENTER_SIGNIN_CODE: {
        return <>
          <HeaderView title={'Enter sign in code'} />
          <SignInCodeContainer
            isUserBooking={true}
            onUserSignedIn={() => {
              history.replace(`/${username}/booking/${STEPS.SELECT_USER}/`)
            }} />
          </>
      }
      case STEPS.SELECT_USER: {
        return <>
          <HeaderView title={'Reserve your appointment'} />
          <UserDetailsContainer 
            onUserSelected={(profile: any) => {
              history.push(`/${username}/booking/${STEPS.ENTER_PAYMENT}/`)
            }} />
          </>
      }
      case STEPS.ENTER_PAYMENT: {
        return <>
          <HeaderView title={'Reserve your appointment'} />
          <PaymentContainer />
          </>
      }
      default: {
        return <Redirect to={`/${stylist.id}/booking/1/`} />;
      }
    }
  }

  return getContent()
}

export default injectStripe(OrderForm);
