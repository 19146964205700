import close from 'assets/icons/icon-close.svg';
import React from 'react';
import ReactDOM from 'react-dom';

import { ModalWrapper } from './Modal.styles';
import { ModalProps } from './Modal.types';

const cancelEvent = (event: React.MouseEvent<HTMLElement>) => event.stopPropagation();

const Modal = ({ isShowing, hide, children, title }: ModalProps) =>
  isShowing
    ? ReactDOM.createPortal(
        <ModalWrapper>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal={true}
            aria-hidden={true}
            onClick={hide}
            role="dialog"
            tabIndex={-1}
          >
            <div className="modal" onClick={cancelEvent}>
              <div className="modal-header">
                <h3>{title}</h3>
                <img
                  src={close}
                  className="modal-close-button"
                  data-dismiss="modal"
                  aria-label="Close"
                  alt="Close"
                  onClick={hide}
                />
              </div>
              {children}
            </div>
          </div>
        </ModalWrapper>,
        document.body,
      )
    : null;

export default Modal;
