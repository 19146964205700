interface ITimezones {
  [key: string]: string;
}

/* tslint:disable: object-literal-sort-keys */
const TIMEZONE_MAP: ITimezones = {
  'America/New_York': 'Eastern Standard Time',
  'America/Chicago': 'Central Standard Time',
  'America/Denver': 'Mountain Standard Time',
  'America/Los_Angeles': 'Pacific Standard Time',
  'America/Juneau': 'Alaska Standard Time',
  'Pacific/Honolulu': 'Hawaii-Aleutian Standard Time',
};

const SHORT_TIMEZONE_MAP: ITimezones = {
  'America/New_York': 'EST',
  'America/Chicago': 'CST',
  'America/Denver': 'MST',
  'America/Los_Angeles': 'PST',
  'America/Juneau': 'AST',
  'Pacific/Honolulu': 'HAST',
};

export const formatTimezone = (timeZone: string): string => {
  if (TIMEZONE_MAP[timeZone]) {
    return TIMEZONE_MAP[timeZone];
  }

  return timeZone.replace(/_/g, ' ').replace(/\//g, ' / ');
};

export const formatTimezoneShort = (timeZone: string): string => {
  if (SHORT_TIMEZONE_MAP[timeZone]) {
    return SHORT_TIMEZONE_MAP[timeZone];
  }

  return timeZone.replace(/_/g, ' ').replace(/\//g, ' / ');
};
