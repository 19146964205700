import React, { useCallback, useMemo, useState } from 'react';

import dropdownWhite from 'assets/icons/dropdown-white-up.svg';
import dropdownBlack from 'assets/icons/dropdown-black.svg';

import { NoHours, ProfileHoursDays, ProfileHoursHeader, ProfileHoursWrapper } from './ProfileHours.styles';
import { ProfileHoursType } from './ProfileHours.types';
import { timeParse } from 'app/utils/timeParse';

export const ProfileHours = ({ hours, colors }: ProfileHoursType) => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  if (!hours) {
    return <NoHours />;
  }

  const hoursToDisplay = useMemo(
    () => [
      {
        dayHours: hours.monday,
        label: 'Monday',
      },
      {
        dayHours: hours.tuesday,
        label: 'Tuesday',
      },
      {
        dayHours: hours.wednesday,
        label: 'Wednesday',
      },
      {
        dayHours: hours.thursday,
        label: 'Thursday',
      },
      {
        dayHours: hours.friday,
        label: 'Friday',
      },
      {
        dayHours: hours.saturday,
        label: 'Saturday',
      },
      {
        dayHours: hours.sunday,
        label: 'Sunday',
      },
    ],
    [hours],
  );

  return (
    <ProfileHoursWrapper className="profile__hours" show={isVisible} colors={colors}>
      <ProfileHoursHeader show={isVisible} onClick={toggleVisibility}>
        <h2>BUSINESS HOURS</h2>
        <img src={colors === 'light' ? dropdownWhite : dropdownBlack} alt="Close" className={colors} />
      </ProfileHoursHeader>
      <div className="toggle">
        {hoursToDisplay.map(({ dayHours, label }) => (
          <ProfileHoursDays key={label}>
            <div className={`hours__date ${!dayHours && 'hours__date--closed'}`}>
              <div className="hours__day">{label}</div>
              <div className="hours__time">
                {!dayHours ? `Closed` : `${timeParse(dayHours.open)} - ${timeParse(dayHours.close)}`}
              </div>
            </div>
          </ProfileHoursDays>
        ))}
      </div>
    </ProfileHoursWrapper>
  );
};
