import { IS_MOBILE, IS_IE } from "./consts";

const BIG_NUMBER = 999999999999;

export enum CalendarType {
  APPLE,
  GOOGLE,
  OUTLOOK,
  YAHOO
}

const getRandomKey = () => [Date.now(), Math.floor(Math.random() * BIG_NUMBER).toString()].join('_');

const formatTime = (date: string) => {
  const formattedDate = date
    .replace(/-/g, '')
    .replace(/\:/g, '')
    .split('.')[0];

  return `${formattedDate}Z`;
};

const buildGoogleUrl = (params: any) => {
  return [
    'https://calendar.google.com/calendar/render',
    '?action=TEMPLATE',
    `&dates=${params.startTime}`,
    `/${params.endTime}`,
    `&location=${params.encodedLocation}`,
    `&text=${params.encodedTitle}`,
    `&details=${params.encodedDescription}`,
  ].join('\n');
};

const buildAppleUrl = (params: any) => {
  return [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    `URL:${document.URL}`,
    `DTSTART:${params.startTime}`,
    `DTEND:${params.endTime}`,
    `SUMMARY:${params.title}`,
    `DESCRIPTION:${params.description}`,
    `LOCATION:${params.location}`,
    'END:VEVENT',
    'END:VCALENDAR',
  ].join('\n');
};

const buildYahooUrl = (params: any) => {
  return [
    'https://calendar.yahoo.com/?v=60&view=d&type=20',
    `&title=${params.encodedTitle}`,
    `&st=${params.startTime}`,
    `&dur=${params.duration}`,
    `&desc=${params.encodedDescription}`,
    `&in_loc=${params.encodedLocation}`,
  ].join('\n');
};

const buildOutlookUrl = (params: any) => {
  return [
    'https://outlook.live.com/owa/?rru=addevent',
    `&startdt=${params.startTime}`,
    `&enddt=${params.endTime}`,
    `&subject=${params.encodedTitle}`,
    `&location=${params.encodedLocation}`,
    `&body=${params.encodedDescription}`,
    '&allday=false',
    `&uid=${getRandomKey()}`,
    `&path=/calendar/view/Month`,
  ].join('\n');
};

export const buildUrl = (params: any, type: number) => {
  let result = '';

  const description = params.description || '';
  const location = params.location || '';
  const title = params.title || '';
  const modifiedParams = {
    ...params,
    description,
    encodedDescription: encodeURIComponent(description),
    encodedLocation: encodeURIComponent(location),
    encodedTitle: encodeURIComponent(title),
    endTime: formatTime(params.endTime),
    location,
    startTime: formatTime(params.startTime),
    title,
  };

  switch (type) {
    case CalendarType.APPLE:
      result = buildAppleUrl(modifiedParams);

      if (IS_MOBILE) {
        result = encodeURI(`data:text/calendar;charset=utf8,${result}`);
      }

      break;

    case CalendarType.GOOGLE:
      result = buildGoogleUrl(modifiedParams);
      break;

    case CalendarType.YAHOO:
      result = buildYahooUrl(modifiedParams);
      break;

    case CalendarType.OUTLOOK:
      result = buildOutlookUrl(modifiedParams);
      break;
  }

  return result;
};

export const downloadBlob = (url: string | null) => {
  if (!url) {
    return null;
  }

  if (!IS_MOBILE && (url.startsWith('data') || url.startsWith('BEGIN'))) {
    const filename = 'download.ics';
    const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });

    if (IS_IE) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } else {
    window.open(url, '_blank');
  }
};
