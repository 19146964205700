import React, { useEffect, useState, Fragment } from 'react';
import { Loader } from 'app/components/Loader';
import { useAppointmentRepository } from 'app/repository/AppointmentRepository';
import { useStylistRepository } from 'app/repository/StylistRepository';
import { Layout } from 'app/components/layout/Layout';
import { StylistProfile } from 'app/components/profile/StylistProfile';
import { Appointment } from 'app/components/Appointment';
import { Column, Row } from 'app/styles/MainLayoutStyles';
import { RouteComponentProps } from 'react-router';
import { catchError } from 'app/utils/analytics';

export type AppointmentDetailProps = {
  match: {
    params: {
      appointmentId: string;
      step: string;
      stylist: string;
    };
  };
} & RouteComponentProps;

export const AppointmentDetail = ({ match }: AppointmentDetailProps) => {

    const appointmentRepo = useAppointmentRepository()
    const stylistRepo = useStylistRepository()

    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState<any>()
    const [appointment, setAppointment] = useState<any>()
    const [stylist, setStylist] = useState<any>()

    async function getData() {
      try {

        // Grab the appointment from the param
        const appointmentId = match.params.appointmentId

        // Get the appointment id
        if (!appointmentId) {
          setError(catchError('appointment_detail_id', 'No appointment found'))
          return
        }

        // Fetch the appointment
        const appointmentRes = await appointmentRepo.getAppointment(appointmentId)
        setAppointment(appointmentRes.appointment)

        // Grab the stylist
        const username = appointmentRes.appointment.stylist.username
        const stylistRes = await stylistRepo.getStylist(username)
        setStylist(stylistRes)

      } catch (error) {
        setError(catchError('appointment_detail_id', error.message))
      }
      setIsLoading(false)
    }

    // Called when page loads
    useEffect(() => {
      getData()
    }, [])
  
    return (
      <Fragment>
        {isLoading ? (
          <Loader />
        ) : (
          <Layout>
            <Row>
              <Column id="stylist-profile">
                <StylistProfile
                  stylist={stylist}
                />
              </Column>
              <Column id="content-column">
                <Appointment
                  appt={appointment}
                />
              </Column>
            </Row>
          </Layout>
        )}
      </Fragment>
    )
  }