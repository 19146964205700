import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { BookingContext } from 'app/context/BookingContext';
import { Button } from 'app/components/button/Button';
import { useUserRepository } from 'app/repository/UserRepository';
import firebase from 'firebase/app';
import { UserContext } from 'app/context/UserContext';
import { UserSelectionForm } from 'app/components/UserSelectionForm';
import { UserSignUpForm } from 'app/components/UserSignUpForm';
import { useStylistRepository } from 'app/repository/StylistRepository';
import { ErrorView } from 'app/components/ErrorView';
import { LoadingView } from 'app/components/LoadingView';
import { Wrapper } from 'app/styles/BookingWrapperStyles';
import { catchError } from 'app/utils/analytics';

export type UserDetailsContainerProps = {
  onUserSelected: (profile: any) => void
}

export const UserDetailsContainer = ({ 
  onUserSelected
}: UserDetailsContainerProps) => {

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>()

  const bookingContext = useContext(BookingContext)
  const userContext = useContext(UserContext)

  const userRepo = useUserRepository()
  const stylistRepo = useStylistRepository()
  const username = stylistRepo.getStylistUsername()

  // Redirect
  if (!bookingContext.services) {
    return <Redirect to={`/${username}/booking/1/`} />
  } else if (!bookingContext.date) {
    return <Redirect to={`/${username}/booking/2/`} />
  } else if (!firebase.auth().currentUser) {
    return <Redirect to={`/${username}/booking/3/`} />
  }

  async function signUp(values: any) {
    try {

      setIsLoading(true)

      // Create the user
      const res = await userRepo.postSignup(
        values.emailAddress, 
        values.givenName, 
        values.familyName
      )

      // Select the user
      const profiles = res.profiles
      userContext.setProfiles(profiles)
      userContext.setCurrentProfile(profiles[0])
      userContext.setCurrentPhoneNumber(null)
      userRepo.setCurrentClientId(null)
      onUserSelected(profiles[0])

    } catch (error) {
      
      // Handle the sign up fetch error
      let err = error
      if (err.message === 'Failed to fetch') {
        err = {
          message: 'Error signing up with that phone number. Please sign out and try a new number'
        }
      }

      setError(catchError('sign_up', error.message))
      setIsLoading(false)
    }
  }

  function signOut() {
    userRepo.showLogOutConfirmation(async () => {
      try {
        await userRepo.logout()
      } catch (error) {
        setError(catchError('sign_out_user_details', error.message))
      }
    })
  }

  const getContent = () => {
    if (userContext.isLoading || isLoading) {
      return <LoadingView />
    } else if (!userContext.currentProfile) {
      return (
        <UserSignUpForm
          onUserSignUpSubmit={(values) => {
            signUp(values)
          }} />
      )
    } else {
      return (
        <>
          <UserSelectionForm 
            showSignOutButton={false}
            onUserSelected={(profile) => {
              bookingContext.setProfile(profile)
            }} 
            onClickSignOut={() => {
              signOut()
            }} />
          <Button 
            style={{
              marginBottom: '24px',
              width: '100%'
            }}
            onClick={() => {
              onUserSelected(bookingContext.profile)
            }}>
            Next
          </Button>
        </>
      )
    }
  }

  return (
    <>
      <Wrapper>
        {error && <ErrorView message={error.message} />}
        {getContent()}
      </Wrapper>
    </>
  )
}
