import { from } from 'app/theme';
import { BreakpointEntries, Theme } from 'app/theme/Theme.types';

const compareFn = (a: [any, number], b: [any, number]) => {
  if (a[1] === b[1]) return 0;

  return a[1] < b[1] ? -1 : 1;
};

export const getValuesWithMediaQueries = <T>(theme: Theme, values: T[]) => {
  const entries = (Object.entries(theme.variables.breakpoints) as BreakpointEntries).sort(compareFn);
  const mediaQueries = [null, ...entries.map(entry => entry[0])].map(key => from(key)({ theme }));

  return values.map<[string, T]>((value, index) => [mediaQueries[index], value]);
};
