import React from 'react';
import { Column } from 'app/theme/grid';

import { LayoutWrapper } from './Layout.styles';
import { LayoutProps } from './Layout.types';

export const Layout = ({ children }: LayoutProps) => (
  <LayoutWrapper className="layout" id="layout">
    <Column className="layout__content" style={{ flex: '1 0 auto' }}>
      {children}
    </Column>
  </LayoutWrapper>
);
