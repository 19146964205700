import moment from 'moment'
import { utcToZonedTime, format } from 'date-fns-tz'
import { setHours, setMinutes } from 'date-fns'
import React, {
    createContext,
    FunctionComponent,
    useContext,
    useState,
} from 'react'
import { StylistContext } from './StylistContext'
import firebase from 'firebase/app'

export interface BookingContextType {
    services: any[],
    setServices: (services: any[]) => void,
    date: any,
    setDate: (date: any) => void,
    profile: any,
    setProfile: (profile: any) => void,
    getBookingPayload: (card: any) => any,
    clear: () => void
}

export const BookingContext = createContext<BookingContextType>({
    services: [],
    setServices: () => {},
    date: null,
    setDate: () => {},
    profile: null,
    setProfile: () => {},
    getBookingPayload: () => {},
    clear: () => {}
})

export const BookingProvider: FunctionComponent = ({ children }) => {

    // State
    const [services, setServices] = useState<any[]>([])
    const [date, setDate] = useState<any>(null)
    const [profile, setProfile] = useState<any>(null)
    const stylistContext = useContext(StylistContext)

    // Returns a safe payload for the server
    const getBookingPayload = (card: any) => {

        // Get date
        const time = date.hour.split(':')
        const startingDate = moment(date.startingAt).toDate()
        const timeZone = stylistContext.stylist.profile.timeZone || ''
        const timezonedDate = utcToZonedTime(startingDate, timeZone)
        const parsedTime = setMinutes(setHours(timezonedDate, parseInt(time[0], 10)), parseInt(time[1], 10))
        const timestamp = format(parsedTime, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone }).replace(' ', 'T')

        // Grab email address
        let emailAddress = null
        if (profile.emailAddresses.length > 0) {
            emailAddress = profile.emailAddresses[0]
        }

        // Grab phone number
        let phoneNumber = null
        const currentUser = firebase.auth().currentUser
        if (currentUser) {
            phoneNumber = currentUser.phoneNumber
        }

        // Get the proper payment values
        let bookedPaymentSourceToken = null
        let savedCardId = null
        if (card) {
            const isLocalCard = card.id.includes('localcard_')
            bookedPaymentSourceToken = isLocalCard ? card.stripePaymentSourceId : null
            savedCardId = isLocalCard ? null : card.id
        }

        // Create payload
        return {
            contact: {
                givenName: profile.givenName || '',
                familyName: profile.familyName || '',
                emailAddress: emailAddress,
                phoneNumber: phoneNumber
            },
            startingAt: timestamp,
            services: services,
            clientId: profile.id,
            bookedPaymentSourceToken: bookedPaymentSourceToken,
            savedCardId: savedCardId
        }

    }

    function clear() {
        setServices([])
        // setDate(null)
        // setProfile(null)
    }

    return (
        <BookingContext.Provider value={{ 
            services, setServices,
            date, setDate,
            profile, setProfile,
            getBookingPayload,
            clear
        }}>
            {children}
        </BookingContext.Provider>
    )

}