import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Redirect, Route, Switch, useHistory } from 'react-router';

import { Button } from 'app/components/button/Button';

import { Tab, Tabs, Wrapper } from '../styles/BookingWrapperStyles';
import { List } from './List';
import { useStylistRepository } from 'app/repository/StylistRepository';
import { useUserRepository } from 'app/repository/UserRepository';
import { UserContext } from 'app/context/UserContext';
import { UserSelectionForm } from 'app/components/UserSelectionForm';
import { BookingContext } from 'app/context/BookingContext';
import { CardsList } from './CardsList';
import { LoadingView } from 'app/components/LoadingView';
import { ErrorView } from 'app/components/ErrorView';
import firebase from 'firebase/app';
import { APPOINTMENT_TYPES } from 'app/utils/consts';
import { ROUTES } from 'app/utils/routes';
import { catchError } from 'app/utils/analytics';

export const UserProfileContainer = () => {

  const userRepo = useUserRepository()
  const stylistRepo = useStylistRepository()
  const username = stylistRepo.getStylistUsername()

  const userContext = useContext(UserContext)
  const bookingContext = useContext(BookingContext)

  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingCards, setIsLoadingCards] = useState(false)
  const [error, setError] = useState<any>()
  const [appointments, setAppointments] = useState([])
  const { pathname } = useLocation()
  const history = useHistory()

  function signOut() {
    userRepo.showLogOutConfirmation(async () => {
      try {
        await userRepo.logout()
      } catch (error) {
        setError(catchError('sign_out_user_profile', error.message))
      }
    })
  }

  if (!firebase.auth().currentUser) {
    return <Redirect to={ROUTES.SIGNIN.replace(':stylist', username)} />
  }

  // If there is no user profile
  // Tell use what is up and give
  // them the option to sign out
  if (!userContext.currentProfile) {
    return (
    <Wrapper>
      <form>
        <Button 
          style={{
            width: '100%',
            marginBottom: '24px',
            marginTop: '24px'
          }}
          type="button"
          onClick={() => {
            history.replace(ROUTES.BOOKING.replace(':stylist', username).replace(':step', '1'))
          }}>
            Book appointment
        </Button>
        <Button 
          style={{
            width: '100%'
          }}
          outlined={true}
          type="button"
          onClick={() => {
            signOut()
          }}>
            Sign Out
        </Button>
      </form>
    </Wrapper>
    )
  }

  const TABS = [
    {
      label: 'Upcoming',
      path: ROUTES.USER_APPOINTMENTS_UPCOMING.replace(':stylist', username),
    },
    {
      label: 'Past',
      path: ROUTES.USER_APPOINTMENTS_PAST.replace(':stylist', username),
    },
    {
      label: 'Cards',
      path: ROUTES.USER_CARDS.replace(':stylist', username),
    }
  ]

  // Show the proper appointments when the user changes
  useEffect(() => {

    // User profile values
    const profile = userContext.currentProfile
    if (!profile) return
    const clientId = profile.id

    // Grab the appointments for the user
    setIsLoading(true)
    setError(null)
    userRepo.getFullProfile(clientId)
      .then(res => {
        setAppointments(res.appointments)
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        setError(catchError('user_profile_get_full_profile', error.message))
      })
  }, [userContext.currentProfile])

  async function deleteSavedCard(card: any) {
    if (window.confirm('Are you sure you want to delete this card?')) {
      setIsLoadingCards(true)
      try {
        const clientId = userContext.currentProfile.id
        await userRepo.deleteSavedCard(clientId, card.id)
        removeCardFromUserProfile(card)
      } catch (error) {
        setError(catchError('user_profile_delete_card', error.message))
      }
      setIsLoadingCards(false)
    }
  }

  function removeCardFromUserProfile(deletedCard: any) {

    // Update the current card
    userContext.profiles.map(profile => {
      if (profile.id === userContext.currentProfile.id) {

        // Create card list if missing
        // This can happen if the user
        // was new and had no cards
        if (!profile.savedCards) {
          profile.savedCards = []
        }

        // Remove the card
        profile.savedCards = profile.savedCards.filter((card: any) => {
          return card.id !== deletedCard.id
        })

        if (profile.lastUsedSavedCard === deletedCard.id) {
          profile.lastUsedSavedCard = null
        }

        // Update the user
        userContext.setCurrentProfile(profile)
        
      }
      return profile

    })
  }

  const upcomingAppointments = () => {
    return appointments.filter((appointment: any) => {
      return !appointment.finalizedAt && appointment.state !== 'canceled'
    })
  }

  const pastAppointments = () => {
    return appointments.filter((appointment: any) => {
      return appointment.finalizedAt || appointment.state === 'canceled'
    })
  }

  const savedCards = () => {
    const profile = userContext.currentProfile
    if (!profile || !profile.savedCards) {
      return []
    } else {
      return profile.savedCards
    }
  }

  return (
    <Wrapper>

      <UserSelectionForm 
        onUserSelected={(profile) => {
          bookingContext.setProfile(profile)
        }} 
        onClickSignOut={() => {
          signOut()
        }} />

      <Tabs>
        {TABS.map(({ label, path }) => {
          const isActive = path === pathname
          return (
            <Tab isActive={isActive} key={path}>
              <Link to={path}>{label}</Link>
            </Tab>
          )
        })}
      </Tabs>

      {error && <ErrorView message={error.message} />}

      <Switch>
        <Route path={ROUTES.USER_APPOINTMENTS_UPCOMING}>
          {isLoading ? (
            <LoadingView />
          ) : (
            <>
              <List
                list={upcomingAppointments()}
                type={APPOINTMENT_TYPES.UPCOMING}
              />
              <Button 
                style={{
                  width: '100%'
                }}
                type="button"
                onClick={() => {
                  history.replace(ROUTES.BOOKING.replace(':stylist', username).replace(':step', '1'))
                }}>
                  Book new appointment
              </Button>
            </>
          )}
        </Route>
        <Route path={ROUTES.USER_APPOINTMENTS_PAST}>
          {isLoading ? (
            <LoadingView />
          ) : (
            <>
              <List
                list={pastAppointments()}
                type={APPOINTMENT_TYPES.PAST}
              />
              <Button 
                style={{
                  width: '100%'
                }}
                type="button">
                <Link to={ROUTES.BOOKING.replace(':stylist', username).replace(':step', '1')}>
                  Book new appointment
                </Link>
              </Button>
            </>
          )}
        </Route>
        <Route path={ROUTES.USER_CARDS}>
          {isLoadingCards ? (
            <LoadingView />
          ) : (
            <CardsList 
              cards={savedCards()}
              canDelete={true}
              onClickDelete={card => {
                deleteSavedCard(card)
              }} />
          )}
        </Route>
      </Switch>

    </Wrapper>
  );
};
