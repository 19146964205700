import styled, { css, to } from 'app/theme'
import React from 'react'
import { useHistory } from 'react-router';

export const HeaderViewStyles = styled.div`
  ${({ theme: { variables } }) => css`
    align-items: center;
    background: ${variables.colors.white};
    border-bottom: 1px solid ${variables.colors.neutral1};
    border-top: 1px solid ${variables.colors.neutral1};
    box-sizing: border-box;
    display: flex;
    height: 90px;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
    position: relative;
    width: 475px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    line-height: 1.5;

    .back {
      height: 56px;
      width: 56px;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      cursor: pointer;
      background-image: url(${variables.icons.close});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px 24px;

      :hover {
        opacity: 0.5;
      }
    }

    ${to('large')} {
      height: 75px;
      width: calc(100% - 48px);
    }
  `}
`;

export type HeaderViewProps = {
  title: string,
  showBackButton?: boolean
}

export const HeaderView = ({ title, showBackButton }: HeaderViewProps) => {

  const history = useHistory()

  function goBack(e: any) {
    e.preventDefault()
    history.goBack()
  }

  return (
    <HeaderViewStyles>
      {showBackButton && <div className="back" onClick={(e) => {
        goBack(e)
      }}/>}
      <span>{title}</span>
    </HeaderViewStyles>
  )
}
