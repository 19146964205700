export const MENU_ITEMS = [
  {
    className: '',
    id: 'home',
    label: 'Home',
    link: '/:stylistId',
  },
  {
    className: '',
    id: 'privacy',
    label: 'Privacy Policy',
    link: 'https://tanto.app/privacy-policy',
    rel: 'noopener noreferrer',
    target: '_blank',
  },
];

export const SIGNED_USER_MENU_ITEMS = [
  {
    className: '',
    id: 'my-appointments',
    label: 'My Appointments',
    link: '/:stylistId/user/appointments/upcoming',
  },
  {
    className: '',
    id: 'my-profile',
    label: 'My Profile',
    link: '/:stylistId/user/profile',
  },
  {
    className: '',
    id: 'logout',
    label: 'Log Out',
    link: '/:stylistId/user/logout',
  },
  {
    className: 'primary-color',
    id: 'book-new-appointment',
    label: 'Book New Appointment',
    link: '/:stylistId',
  },
];
