const zeroPad = (value: number) => `${value < 10 ? '0' : ''}${value}`;

export const prepareDuration = (services: any[]) => {
  const servicesDuration = services.reduce<number>((sum, item) => sum + item.duration, 0);

  const hours = Math.floor(servicesDuration / 60);
  const minutes = zeroPad(servicesDuration - hours * 60);

  return `${hours}:${minutes}`;
};
