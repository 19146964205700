import firebase from 'firebase/app';
import 'firebase/auth';

import { FIREBASE_CONFIG } from './firebaseConfig';

if (!FIREBASE_CONFIG.apiKey) {
  throw new Error('REACT_APP_FIREBASE_API_KEY is not set in your .env file');
}

if (!FIREBASE_CONFIG.projectId) {
  throw new Error('REACT_APP_FIREBASE_PROJECT_ID is not set in your .env file');
}

if (!FIREBASE_CONFIG.appId) {
  throw new Error('FIREBASE_APP_ID is not set in your .env file');
}

if (!FIREBASE_CONFIG.measurementId) {
  throw new Error('GA_MEASUREMENT_ID is not set in your .env file');
}

const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG);

firebaseApp.auth().languageCode = 'en';
