import { ProfileBusinessLocation } from 'app/components/profile/Profile.types';

export const prepareLocationURL = (location: ProfileBusinessLocation) => {
  const address1 = location.address1 || '';
  const address2 = location.address2 ? ` ${location.address2}` : '';
  const city = location.city ? ` ${location.city}` : '';
  const state = location.state ? ` ${location.state}` : '';
  const zip = location.zip ? ` ${location.zip}` : '';

  return `https://maps.google.com/?q=${address1}${address2}${city}${state}${zip}`;
};
