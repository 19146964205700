import React, { useContext } from 'react';
import { Route, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';

import { StylistProfile } from 'app/components/profile/StylistProfile';
import { Layout } from 'app/components/layout/Layout';

import { UserProfileContainer } from './UserProfileContainer';
import { AddUser } from './AddUser';
import { AddCard } from 'app/components/AddCard';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { StylistContext } from 'app/context/StylistContext';
import { useStylistRepository } from 'app/repository/StylistRepository';
import { UserContext } from 'app/context/UserContext';
import { SignInPhoneContainer } from 'app/components/SignInPhoneContainer';
import { SignInCodeContainer } from 'app/components/SignInCodeContainer';
import { LoadingView } from 'app/components/LoadingView';
import { HeaderView } from 'app/components/HeaderView';
import { Column, Row } from 'app/styles/MainLayoutStyles';
import { ROUTES } from 'app/utils/routes';

export const UserContainer = () => {

  const stylistRepo = useStylistRepository()
  const username = stylistRepo.getStylistUsername()
  const stylistContext = useContext(StylistContext)
  const history = useHistory()
  const userContext = useContext(UserContext)

  function getContent() {

    if (userContext.isLoading) {
      return (
        <Column id="content-column" style={{
          paddingTop: '24px'
        }}>
          <LoadingView />
        </Column>
      )
    }

    return (
      <Column id="content-column">
        <Switch>
          <Route path={ROUTES.SIGNIN}>
            <HeaderView title={'Enter your contact info'} />
            <SignInPhoneContainer 
              isUserBooking={false}
              onPhoneNumberSubmit={() => {
                history.push(ROUTES.SIGNIN_CODE.replace(':stylist', username))
              }}
              onUserSignedIn={() => {
                history.replace(ROUTES.USER_APPOINTMENTS_UPCOMING.replace(':stylist', username))
              }} />
          </Route>
          <Route path={ROUTES.SIGNIN_CODE}>
            <HeaderView title={'Enter sign in code'} />
            <SignInCodeContainer
              isUserBooking={false}
              onUserSignedIn={() => {
                history.replace(ROUTES.USER_APPOINTMENTS_UPCOMING.replace(':stylist', username))
              }}
              onUnknownUserSignIn={() => {
                history.replace(ROUTES.BOOKING.replace(':stylist', username).replace(':step', '1'))
              }} />
          </Route>
          <Route path={ROUTES.USER_ADD_USER}>
            <HeaderView title={'Add new user'} showBackButton={true} />
            <AddUser />
          </Route>
          <Route path={ROUTES.USER_ADD_CARD}>
            <HeaderView title={'Add new card'} showBackButton={true} />
            <StripeProvider apiKey={stylistContext.stylist.profile.cardSettings.apiKey}>
              <Elements>
                <AddCard stylist={stylistContext.stylist} stylistId={username} />
              </Elements>
            </StripeProvider>
          </Route>
          <Route path={ROUTES.USER_APPOINTMENTS_PAST}>
            <UserProfileContainer />
          </Route>
          <Route>
            <UserProfileContainer />
          </Route>
        </Switch>
      </Column>
    )
  }

  return (
    <Layout>
      <Row>
        <Column id="stylist-profile">
          <StylistProfile stylist={stylistContext.stylist} />
        </Column>
        {getContent()}
      </Row>
    </Layout>
  )
}
