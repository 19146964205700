import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Field, Form } from 'react-final-form';

import { FocusInput } from 'app/components/focusInput/FocusInput';

import { Button } from 'app/components/button/Button';
import { useUserRepository } from 'app/repository/UserRepository';
import { UserContext } from 'app/context/UserContext';
import { ErrorView } from 'app/components/ErrorView';
import { LoadingView } from 'app/components/LoadingView';
import { Wrapper } from '../styles/BookingWrapperStyles';
import { InputStyles } from 'app/styles/InputStyles';

export const AddUser = () => {

  const history = useHistory()
  const [error, setError] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)

  const userContext = useContext(UserContext)
  const userRepo = useUserRepository()

  async function saveUser(values: any) {

    setIsLoading(true)

    try {

      // Create the new user
      const res = await userRepo.postAddName(
        values.givenName, 
        values.familyName, 
        userContext.currentProfile.id
      )

      // Determine which user was changed
      const previousClientIds = userContext.profiles.map(profile => profile.id)
      const newProfile = res.profiles.filter((profile: any) => !previousClientIds.includes(profile.id))[0]

      // Update the current user with the result
      userContext.setProfiles(res.profiles)
      userContext.setCurrentProfile(newProfile)

      history.goBack()

    } catch (error) {
      setIsLoading(false)
      setError(error)
    }
  }

  return (
    <Wrapper>
      {error && <ErrorView message={error.message}/>}
      {isLoading ? <LoadingView /> : (
        <InputStyles>
          <Form
            onSubmit={saveUser}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    type="text"
                    component="input"
                    name="givenName"
                    required={true}
                  >
                    {({ input, meta: { error, touched }, ...rest }) => (
                      <FocusInput
                        className="field-validation"
                        inputClassName={`${error && touched && `field-error`}`}
                        inputProps={{ ...input, ...rest }}
                        label="First Name"
                        wrapperClassName="field-wrapper"
                        autofocus={true}
                      />
                    )}
                  </Field>
                  <Field
                    type="text"
                    component="input"
                    name="familyName"
                    required={true}
                  >
                    {({ input, meta: { error, touched }, ...rest }) => (
                      <FocusInput
                        className="field-validation"
                        inputClassName={`${error && touched && `field-error`}`}
                        inputProps={{ ...input, ...rest }}
                        label="Last Name"
                        wrapperClassName="field-wrapper"
                        autofocus={false}
                      />
                    )}
                  </Field>
                  <Button 
                    style={{
                      width: '100%'
                    }}
                    type="submit">
                      Save
                  </Button>
                </form>
              )
            }}
          />
        </InputStyles>
      )}
    </Wrapper>
  )
}
