import firebase from "firebase/app"
import 'firebase/analytics';

export const catchError = (location: string, message: string) => {
    firebase.analytics().logEvent(location, {
        error: message
    })
    return {
        message: message
    }
}