import React, { CSSProperties, ReactNode } from 'react';
import styled, { css, to } from 'app/theme';

export const ButtonStyled = styled.button<{ disabled?: boolean, outlined?: boolean, isSticky?: boolean, isCancel?: boolean }>`
  ${({ disabled, isCancel, outlined, isSticky, theme: { variables } }) => css`

    background: ${outlined ? variables.colors.white : isCancel ? variables.colors.red : variables.colors.primary};
    color: ${outlined ? variables.colors.primary : variables.colors.white};
    cursor: ${disabled ? 'default' : 'pointer'};
    border: ${outlined ? `1px solid ${variables.colors.primary}` : 'none'} !important;
    outline: none;
    border: none;
    font-size: 13px;
    font-weight: ${outlined ? `500` : '600'};
    line-height: 1.2;
    text-align: center;
    height: 50px;

    &:disabled {
      border-color: ${variables.colors.gray};
      background: ${variables.colors.gray};
      color: ${variables.colors.white};
    }

    ${isSticky &&
      css`
        bottom: 0;
        margin: 0;
        position: fixed;
        right: 0;
        width: 50vw;

        ${to('large')} {
          width: 100%;
        }
      `}

    ${!disabled && (
      `&:hover {
        background: ${outlined ? variables.colors.neutral1 : isCancel ? variables.colors.redDark : variables.colors.primaryLight};
        color: ${outlined ? variables.colors.primary : variables.colors.white};
      }`
    )}

  `}
`;

export type ButtonProps = {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  outlined?: boolean;
  isSticky?: boolean;
  isCancel?: boolean;
  id?: string;
  style?: CSSProperties;
  type?: string;
  htmlType?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  forwardRef?: any;
}

export const Button = ({ children, ...props }: ButtonProps) => {
  return <ButtonStyled {...props}>{children}</ButtonStyled>;
}
