import React from 'react'
import styled, { css } from 'app/theme'
import iconClose from 'assets/icons/icon-close.svg'

export const CreditCardViewStyles = styled.div`
    ${({ theme: { variables } }) => css`
        background-color: ${variables.colors.white};
        position: relative;
        border-bottom: 1px solid ${variables.colors.neutral1};
        width: 100%;

        :hover {
            background-color: ${variables.colors.neutral1};
        }

        .disable-hovers {
            cursor: default;
        }

        label {
            display: flex;
            flex-direction: column;
            cursor: pointer;
            padding: ${variables.margins.marginNormal} ${variables.margins.marginBig} ${variables.margins.marginBig};
        }

        input {
            display: none;
        }

        h3 {
            color: ${variables.colors.black};
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 6px;
        }
    
        p {
            color: ${variables.colors.infoGray};
            font-size: 14px;
            font-weight: 600;
        }

        .check-container {
            width: 22px;
            height: 22px;
            right: 24px;
            top: 0;
            bottom: 0;
            margin: auto;
            position: absolute;
            border-radius: 50%;
            border: 1px solid ${variables.colors.blackTransparent};
        }

        .check {
            width: 22px;
            height: 22px;
            right: 24px;
            top: 0;
            bottom: 0;
            margin: auto;
            position: absolute;
            border-radius: 50%;
            border: 1px solid transparent;
            background-image: url(${variables.icons.select});
            background-repeat: no-repeat;
            background-position: center;
            background-color: ${variables.colors.primary};
        }

        .delete {
            width: 48px;
            height: 48px;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            position: absolute;
            background-image: url(${iconClose});
            background-repeat: no-repeat;
            background-size: 24px 24px;
            background-position: center;
            cursor: pointer;
        }

    `}
`;

export type CreditCardViewProps = {
    card: any,
    checked?: boolean,
    onChange?: (card: any) => void,
    onDelete?: (card: any) => void
}

export const CreditCardView = ({ 
    card, 
    checked,
    onChange,
    onDelete
}: CreditCardViewProps) => {

    return (
        <CreditCardViewStyles 
            className={onDelete ? "disable-hovers" : ""}>
            <label
                className={onDelete ? "disable-hovers" : ""}>
                <input 
                    type="radio"
                    name="selectedCard"
                    required={true}
                    value={card.id}
                    checked={checked || false}
                    onChange={() => {
                        if (onChange) {
                            onChange(card)
                        }
                    }} />
                    <h3>{`${card.brand} ${card.last4}`}</h3>
                    <p>{`Exp ${card.expMonth}/${card.expYear}`}</p>
                    {onDelete ? (
                        <div className="delete" onClick={() => {
                            onDelete(card)
                        }} />
                    ) : (
                        <>
                            <div className="check-container" />
                            {checked && <div className="check" />}
                        </>
                    )}
            </label>
        </CreditCardViewStyles>
    )

}