import styled, { to } from 'app/theme';

export const AvatarWrapper = styled.div`
  color: #fff;
  padding-bottom: 5px;

  ${to('large')} {
    padding: 50px 0 10px;
  }
`;

export const AvatarImage = styled.div<{ src: string }>`
  background-image: ${props => `url('${props.src}')`};
  background-size: cover;
  background-position: top center;
  border-radius: 50%;
  box-shadow: 0 15px 35px rgba(34, 36, 42, 0.3);
  height: 177px;
  margin: 0 auto;
  width: 177px;

  ${to('large')} {
    height: 100px;
    width: 100px;
  }
`;
