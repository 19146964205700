import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import { BookingContainer } from './components/BookingContainer'
import { UserContainer } from './components/UserContainer'
import { RedirectToBooking } from './utils/RedirectToBooking'
import { CookiesFallback } from './components/CookiesFallback'
import { NewUserProvider } from 'app/context/UserContext'
import { StylistProvider } from './context/StylistContext'
import { BookingProvider } from './context/BookingContext'
import { AppointmentDetail } from './components/AppointmentDetail'
import { RedirectToUser } from './utils/RedirectToUser'
import { ROUTES } from './utils/routes'

export const App = () => (
  navigator.cookieEnabled ? (
    <Router>
      <Fragment>
        <Switch>
          <StylistProvider>
              <NewUserProvider>
                <BookingProvider>
                  <Route path={ROUTES.APPOINTMENT} component={AppointmentDetail} exact={true} />
                  <Route path={ROUTES.STYLIST} component={RedirectToBooking} exact={true} />
                  <Route path={ROUTES.BOOKING} component={BookingContainer} exact={true} />
                  <Route path={ROUTES.USER_ADD_CARD} component={UserContainer} exact={true} />
                  <Route path={ROUTES.SIGNIN} component={UserContainer} exact={true} />
                  <Route path={ROUTES.SIGNIN_CODE} component={UserContainer} exact={true} />
                  <Route path={ROUTES.USER} component={RedirectToUser} exact={true} />
                  <Route path={ROUTES.USER_APPOINTMENTS_UPCOMING} component={UserContainer} />
                  <Route path={ROUTES.USER_APPOINTMENTS_PAST} component={UserContainer} />
                  <Route path={ROUTES.USER_CARDS} component={UserContainer} />
                  <Route path={ROUTES.USER_ADD_USER} component={UserContainer} />
                </BookingProvider>
              </NewUserProvider>
          </StylistProvider>
        </Switch>
      </Fragment>
    </Router>
  ) : (
    <CookiesFallback />
  )
)
