import React, { useContext, useMemo, useState } from 'react';
import { utcToZonedTime, format } from 'date-fns-tz';

import { formatTimezoneShort } from 'app/components/calendar/utils/formatTimezone';
import { phoneParse } from 'app/utils/phoneParse';

import { AddToCalendar } from './AddToCalendar';

import { prepareDescription } from '../utils/prepareDescription';
import { prepareLocation } from 'app/utils/prepareLocation';

import { CancellationPolicy } from 'app/components/CancellationPolicy';

import { StylistContext } from 'app/context/StylistContext';
import { Wrapper } from 'app/styles/BookingWrapperStyles';
import { HeaderView } from 'app/components/HeaderView';
import { ServiceItemView } from 'app/components/ServiceItemView';
import { Block, Header, Status } from 'app/styles/ListItemStyles';
import { HOUR_FORMAT } from 'app/components/calendar/consts';
import { prepareLocationArray } from 'app/utils/prepareLocationArray';
import { BookingContext } from 'app/context/BookingContext';
import { useHistory } from 'react-router';
import { useStylistRepository } from 'app/repository/StylistRepository';
import { Button } from 'app/components/button/Button';
import { ErrorView } from 'app/components/ErrorView';
import { LoadingView } from 'app/components/LoadingView';
import { useAppointmentRepository } from 'app/repository/AppointmentRepository';
import { prepareDuration } from 'app/utils/prepareDuration';
import { prepareTitle } from 'app/utils/prepareTitle';
import { prepareLocationURL } from 'app/utils/prepareLocationUrl';
import { catchError } from 'app/utils/analytics';

export type AppointmentProps = {
  appt: any;
}

export const Appointment = ({ appt }: AppointmentProps) => {

  const { stylist, services } = useContext(StylistContext)
  const bookingContext = useContext(BookingContext)
  const history = useHistory()

  const appointmentRepo = useAppointmentRepository()
  const stylistRepo = useStylistRepository()
  const username = stylistRepo.getStylistUsername()

  const [appointment, setAppointment] = useState(appt)
  const [error, setError] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)

  async function cancelAppointment() {
    if (window.confirm('Are you sure you want to cancel this appointment?')) {
      setIsLoading(true)
      try {
        const res = await appointmentRepo.cancelAppointment(appointment.id)
        setAppointment(res.appointment)
      } catch (error) {
        setError(catchError('cancel_appointment', error.message))
      }
      setIsLoading(false)
    }
  }

  const isInFuture = +new Date(appointment.startingAt) > +new Date()
  const canBeAddedToCalendar = ['accepted', 'confirmed'].includes(appointment.state)
  const canBeCancelled = isInFuture && appointment.state === 'accepted' && stylist.profile.cancellationSettings.onlineCancellations

  function scheduleAgain(serviceIds: string[]) {
    bookingContext.setServices(serviceIds)
    history.push(`/${username}/booking/1/`)
  }

  const title = () => {
    switch (appointment.state) {
      case 'finalized': return 'Your appointment is completed'
      case 'requested': return `Your booking request has been sent to ${stylist.profile.firstName}`
      case 'denied': return 'Your Appointment has been denied'
      case 'accepted': return 'Your Appointment has been booked'
      case 'confirmed': return 'Your Appointment is confirmed'
      case 'canceled': return 'Your Appointment has been canceled'
      case 'cancelling': return 'Your Appointment has been canceled'
      default: return 'Your Appointment'
    }
  }

  const appointmentServices = () => {
    const appointmentServiceIds = appointment.services.map((service: any) => service.id)
    return services.services.filter((service: any) => appointmentServiceIds.includes(service.id))
  }

  const pageAppointmentLocation = useMemo(() => {
    if (!stylist || !stylist.profile.location.address) {
      return null;
    }

    return prepareLocation(stylist.profile.location.address, ', ');
  }, [stylist])

  const calendarParameters = useMemo(() => {
    return {
      description: prepareDescription(stylist),
      duration: prepareDuration(appointment.services),
      endTime: `${appointment.endingAt}`,
      location: pageAppointmentLocation,
      startTime: `${appointment.startingAt}`,
      title: prepareTitle(appointmentServices(), stylist),
    };
  }, [appointment, appointmentServices(), stylist])

  const timeZone = stylist.profile.timeZone
  const formattedDate = format(new Date(appointment.startingAt), 'EEEE, LLLL do, yyyy')
  const startingHour = format(utcToZonedTime(new Date(appointment.startingAt), timeZone), HOUR_FORMAT)
  const endingHour = format(utcToZonedTime(new Date(appointment.endingAt), timeZone), HOUR_FORMAT)
  const formattedHour = `${startingHour} - ${endingHour}, ${formatTimezoneShort(timeZone)}`
  const location = stylist.profile.location.address
  const phone = stylist.profile.contact.phoneNumber || ''

  const locationURL = useMemo(() => {
    if (location) {
      return prepareLocationURL(location)
    }
    return ''
  }, [location])

  const locationArray = useMemo(() => {
    return location ? prepareLocationArray(location) : []
  }, [location])

  return (
    <>
      <HeaderView title={title()} />
      <Wrapper>
        <form>
          {error && <ErrorView message={error.message} />}
          {isLoading ? (
            <LoadingView />
          ) : (
            <>
              {appointmentServices().map((service: any) => (
                <ServiceItemView 
                  key={service.id}
                  service={service}
                  isSelectable={false} />
              ))}
              <h3 className="title">When</h3>
              <Header isFilled={false}>
                <h4>{formattedDate}</h4>
                <p>{formattedHour}</p>
                {appointment.state === 'canceled' && <Status isCanceled={true}>Canceled</Status>}
                {appointment.state === 'finalized' && <Status isPaid={true}>Paid</Status>}
              </Header>
              <h3 className="title">Where</h3>
              <Block>
                <a href={locationURL} rel="noopener noreferrer" title="Go to Google map" target="_blank" className="extended">
                  {locationArray.map((line: any, key: any) => (
                    <p key={key}>
                      {line}
                    </p>
                ))}
                </a>
              </Block>
              <h3 className="title">With</h3>
              <Block>
                {phone ? (
                  <>
                    <a href={`tel:${phone}`} className="extended">
                      <p className="name">{`${stylist.profile.firstName} ${stylist.profile.lastName}`}</p>
                      <p>{phoneParse(phone)}</p>
                    </a>
                  </>
                ) : (
                  <p className="title extended">{`${stylist.profile.firstName} ${stylist.profile.lastName}`}</p>
                )}
              </Block>
              <>
                {canBeAddedToCalendar && <AddToCalendar params={calendarParameters} />}
                {canBeCancelled && 
                <Button 
                  style={{
                      width: '100%',
                      marginTop: '24px',
                      marginBottom: '12px'
                    }} 
                  isCancel={true} 
                  onClick={() => cancelAppointment()}>
                    Cancel Appointment
                </Button>}
                {canBeCancelled && (
                  <CancellationPolicy
                    canCancelOnline={stylist.profile.cancellationSettings.onlineCancellations}
                    days={appointment.cancellationFeeMinHours / 24}
                    percentage={appointment.cancellationFeePercent * 100}
                  />
                )}
    
                {appointment.state !== 'cancelling' && (
                  <Button
                    style={{
                      width: '100%',
                      marginTop: '24px'
                    }}
                    type="button"
                    onClick={() => {
                      const ids = appointment.services.map((service: any) => service.id)
                      scheduleAgain(ids)
                    }}>
                      Book another
                  </Button>
                )}
              </>
            </>
          )}
        </form>
      </Wrapper>
    </>
  )
}
