import React, { CSSProperties } from 'react'
import styled, { css } from 'app/theme'

export const CheckBoxStyles = styled.div`
    ${({ theme: { variables } }) => css`

        width: 24px;
        height: 24px;

        .check-container {
            position: absolute;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            border: 1px solid ${variables.colors.blackTransparent};
        }

        .check {
            position: absolute;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            border: 1px solid transparent;
            background-image: url(${variables.icons.select});
            background-repeat: no-repeat;
            background-position: center;
            background-color: ${variables.colors.primary};
        }

    `}
`;

export type CheckBoxProps = {
    checked: boolean,
    style?: CSSProperties
}

export const CheckBox = ({
    checked,
    style
}: CheckBoxProps) => {

    return (
        <CheckBoxStyles style={style}>
            <div className="check-container" />
            {checked && <div className="check" />}
        </CheckBoxStyles>
    )

}