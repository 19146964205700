import styled, { css, from, landscape, to } from 'app/theme';
import { Box } from 'app/theme/grid';
import { NavbarWrapper } from 'app/components/profile/navbar/Navbar.styles';

export const ProfileWrapper = styled(Box)<{ bgImage: string }>`
  ${({ bgImage, theme: { variables } }) => css`
    align-items: center;
    background: ${bgImage
      ? `linear-gradient(-135deg, rgba(34, 36, 42, 0.75) 0%, rgba(34, 36, 42, 0.75) 100%), url('${bgImage}') no-repeat`
      : `#F5F6FA`};
    background-position: center;
    background-size: cover;
    color: ${bgImage ? variables.colors.white : variables.colors.black};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    ${to('large')} {
      background-position: center ${variables.margins.marginSuperBig};
      background-size: auto 100vh;
      justify-content: flex-start;
      min-height: auto;
    }

    ${landscape()} {
      background-size: cover;
    }

    ${to('medium')} {
      min-height: auto;
      width: auto;
      position: static;

      .hidden {
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
      }
    }

    ${from('large')} {
      ${NavbarWrapper} {
        position: static;
        margin: 20px auto;
        width: calc(100% - 40px);
      }

      &:after {
        content: '';
        display: block;
        height: 0;
        margin: 20px auto;
        padding-top: 50px;
        width: 100%;
      }
    }
  `}
`;

export const ProfileContent = styled.div`
  ${({ theme: { variables } }) => css`
    box-sizing: border-box;
    padding: 0 ${variables.margins.marginBig};
    width: 100%;

    ${to('large')} {
      margin-top: ${variables.margins.marginSuperBig};
      padding-bottom: ${variables.margins.marginBig};
    }
  `}
`;
