import { ProfileBusinessLocation } from 'app/components/profile/Profile.types';

export const prepareLocationArray = (address: ProfileBusinessLocation): string[] => {
  const { address1, address2, city, state, zip } = address;

  if (!address1 && !address2 && !city && !state && !zip) {
    return [];
  }

  const line1 = [];
  const line2 = [];

  if (address1 || address2) {
    line1.push(`${address1 || ''}${address2 ? `${address1 ? ' ' : ''}${address2}` : ''}`);
  }

  if (city) {
    line2.push(city);
  }

  if (state && city) {
    line2[line2.length - 1] = `${line2[line2.length - 1]}, ${state}`;
  }

  if (state && !city) {
    line2.push(state);
  }

  if (zip) {
    line2.push(zip);
  }

  return [line1.join(' '), line2.join(' ')];
};
