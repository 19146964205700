import styled, { to, landscape } from 'app/theme';

export const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(25, 26, 28, 0.7);

    ${to('xsmall')} {
      cursor: pointer;
    }
  }

  .modal-wrapper {
    position: fixed;
    top: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    z-index: 100;
    background: white;
    position: relative;
    border-radius: 3px;
    min-width: auto;

    ${landscape()} {
      max-height: 90vh;
      overflow-y: auto;
      position: relative;
    }

    ${to('xsmall')} {
      min-width: calc(100% - 10vw);
    }
  }

  .modal-content {
    pointer-events: auto;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    h3 {
      color: ${props => props.theme.variables.colors.black};
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
    }

    ${to('xsmall')} {
      margin-right: 0;
    }
  }

  .modal-close-button {
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 700;
    height: 24px;
    line-height: 1;
    border: none;
    outline: 0 !important;
    width: 24px;
    z-index: 200;
  }

  .modal-close-button:hover {
    opacity: 0.8;
    text-decoration: none;
  }

  .modal-body {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    border-radius: 0.3rem;
  }

  .modal-body-style {
    padding: 0.5rem 1.5rem;
  }

  .modal-footer {
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: flex-end;
  }

  .modal-open {
    overflow: hidden;
  }

  .modal-size-regular {
    max-width: 500px;
  }

  .modal-size-large {
    max-width: 800px;
  }

  .modal-button {
    font-size: 0.9rem;
    font-weight: 700;
    border: none;
    border-radius: 3px;
    padding: 0.3rem 1rem;
    margin-left: 0.5rem;
  }

  .modal-button-cancel {
    background: #fff;
    color: #000;
  }

  .modal-button-destructive {
    background: #ff1654;
    color: #fff;
  }

  .modal-button-default {
    background: #247ba0;
    color: #fff;
  }

  .modal-animated {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .modal-animation-fade-in {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 30%, 0);
      transform: translate3d(0, 30%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 30%, 0);
      transform: translate3d(0, 30%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
`;
