import { ProfileStylist } from 'app/components/profile/Profile.types';
import { phoneParse } from 'app/utils/phoneParse';

export const prepareDescription = (stylist?: ProfileStylist) => {
  if (!stylist || !stylist.profile) {
    return '';
  }

  const {
    profile: { contact },
  } = stylist;

  const urlAddress = window.location.href;
  const phoneNumber = contact && contact.phoneNumber ? phoneParse(contact.phoneNumber) : '';
  const emailAddress = (contact && contact.emailAddress) || '';

  if (!phoneNumber && !emailAddress) {
    return urlAddress;
  }

  let contactForm = '';

  if (phoneNumber && !emailAddress) {
    contactForm = `Call: ${phoneNumber}`;
  } else if (!phoneNumber && emailAddress) {
    contactForm = `Email at: ${emailAddress}`;
  } else {
    contactForm = `Call or email at: ${phoneNumber}, ${emailAddress}`;
  }

  const haveQuestions = `Have questions? ${contactForm}`;

  return `${urlAddress}\n${haveQuestions}`;
};
