import React, { useEffect } from 'react';
import { Field } from 'react-final-form';

import { CalendarWrapper } from './Calendar.styles';
import { DatesProps } from './Calendar.types';
import { DateSelect } from './dateSelect/DateSelect';
import { TimeSelect } from './timeSelect/TimeSelect';

export const Calendar = ({
  closeDays,
  dates,
  duration,
  mutators,
  onTimeSelect,
  selectedDay,
  setIsLoading,
  timeZone,
}: DatesProps) => {

  useEffect(() => mutators.setHourToNull(), [])

  return (
    <CalendarWrapper>
      <Field name="startingAt" initialValue={new Date()}>
        {({ input, meta }) => (
          <DateSelect
            input={input}
            meta={meta}
            dates={dates}
            closeDays={closeDays}
            mutators={mutators}
            timeZone={timeZone}
          />
        )}
      </Field>
      <TimeSelect
        dates={dates}
        duration={duration}
        onTimeSelect={onTimeSelect}
        selectedDay={selectedDay}
        setIsLoading={setIsLoading}
        timeZone={timeZone}
      />
    </CalendarWrapper>
  );
};
