import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from 'app/utils/routes';

import { getUserInitials } from './utils';
import { Menu } from '../menu/Menu';
import { LoadingUserInitials, NavbarWrapper, SignInLink, StylistName, UserInitials } from './Navbar.styles';
import firebase from 'firebase/app';
import { UserContext } from 'app/context/UserContext';
import { StylistContext } from 'app/context/StylistContext';

export type NavbarProps = {
  location: any;
  name: string;
  phoneNumber: string;
}

export const Navbar = ({ name, phoneNumber }: NavbarProps) => {

  const stylistContext = useContext(StylistContext)
  const username = stylistContext.stylist.profile.username
  const signInPath = `${ROUTES.SIGNIN.replace(':stylist', username)}`
  const userContext = useContext(UserContext)

  const getUserInitialView = () => {
    if (userContext.isLoading) {
      return (
        <LoadingUserInitials>
          <div className="loader" />
        </LoadingUserInitials>
      )
    } else if (firebase.auth().currentUser && !userContext.currentProfile) {
      return (
        <UserInitials isMissing={true}>
          <Link to={`${ROUTES.USER_APPOINTMENTS_UPCOMING.replace(':stylist', username)}`}>
            <span>?</span>
          </Link>
        </UserInitials>
      )
    } else if (!firebase.auth().currentUser && !userContext.currentProfile) {
      return (
        <SignInLink>
          <Link to={signInPath}>Sign in</Link>
        </SignInLink>
      )
    } else {
      return (
        <UserInitials isMissing={false}>
          <Link to={`${ROUTES.USER_APPOINTMENTS_UPCOMING.replace(':stylist', username)}`}>
            <span>{getUserInitials(userContext.currentProfile)}</span>
          </Link>
        </UserInitials>
      )
    }
  }

  return (
    <NavbarWrapper>
      <Menu phoneNumber={phoneNumber} stylistId={username} isUserSigned={false} />
      <StylistName>
        <Link to={`${ROUTES.STYLIST.replace(':stylist', username)}`}>{name}</Link>
      </StylistName>
      {getUserInitialView()}
    </NavbarWrapper>
  );
};
