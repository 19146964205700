import styled, { from, to, landscape } from 'app/theme';

export const Row = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  min-height: 100vh;

  ${to('large')} {
    flex-direction: column;
    justify-content: flex-start;
  }

  ${landscape()} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const Column = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;

  ${to('large')} {
    flex: 0;
  }

  ${from('large')} {
    .profile-wrapper {
      height: 100vh;
      left: 0;
      overflow: auto;
      position: fixed;
      top: 0;
      width: 50vw;
    }
  }
`;
