import React from 'react';

import useModal from 'app/utils/useModal';
import Modal from 'app/components/modal/Modal';

import { ModalContent } from '../styles/CardAuthorizationStyles';
import { CheckOptionView } from 'app/components/CheckOptionView';

export type CardAuthorizationProps = {
  name: string,
  checked: boolean,
  onCheckChange: (checked: boolean) => void
}

export const CardAuthorization = ({ name, checked, onCheckChange }: CardAuthorizationProps) => {
  const { isShowing, toggle } = useModal();
  return (
    <div style={{
        margin: '0',
        width: '100%'
      }}>
      <CheckOptionView 
        name={name}
        required={false}
        checked={checked} 
        onChange={() => {
          onCheckChange(!checked)
        }}>
          <p>
            I authorize storing this card for future use until I <span onClick={toggle}>cancel this authorization</span>.
          </p>
          <Modal hide={toggle} isShowing={isShowing} title="Canceling card authorization">
            <ModalContent>
              <p>You can manage your saved cards at any time by viewing your profile and clicking the "Cards" tab.</p>
            </ModalContent>
          </Modal>
      </CheckOptionView>
    </div>
  );
};
