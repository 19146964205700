import styled, { css, to } from 'app/theme';
import { ServiceItemView } from 'app/components/ServiceItemView';
import { BookingContext } from 'app/context/BookingContext';
import { StylistContext } from 'app/context/StylistContext';
import { EmptyList } from 'app/styles/ListStyles';
import { Wrapper } from 'app/styles/BookingWrapperStyles';
import React, { Fragment, useContext } from 'react';
import { Form } from 'react-final-form';
import { Button } from 'app/components/button/Button';
import { groupServicesInCategories } from '../utils/groupServicesInCategories';

export const CategoryName = styled.div`
  ${({ theme: { variables } }) => css`
    border-top: 1px solid ${variables.colors.lightGray};
    box-sizing: border-box;
    font-size: ${variables.fonts.size.smaller0};
    color: ${variables.colors.mediumGray};
    line-height: 24px;
    padding: ${variables.margins.marginNormal} 0;
    width: 475px;

    ${to('large')} {
      padding-left: ${variables.margins.marginNormalTwice};
      width: 100%;
    }
   
    &:first-child {
      border-top: none;
    }

  `}
`;

export type ServicesContainerProps = {
  onSubmit: () => void
}

export const ServicesContainer = ({ 
  onSubmit
}: ServicesContainerProps) => {

  // Note:
  // This container does not need a loading or error state
  
  // Reason: 
  // The loading of the services is done 
  // before this container is loaded

  // Get values from stylist context
  const bookingContext = useContext(BookingContext)
  const stylistContext = useContext(StylistContext)
  const stylist = stylistContext.stylist
  const { categories, services } = stylistContext.services

  if (services.length === 0) {
    return (
      <EmptyList>
        <p>Services are not available to book yet.</p>
        <p>Please call or email {stylist.profile.firstName}.</p>
      </EmptyList>
    )
  }

  const groupedServices = groupServicesInCategories(services, categories)

  return (
    <Wrapper>
      <Form
        initialValues={{
          services: bookingContext.services
        }}
        onSubmit={() => {}}
        render={({ values }) => {

          // Update the booking context values
          bookingContext.setServices(values.services)

          return (
            <form>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '50px'
              }}>
                {groupedServices.map((group) => {
                  return (
                    <Fragment key={group.id}>
                      {group.displayCategory && <CategoryName>{group.name}</CategoryName>}
                        {group.services.map((service: any) => (
                          <ServiceItemView 
                            key={service.id}
                            service={service} />
                        ))}
                    </Fragment>
                  )
                })}
              </div>
              <Button 
                type="button" 
                isSticky={true}
                disabled={bookingContext.services.length === 0}
                onClick={() => onSubmit()}>
                Next
              </Button>
            </form>
          )
        }}
      />
    </Wrapper>
  )

}
