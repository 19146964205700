const MORE_SERVICES_ID = 'more-services';

interface CategorizedServices {
  [categoryId: string]: any[];
}

export const groupServicesInCategories = (services: any[], categories: any[]) => {
  const categorizedServices = services.reduce<CategorizedServices>((accCategorizedServices, service) => {
    if (service.categories.length === 0) {
      const moreServicesCategory = accCategorizedServices[MORE_SERVICES_ID] || [];
      accCategorizedServices[MORE_SERVICES_ID] = [...moreServicesCategory, service];

      return accCategorizedServices;
    }

    service.categories.forEach((category: any) => {
      const serviceCategory = accCategorizedServices[category.id] || [];
      accCategorizedServices[category.id] = [...serviceCategory, service];
    });

    return accCategorizedServices;
  }, {});

  const categoryList = categories.reduce<any[]>((accCategoryList, category) => {
    if (categorizedServices[category.id]) {
      accCategoryList.push({
        ...category,
        displayCategory: true,
        services: categorizedServices[category.id],
      });
    }

    return accCategoryList;
  }, []);

  if (categorizedServices[MORE_SERVICES_ID]) {
    categoryList.push({
      displayCategory: categoryList.length > 0,
      id: MORE_SERVICES_ID,
      name: 'More Services',
      services: categorizedServices[MORE_SERVICES_ID],
    });
  }

  return categoryList;
};
