import { BreakpointKey, Theme as ThemeInterface } from '../Theme.types';

export const from = (breakpoint: BreakpointKey | null) => ({ theme }: { theme: ThemeInterface }) => {
  if (breakpoint === null) return '@media (min-width: 0px)';

  if (theme.variables.breakpoints[breakpoint] === undefined) {
    throw new Error('Undefined breakpoint');
  }

  return `@media (min-width: ${theme.variables.breakpoints[breakpoint]}px)`;
};
