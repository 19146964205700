import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { DayPickerSingleDateController, isSameDay } from 'react-dates';
import 'react-dates/initialize';
import { renderErrors } from 'app/utils';
import { hasError } from 'app/utils/hasError';

import { DatePickerWrapper, DesktopDatePicker, IconCalendar, IconChevron, NavNext, NavPrev } from './Datepicker.styles';
import { DatePickerProps, DatePickerState } from './Datepicker.types';

export class DatePicker extends Component<DatePickerProps, DatePickerState> {
  static defaultProps: Partial<DatePickerProps> = {
    autoFocus: false,
    displayFormat: 'DD.MM.YYYY',
    icon: IconCalendar,
    initialDate: null,
    placeholder: '',
    useNativeDate: false,
  };

  state: DatePickerState = { focused: false, date: new Date() };

  nextButtonRef = React.createRef<HTMLButtonElement>();
  prevButtonRef = React.createRef<HTMLButtonElement>();

  touchStartX: number = 0;
  touchStartY: number = 0;
  tapAccuracy = 10;

  handleDatepickerTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    this.touchStartX = event.changedTouches[0].screenX;
    this.touchStartY = event.changedTouches[0].screenY;
  };

  handleDatepickerSwipe = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!this.nextButtonRef.current || !this.prevButtonRef.current) {
      return;
    }

    const touchEndX = event.changedTouches[0].screenX;
    const touchEndY = event.changedTouches[0].screenY;
    const isTap =
      Math.abs(this.touchStartX - touchEndX) <= this.tapAccuracy &&
      Math.abs(this.touchStartY - touchEndY) <= this.tapAccuracy;
    // NOTE: we can also add here some condition to narrow the Y delta between swipe start and
    // end point. This could prevent from unexpected vertical scrolling the page while swiping
    // horizontally
    const isVerticalSwipe = Math.abs(this.touchStartX - touchEndX) < Math.abs(this.touchStartY
      - touchEndY);

    if (isTap || isVerticalSwipe) {
      return;
    }

    event.preventDefault();

    if (touchEndX < this.touchStartX) {
      this.nextButtonRef.current.click();
    } else if (touchEndX > this.touchStartX) {
      this.prevButtonRef.current.click();
    }
  };

  dateToOutput = (date: Moment | null): Date | Moment | null => {
    if (this.props.useNativeDate && moment.isMoment(date)) {
      return date.toDate();
    }

    return date;
  };

  dateToMoment = (date: Date | Moment | null): Moment => {
    if (!date) {
      return moment();
    }

    if (!moment.isMoment(date)) {
      return moment(date);
    }

    return date as Moment;
  };

  makeIsOutsideRange = () => {
    const maxDate = this.props.endDate && this.dateToMoment(this.props.endDate);
    const minDate = this.props.startDate && moment(this.props.startDate).subtract(1, 'days');

    return (date: Moment | Date) => {
      if (!date) {
        return false;
      }

      const momentDate = this.dateToMoment(date);

      return (maxDate && maxDate.isBefore(momentDate)) || (minDate && minDate.isAfter(momentDate));
    };
  };

  makeClosedDays = () => {
    const openDays = this.props.dates && this.props.dates.map(el => moment(el.startingAt));

    return (date: Moment | Date) => {
      const momentDate = this.dateToMoment(date);
      if (date && openDays) {
        return !openDays.some(el => isSameDay(el, momentDate));
      }

      return false;
    };
  };

  onDateChange = (date: Moment | null) => {
    this.setState({ date });
    if (date && this.makeIsOutsideRange()(date)) {
      return;
    }
    this.props.onDateChange(this.dateToOutput(date));
  };

  onFocusChange = () => {
    this.setState({ focused: true });
  };

  hasError = (): boolean => {
    return hasError(this.props.meta, this.props.disabled);
  };

  render() {
    const isOutsideRange = this.makeIsOutsideRange();
    const startDate = this.dateToMoment(this.state.date);
    const isClosed = this.makeClosedDays();

    return (
      <DatePickerWrapper>
        <DesktopDatePicker
          data-testid="date-picker__wrapper"
          hasError={this.hasError()}
          onTouchStart={this.handleDatepickerTouchStart}
          onTouchEnd={this.handleDatepickerSwipe}
        >
          <DayPickerSingleDateController
            onDateChange={this.onDateChange}
            onFocusChange={this.onFocusChange}
            focused={this.state.focused}
            date={startDate}
            daySize={30}
            navPrev={
              <NavPrev ref={this.prevButtonRef}>
                <IconChevron />
              </NavPrev>
            }
            navNext={
              <NavNext ref={this.nextButtonRef}>
                <IconChevron />
              </NavNext>
            }
            noBorder={true}
            hideKeyboardShortcutsPanel={true}
            numberOfMonths={1}
            renderCalendarDay={undefined}
            isDayBlocked={isOutsideRange}
            isDayHighlighted={() => false}
            isOutsideRange={isClosed}
            enableOutsideDays={false}
          />
          {this.hasError() && <div>{renderErrors(this.props.meta.error, this.props.meta.submitError)}</div>}
        </DesktopDatePicker>
      </DatePickerWrapper>
    );
  }
}
