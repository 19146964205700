import React from 'react';

import { AvatarImage, AvatarWrapper } from './Avatar.styles';
import { AvatarProps } from './Avatar.types';

export const Avatar = ({ image }: AvatarProps) => {
  return (
    <AvatarWrapper className="profile__avatar">
      <AvatarImage src={image} />
    </AvatarWrapper>
  );
};
