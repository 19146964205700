import React, { Fragment, useContext } from 'react';
import { StylistContext } from 'app/context/StylistContext';
import { Layout } from 'app/components/layout/Layout';
import { Column, Row } from 'app/styles/MainLayoutStyles';
import { StylistProfile } from 'app/components/profile/StylistProfile';
import { Elements, StripeProvider } from 'react-stripe-elements';
import OrderFrom from 'app/components/OrderForm';

export const BookingContainer = () => {

  const stylistContext = useContext(StylistContext)
  const stylist = stylistContext.stylist

  return (
    <Fragment>
      <Layout>
          <Row>
            <Column id="stylist-profile">
              <StylistProfile
                stylist={stylist}
              />
            </Column>
            <Column id="content-column">
              <StripeProvider apiKey={stylist.profile.cardSettings.apiKey}>
                <Column id="content-column">
                  <Elements>
                    <OrderFrom
                      stylist={stylist}
                    />
                  </Elements>
                </Column>
              </StripeProvider>
            </Column>
          </Row>
        </Layout>
    </Fragment>
  )
}
