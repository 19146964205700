export const ROUTES = {
  APPOINTMENT: '/a/:appointmentId',
  BOOKING: '/:stylist/booking/:step',
  BOOKING_NO_STEP: '/:stylist/booking',
  LOGOUT: '/:stylist/user/logout',
  SIGNIN: '/:stylist/user/signin',
  SIGNIN_CODE: '/:stylist/user/signin-code',
  STYLIST: '/:stylist',
  USER_ADD_CARD: '/:stylist/user/add-card',
  USER_ADD_USER: '/:stylist/user/add-user',
  USER_APPOINTMENTS: '/:stylist/user/appointments',
  USER_APPOINTMENTS_PAST: '/:stylist/user/appointments/past',
  USER_APPOINTMENTS_UPCOMING: '/:stylist/user/appointments/upcoming',
  USER_CARDS: '/:stylist/user/cards',
  USER: '/:stylist/user',
}
