import { SocialMediaDisplayItem, SocialMediaUrls, SocialMediaUrlType } from './Social.types';

import { EMAIL_MEDIA_ITEM, MEDIA_ITEMS } from './consts';

const createMediaItem = (url: string, type: SocialMediaUrlType): SocialMediaDisplayItem => ({
  ...MEDIA_ITEMS[type],
  url,
});

export const doesContainProfileName = (socialMediaUrl: string): boolean => {
  const urlParts = socialMediaUrl.split('://');
  if (urlParts.length !== 2) {
    return false;
  }

  let url = urlParts[1];
  if (url[url.length - 1] === '/') {
    url = url.slice(0, -1);
  }

  return url.includes('/');
};

export const getSocialMediaArray = (emailAddress: string, social: SocialMediaUrls): SocialMediaDisplayItem[] => {
  const socialMediaItems: SocialMediaDisplayItem[] = Object.entries(social).reduce<SocialMediaDisplayItem[]>(
    (socialMediaUrls, [socialMediaType, socialMediaUrl]) => {
      if (socialMediaUrl && doesContainProfileName(socialMediaUrl)) {
        socialMediaUrls.push(createMediaItem(socialMediaUrl, socialMediaType as SocialMediaUrlType));
      }

      return socialMediaUrls;
    },
    [],
  );

  if (emailAddress) {
    socialMediaItems.unshift({
      ...EMAIL_MEDIA_ITEM,
      url: `mailto:${emailAddress}?subject=Tanto Website Inquiry`,
    });
  }

  return socialMediaItems;
};
