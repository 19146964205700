import React from 'react'
import styled, { css } from 'app/theme';

export const ErrorViewStyles = styled.div`
    ${({ theme: { variables } }) => css`
        background-color: ${variables.colors.red};=
        margin-top: 24px;
        margin-bottom: 24px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 475px;
        font-weight: 500;
        font-size: 14px;
        color: ${variables.colors.white};

        p {
            padding: 24px;
            line-height: 1.5;
        }

    `}
`;

export type ErrorViewProps = {
    message: string
}

export const ErrorView = ({ 
    message
}: ErrorViewProps) => {

    return (
        <ErrorViewStyles>
            <p>{message}</p>
        </ErrorViewStyles>
    )

}