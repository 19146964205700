import arrowRight from 'assets/icons/arrow-right.svg';
import styled, { to } from 'app/theme';

export const MobileDatePicker = styled.div`
  display: none;
  position: relative;
  height: 2rem;

  ${to('medium')} {
    display: block;
  }
`;

export const NativeInput = styled.input<{ hasError: boolean }>`
  border: none;
  outline: none;
  border-bottom: 1px solid ${props => props.theme.variables.colors.gray};
  font-family: OpenSans;
  font-size: 1rem;
  line-height: 2rem;
  padding: 0rem 1rem;
  &:focus {
    outline: none;
    border-bottom: 1px solid ${props => props.theme.variables.colors.primary};
    margin-bottom: -0.0625rem;
  }
`;

export const PrefixText = styled.p`
  margin-top: 0.8125rem;
  padding-right: 0.625rem;
`;

export const PrefixIcon = styled.div`
  padding-top: 0.625rem;
  padding-right: 0.5rem;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  > svg {
    display: block;
  }
`;

export const DatePickerWrapper = styled.div`
  font-family: ${p => p.theme.variables.typography.primaryFont};
  display: flex;
  flex-direction: row;
  min-width: 320px;
`;

export const NavButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  position: absolute;
  top: 0.875rem;
  border-radius: 50%;
  padding: 0.4375rem;
  cursor: pointer;
  background: url(${arrowRight}) no-repeat;
  background-position: center;
`;

export const NavPrev = styled(NavButton)`
  left: 1.375rem;
  transform: rotateZ(180deg);
`;

export const NavNext = styled(NavButton)`
  right: 1.375rem;
`;

export const IconChevron = styled.div`
  display: inline-block;
  height: 17px;
  width: 17px;
`;

export const IconCalendar = styled.div`
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
`;

export const DesktopDatePicker = styled.div<{ hasError: boolean }>`
  /* Original styles */
  .PresetDateRangePicker_panel {
    padding: 0 1.375rem 0.6875rem;
  }
  .PresetDateRangePicker_button {
    position: relative;
    height: 100%;
    text-align: center;
    background: 0 0;
    border: 0.125rem solid #00a699;
    color: #00a699;
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
    font: inherit;
    font-weight: 700;
    line-height: normal;
    overflow: visible;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
  }
  .PresetDateRangePicker_button:active {
    outline: 0;
  }
  .PresetDateRangePicker_button__selected {
    color: #fff;
    background: #00a699;
  }
  .SingleDatePickerInput {
    display: inline-block;
    background-color: #fff;
  }
  .SingleDatePickerInput__withBorder {
    border-radius: 0.125rem;
    border: 0.0625rem solid #dbdbdb;
  }
  .SingleDatePickerInput__rtl {
    direction: rtl;
  }
  .SingleDatePickerInput__disabled {
    background-color: #f2f2f2;
  }
  .SingleDatePickerInput__block {
    display: block;
  }
  .SingleDatePickerInput__showClearDate {
    padding-right: 1.875rem;
  }
  .SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 0.625rem;
    margin: 0 0.625rem 0 0.3125rem;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .SingleDatePickerInput_clearDate__default:focus,
  .SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%;
  }
  .SingleDatePickerInput_clearDate__small {
    padding: 0.375rem;
  }
  .SingleDatePickerInput_clearDate__hide {
    visibility: hidden;
  }
  .SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
    height: 0.75rem;
    width: 0.9375rem;
    vertical-align: middle;
  }
  .SingleDatePickerInput_clearDate_svg__small {
    height: 0.5625rem;
  }
  .SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 0.625rem;
    margin: 0 0.3125rem 0 0.625rem;
  }
  .SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 0.9375rem;
    width: 0.875rem;
    vertical-align: middle;
  }
  .SingleDatePicker {
    position: relative;
    display: inline-block;
  }
  .SingleDatePicker__block {
    display: block;
  }
  .SingleDatePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute;
  }
  .SingleDatePicker_picker__rtl {
    direction: rtl;
  }
  .SingleDatePicker_picker__directionLeft {
    left: 0;
  }
  .SingleDatePicker_picker__directionRight {
    right: 0;
  }
  .SingleDatePicker_picker__portal {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .SingleDatePicker_picker__fullScreenPortal {
    background-color: #fff;
  }
  .SingleDatePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.9375rem;
    z-index: 2;
  }
  .SingleDatePicker_closeButton:focus,
  .SingleDatePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none;
  }
  .SingleDatePicker_closeButton_svg {
    height: 0.9375rem;
    width: 0.9375rem;
    fill: #cacccd;
  }
  .DayPickerKeyboardShortcuts_buttonReset {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    font-size: 0.875rem;
  }
  .DayPickerKeyboardShortcuts_buttonReset:active {
    outline: 0;
  }
  .DayPickerKeyboardShortcuts_show {
    width: 1.375rem;
    position: absolute;
    z-index: 2;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight {
    border-top: 1.625rem solid transparent;
    border-right: 2.0625rem solid #00a699;
    bottom: 0;
    right: 0;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight:hover {
    border-right: 2.0625rem solid #008489;
  }
  .DayPickerKeyboardShortcuts_show__topRight {
    border-bottom: 1.625rem solid transparent;
    border-right: 2.0625rem solid #00a699;
    top: 0;
    right: 0;
  }
  .DayPickerKeyboardShortcuts_show__topRight:hover {
    border-right: 2.0625rem solid #008489;
  }
  .DayPickerKeyboardShortcuts_show__topLeft {
    border-bottom: 1.625rem solid transparent;
    border-left: 2.0625rem solid #00a699;
    top: 0;
    left: 0;
  }
  .DayPickerKeyboardShortcuts_show__topLeft:hover {
    border-left: 2.0625rem solid #008489;
  }
  .DayPickerKeyboardShortcuts_showSpan {
    color: #fff;
    position: absolute;
  }
  .DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: -1.75rem;
  }
  .DayPickerKeyboardShortcuts_showSpan__topRight {
    top: 0.0625rem;
    right: -1.75rem;
  }
  .DayPickerKeyboardShortcuts_showSpan__topLeft {
    top: 0.0625rem;
    left: -1.75rem;
  }
  .DayPickerKeyboardShortcuts_panel {
    overflow: auto;
    background: #fff;
    border: 0.0625rem solid #dbdbdb;
    border-radius: 0.125rem;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 1.375rem;
    margin: 2.0625rem;
  }
  .DayPickerKeyboardShortcuts_title {
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
  }
  .DayPickerKeyboardShortcuts_list {
    list-style: none;
    padding: 0;
    font-size: 0.875rem;
  }
  .DayPickerKeyboardShortcuts_close {
    position: absolute;
    right: 1.375rem;
    top: 1.375rem;
    z-index: 2;
  }
  .DayPickerKeyboardShortcuts_close:active {
    outline: 0;
  }
  .DayPickerKeyboardShortcuts_closeSvg {
    height: 0.9375rem;
    width: 0.9375rem;
    fill: #cacccd;
  }
  .DayPickerKeyboardShortcuts_closeSvg:focus,
  .DayPickerKeyboardShortcuts_closeSvg:hover {
    fill: #82888a;
  }
  .CalendarDay {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 0.875rem;
    text-align: center;
  }
  .CalendarDay:active {
    outline: 0;
  }
  .CalendarDay__defaultCursor {
    cursor: default;
  }
  .CalendarDay__default {
    border: 0.0625rem solid #e4e7e7;
    color: #484848;
    background: #fff;
  }
  .CalendarDay__default:hover {
    background: #e4e7e7;
    border: 0.0625rem double #e4e7e7;
    color: inherit;
  }
  .CalendarDay__hovered_offset {
    background: #f4f5f5;
    border: 0.0625rem double #e4e7e7;
    color: inherit;
  }
  .CalendarDay__outside {
    border: 0;
    background: #fff;
    color: #484848;
  }
  .CalendarDay__outside:hover {
    border: 0;
  }
  .CalendarDay__blocked_minimum_nights {
    background: #fff;
    border: 0.0625rem solid #eceeee;
    color: #cacccd;
  }
  .CalendarDay__blocked_minimum_nights:active,
  .CalendarDay__blocked_minimum_nights:hover {
    background: #fff;
    color: #cacccd;
  }
  .CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: #484848;
  }
  .CalendarDay__highlighted_calendar:active,
  .CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: #484848;
  }
  .CalendarDay__selected_span {
    background: #66e2da;
    border: 0.0625rem solid #33dacd;
    color: #fff;
  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: #33dacd;
    border: 0.0625rem solid #33dacd;
    color: #fff;
  }
  .CalendarDay__last_in_range {
    border-right: #00a699;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #00a699;
    border: 0.0625rem solid #00a699;
    color: #fff;
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: #b2f1ec;
    border: 0.0625rem solid #80e8e0;
    color: #007a87;
  }
  .CalendarDay__hovered_span:active {
    background: #80e8e0;
    border: 0.0625rem solid #80e8e0;
    color: #007a87;
  }
  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar:active,
  .CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    border: 0.0625rem solid #cacccd;
    color: #82888a;
  }
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background: #fff;
    border: 0.0625rem solid #e4e7e7;
    color: #cacccd;
  }
  .CalendarMonth {
    background: #fff;
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  .CalendarMonth_verticalSpacing {
    border-collapse: separate;
  }
  .CalendarMonth_caption {
    font-size: ${props => props.theme.variables.fonts.size.smaller15};
    color: ${props => props.theme.variables.colors.black};
    padding-top: 1.375rem;
    padding-bottom: 2.3125rem;
    caption-side: initial;
  }
  .CalendarMonth_caption__verticalScrollable {
    padding-top: 0.75rem;
    padding-bottom: 0.4375rem;
  }
  .CalendarMonthGrid {
    background: #fff;
    text-align: left;
    z-index: 0;
  }
  .CalendarMonthGrid__animating {
    z-index: 1;
  }
  .CalendarMonthGrid__horizontal {
    position: absolute;
    left: 0.5625rem;
  }
  .CalendarMonthGrid__vertical {
    margin: 0 auto;
  }
  .CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto;
    overflow-y: scroll;
  }
  .CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%;
  }
  .CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }
  .CalendarMonthGrid_month__hidden {
    visibility: hidden;
  }
  .DayPickerNavigation {
    position: relative;
    z-index: 2;
  }
  .DayPickerNavigation__horizontal {
    height: 0;
  }
  .DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 3.25rem;
    bottom: 0;
    left: 0;
  }
  .DayPickerNavigation__verticalScrollableDefault {
    position: relative;
  }
  .DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0;
  }
  .DayPickerNavigation_button__default {
    border: 0.0625rem solid #e4e7e7;
    background-color: #fff;
    color: #757575;
  }
  .DayPickerNavigation_button__default:focus,
  .DayPickerNavigation_button__default:hover {
    border: 0.0625rem solid #c4c4c4;
  }
  .DayPickerNavigation_button__default:active {
    background: #f2f2f2;
  }
  .DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 1.125rem;
    line-height: 0.78;
    border-radius: 0.1875rem;
    padding: 0.375rem 0.5625rem;
  }
  .DayPickerNavigation_leftButton__horizontalDefault {
    left: 1.375rem;
  }
  .DayPickerNavigation_rightButton__horizontalDefault {
    right: 1.375rem;
  }
  .DayPickerNavigation_button__verticalDefault {
    padding: 0.3125rem;
    background: #fff;
    box-shadow: 0 0 0.3125rem 0.125rem rgba(0, 0, 0, 0.1);
    position: relative;
    display: inline-block;
    height: 100%;
    width: 50%;
  }
  .DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0;
  }
  .DayPickerNavigation_nextButton__verticalScrollableDefault {
    width: 100%;
  }
  .DayPickerNavigation_svg__horizontal {
    height: 1.1875rem;
    width: 1.1875rem;
    fill: #82888a;
    display: block;
  }
  .DayPickerNavigation_svg__vertical {
    height: 2.625rem;
    width: 2.625rem;
    fill: #484848;
    display: block;
  }
  .DayPicker {
    background: #fff;
    position: relative;
    text-align: left;
  }
  .DayPicker__horizontal {
    background: #fff;
  }
  .DayPicker__verticalScrollable {
    height: 100%;
  }
  .DayPicker__hidden {
    visibility: hidden;
  }
  .DayPicker__withBorder {
    box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.05), 0 0 0 0.0625rem rgba(0, 0, 0, 0.07);
    border-radius: 0.1875rem;
  }
  .DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%;
  }
  .DayPicker_portal__vertical {
    position: initial;
  }
  .DayPicker_focusRegion {
    outline: 0;
  }
  .DayPicker_calendarInfo__horizontal,
  .DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top;
  }
  .DayPicker_weekHeaders {
    position: relative;
  }
  .DayPicker_weekHeaders__horizontal {
    margin-left: 0.5625rem;
  }
  .DayPicker_weekHeader {
    color: #757575;
    position: absolute;
    top: 3.875rem;
    z-index: 2;
    text-align: left;
  }
  .DayPicker_weekHeader__vertical {
    left: 50%;
  }
  .DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 0.0625rem solid #dbdbdb;
    background: #fff;
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .DayPicker_weekHeader_ul {
    list-style: none;
    margin: 0.0625rem 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 0.875rem;
  }
  .DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center;
  }
  .DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 0.1875rem;
  }
  .DayPicker_transitionContainer__horizontal {
    -webkit-transition: height 0.2s ease-in-out;
    -moz-transition: height 0.2s ease-in-out;
    transition: height 0.2s ease-in-out;
  }
  .DayPicker_transitionContainer__vertical {
    width: 100%;
  }
  .DayPicker_transitionContainer__verticalScrollable {
    padding-top: 1.25rem;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll;
  }
  .DateInput {
    margin: 0;
    padding: 0;
    background: #fff;
    position: relative;
    display: inline-block;
    width: 8.125rem;
    vertical-align: middle;
  }
  .DateInput__small {
    width: 6.0625rem;
  }
  .DateInput__block {
    width: 100%;
  }
  .DateInput__disabled {
    background: #f2f2f2;
    color: #dbdbdb;
  }
  .DateInput_input {
    font-weight: 200;
    font-size: 1.1875rem;
    line-height: 1.5rem;
    color: #484848;
    background-color: #fff;
    width: 100%;
    padding: 0.6875rem 0.6875rem 0.5625rem;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 0.125rem solid transparent;
    border-left: 0;
    border-radius: 0;
  }
  .DateInput_input__small {
    font-size: 0.9375rem;
    line-height: 1.125rem;
    letter-spacing: 0.0125rem;
    padding: 0.4375rem 0.4375rem 0.3125rem;
  }
  .DateInput_input__regular {
    font-weight: auto;
  }
  .DateInput_input__readOnly {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .DateInput_input__focused {
    outline: 0;
    background: #fff;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 0.125rem solid #008489;
    border-left: 0;
  }
  .DateInput_input__disabled {
    background: #f2f2f2;
    font-style: italic;
  }
  .DateInput_screenReaderMessage {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 0.0625rem;
    margin: -0.0625rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0.0625rem;
  }
  .DateInput_fang {
    position: absolute;
    width: 1.25rem;
    height: 0.625rem;
    left: 1.375rem;
    z-index: 2;
  }
  .DateInput_fangShape {
    fill: #fff;
  }
  .DateInput_fangStroke {
    stroke: #dbdbdb;
    fill: transparent;
  }
  .DateRangePickerInput {
    background-color: #fff;
    display: inline-block;
  }
  .DateRangePickerInput__disabled {
    background: #f2f2f2;
  }
  .DateRangePickerInput__withBorder {
    border-radius: 0.125rem;
    border: 0.0625rem solid #dbdbdb;
  }
  .DateRangePickerInput__rtl {
    direction: rtl;
  }
  .DateRangePickerInput__block {
    display: block;
  }
  .DateRangePickerInput__showClearDates {
    padding-right: 1.875rem;
  }
  .DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #484848;
  }
  .DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #484848;
    height: 1.5rem;
    width: 1.5rem;
  }
  .DateRangePickerInput_clearDates {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 0.625rem;
    margin: 0 0.625rem 0 0.3125rem;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .DateRangePickerInput_clearDates__small {
    padding: 0.375rem;
  }
  .DateRangePickerInput_clearDates_default:focus,
  .DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 50%;
  }
  .DateRangePickerInput_clearDates__hide {
    visibility: hidden;
  }
  .DateRangePickerInput_clearDates_svg {
    fill: #82888a;
    height: 0.75rem;
    width: 0.9375rem;
    vertical-align: middle;
  }
  .DateRangePickerInput_clearDates_svg__small {
    height: 0.5625rem;
  }
  .DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 0.625rem;
    margin: 0 0.3125rem 0 0.625rem;
  }
  .DateRangePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 0.9375rem;
    width: 0.875rem;
    vertical-align: middle;
  }
  .DateRangePicker {
    position: relative;
    display: inline-block;
  }
  .DateRangePicker__block {
    display: block;
  }
  .DateRangePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute;
  }
  .DateRangePicker_picker__rtl {
    direction: rtl;
  }
  .DateRangePicker_picker__directionLeft {
    left: 0;
  }
  .DateRangePicker_picker__directionRight {
    right: 0;
  }
  .DateRangePicker_picker__portal {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .DateRangePicker_picker__fullScreenPortal {
    background-color: #fff;
  }
  .DateRangePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.9375rem;
    z-index: 2;
  }
  .DateRangePicker_closeButton:focus,
  .DateRangePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none;
  }
  .DateRangePicker_closeButton_svg {
    height: 0.9375rem;
    width: 0.9375rem;
    fill: #cacccd;
  }

  /* Custom styles */
  * {
    font-family: ${p => p.theme.variables.typography.primaryFont};
    box-sizing: border-box;
    text-transform: uppercase;
  }

  & {
    .DayPickerNavigation__horizontal {
      left: -1rem;
    }
    .CalendarMonthGrid__horizontal {
      left: -0.5625rem;
    }
    .CalendarMonth_caption strong {
      font-size: ${props => props.theme.variables.fonts.size.base};
      font-weight: ${props => props.theme.variables.fonts.weight.bold};
    }

    .DayPicker_weekHeader_ul small {
      font-size: ${props => props.theme.variables.fonts.size.smaller1};
    }

    .CalendarDay__default {
      vertical-align: middle;
      font-size: ${p => p.theme.variables.fonts.size.smaller1};
      color: ${p => p.theme.variables.colors.black};
      &,
      &:hover,
      &:active {
        border: none;
        border-radius: 50%;
        outline: none;
        font-weight: ${p => p.theme.variables.fonts.weight.bold};
      }
      &:hover,
      &:active {
        color: ${p => p.theme.variables.colors.white};
        background: ${p => p.theme.variables.colors.primary};
      }

      ${to('large')} {
        &:hover {
          background: initial;
          color: initial;
        }
      }
    }
    .CalendarDay__today {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 50%;
      color: ${p => p.theme.variables.colors.black};
    }

    .CalendarDay__selected {
      background: #fff;
      border: none;
      &,
      &:hover,
      &:active {
        color: ${p => p.theme.variables.colors.white};
        background: ${p => p.theme.variables.colors.primary};
      }
      &:hover,
      &:active {
        background: ${p => p.theme.variables.colors.primary};
      }
    }

    .CalendarMonth_table {
      border-collapse: separate;
      border-spacing: 13px;
    }

    .DayPicker_focusRegion {
      width: 360px;
      height: 290px;
    }

    .DayPicker_transitionContainer {
      width: auto !important;
      height: 295px !important;
    }
    .DayPicker_weekHeader_li:first-child {
      margin-right: -4px;
    }

    .DayPicker_weekHeader_li:not(:first-child) {
      padding: 0 1.35rem;
    }

    .DayPicker_weekHeader_ul {
      width: 360px;
    }

    .CalendarDay__blocked_calendar,
    .CalendarDay__blocked_calendar:active,
    .CalendarDay__blocked_calendar:hover {
      background: none;
      border: none;
      color: ${p => p.theme.variables.colors.gray};
    }
    .CalendarDay__blocked_out_of_range {
      text-decoration: line-through;
      color: ${p => p.theme.variables.colors.gray};
    }
  }
`;
