import { useStylistRepository } from 'app/repository/StylistRepository';
import React from 'react';
import { Redirect } from 'react-router';
import { ROUTES } from './routes';

export const RedirectToUser = () => {
  const stylistRepo = useStylistRepository()
  const username = stylistRepo.getStylistUsername()
  return <Redirect to={ROUTES.USER_APPOINTMENTS_UPCOMING.replace(':stylist', username)} />
}
