import { STYLIST_USERNAME } from "app/utils/LocalStorageKeys"
import { get } from "app/utils/Requests"

export const useStylistRepository = () => {

    const getStylist = (username: string) => {
        return get(`stylists/${username}/profile`)
    }

    const getServices = (username: string) => {
        return get(`stylists/${username}/services`)
    }

    const getAvailability = (username: string, minDuration: number) => {
        return get(`stylists/${username}/availability/?minDuration=${minDuration}`)
    }

    const setStylistUsername = (username: string) => {
        localStorage.setItem(STYLIST_USERNAME, username)
    }

    const getStylistUsername = () => {
        return localStorage.getItem(STYLIST_USERNAME) || ''
    }

    return {
        getStylist,
        getServices,
        getAvailability,
        setStylistUsername,
        getStylistUsername
    }

}