import React from 'react';
import styled, { css } from 'app/theme';

export const LoaderWrapper = styled.div<{ small: boolean }>`
  ${({ small, theme: { variables } }) => css`
    align-items: center;
    background: ${variables.colors.white};
    color: ${variables.colors.mediumGray};
    font-weight: 600;
    display: flex;
    flex-flow: column;
    font-size: 14px;
    height: 100vh;
    justify-content: center;
    text-transform: uppercase;
    width: 100vw;
    z-index: 99999;
    position: fixed;

    .loading {
      margin-top: ${variables.margins.marginNormal};
    }

    .spinner-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    .spinner {
      animation: rotate 2s linear infinite;
      height: 32px;
      margin: -25px 0 0 -25px;
      width: 32px;
      margin: 0 auto;

      & .path {
        animation: dash 1s ease-in-out infinite;
        stroke-linecap: round;
        stroke: ${variables.colors.primary};
      }
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
      }
    }
  `}
`;

export const Loader = () => (
  <LoaderWrapper small={true}>
    <div className="spinner-wrapper">
      <svg className="spinner" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
      </svg>
      <div className="loading">Loading</div>
    </div>
  </LoaderWrapper>
)
