import { isIE } from "app/utils/isIE";
import { isMobile } from "app/utils/isMobile";

/* tslint:disable: object-literal-sort-keys */
export const STEPS = {
  SELECT_SERVICE: 1,
  SELECT_DATE: 2,
  ENTER_PHONE_NUMBER: 3,
  ENTER_SIGNIN_CODE: 4,
  SELECT_USER: 5,
  ENTER_PAYMENT: 6,
  THANK_YOU: 7,
}

export const APPOINTMENT_TYPES = {
  PAST: 'past',
  UPCOMING: 'upcoming',
}

export const EMPTY_LIST_MESSAGES = {
  [APPOINTMENT_TYPES.PAST]: 'You have no past appointments.',
  [APPOINTMENT_TYPES.UPCOMING]: 'You have no upcoming appointments.',
}

export const HOUR_FORMAT = 'h:mm a'

export const MENU_BAR_HEIGHT = 50;
export const MENU_BAR_HEIGHT_WITH_PADDING = MENU_BAR_HEIGHT + 20;

export const IS_MOBILE = isMobile();
export const IS_IE = isIE();
