import styled, { css, to, from, landscape } from 'app/theme';

export const MenuWrapper = styled.div`
  display: block;
  z-index: 100;
  width: 60px;

  img {
    cursor: pointer;
  }

  .hide-on-desktop {
    display: block;

    ${from('medium')} {
      display: none;
    }
  }

  .hide-on-mobile {
    display: none;
  }

  .close-button {
    height: 24px;
    width: 24px;
  }
`;

export const MenuList = styled.div`
  ${({ theme: { variables } }) => css`
    background: ${variables.colors.white};
    border: 1px solid ${variables.colors.lightGray};
    border-radius: 4px;
    box-shadow: 0 20px 40px 0 ${variables.colors.blackTransparent};
    box-sizing: border-box;
    color: ${variables.colors.black};
    font-weight: 600;
    left: 0;
    padding: 5px 0;
    position: absolute;
    text-align: left;
    top: 60px;
    width: 200px;
    z-index: 100;

    ${from('large')} {
      top: 80px;
      left: 20px;
    }

    ${landscape()} {
      left: env(safe-area-inset-left);
    }

    .mobile-overlay {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1040;
      width: 100vw;
      height: 100vh;
      background-color: rgba(25, 26, 28, .7);
    }

    ul {
      list-style: none;
      padding: 0 ${variables.margins.marginBig};

      li {
        line-height: 40px;

        &.primary-color {
          color: ${variables.colors.primary};
        }

        &:hover,
        &:active {
          color: ${variables.colors.primary};
        }
      }
    }

    ul + ul {
      border-top: 1px solid ${variables.colors.neutral1};
      margin-top: ${variables.margins.marginNormal};
      padding-top: ${variables.margins.marginNormal};
    }

    .mobile-header {
      display: none;
    }

    ${to('medium')} {
      border-top: 1px solid ${variables.colors.lightGray};
      border-radius: 0;
      padding: 20px;
      top: 50px;
      width: 100%;

      ul li {
        line-height: 40px;
        text-align: center;
      }
    }
  `}
`;

export const MenuButtonWrapper = styled.div`
  width: 25px;
`;

export const MenuCloseActionWrapper = styled.div`
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 10;
  width: 50vw;

  ${to('large')} {
    width: 100vw;
  }
`;
