import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router';
import { BookingContext } from 'app/context/BookingContext';
import { PhoneNumberForm } from 'app/components/PhoneNumberForm';
import { useUserRepository } from 'app/repository/UserRepository';
import firebase from 'firebase/app';
import { UserContext } from 'app/context/UserContext';
import { STEPS } from 'app/utils/consts';
import { useStylistRepository } from 'app/repository/StylistRepository';
import { ErrorView } from 'app/components/ErrorView';
import { LoadingView } from 'app/components/LoadingView';
import { Wrapper } from 'app/styles/BookingWrapperStyles';
import { catchError } from 'app/utils/analytics';

export type SignInPhoneContainerProps = {
  isUserBooking: boolean,
  onPhoneNumberSubmit: () => void,
  onUserSignedIn: () => void
}

export const SignInPhoneContainer = ({ 
  isUserBooking,
  onPhoneNumberSubmit,
  onUserSignedIn
}: SignInPhoneContainerProps) => {


  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>()

  const { services, date } = useContext(BookingContext)
  const userContext = useContext(UserContext)

  const userRepo = useUserRepository()
  const stylistRepo = useStylistRepository()
  const username = stylistRepo.getStylistUsername()

  // Redirect
  if (isUserBooking) {
    if (!services) {
      return <Redirect to={`/${username}/booking/${STEPS.SELECT_SERVICE}/`} />
    } else if (!date) {
      return <Redirect to={`/${username}/booking/${STEPS.SELECT_DATE}/`} />
    }
  }

  // User is signed in
  // We can drop
  if (firebase.auth().currentUser) {
    onUserSignedIn()
    return <></>
  }

  async function signInWithPhone(phoneNumber: string) {
    setIsLoading(true)
    try {
      const result = await userRepo.signInWithPhone(phoneNumber)
      userContext.setConfirmationResult(result)
      userContext.setCurrentPhoneNumber(phoneNumber)
      setIsLoading(false)
      onPhoneNumberSubmit()
    } catch (error) {
      setError(catchError('sign_in_with_phone', error.message))
      setIsLoading(false)
    }
  }

  const getContent = () => {
    return <PhoneNumberForm onSubmitPhone={(values) => {
      signInWithPhone(values.phoneNumber)
    }} />
  }

  return (
    <Wrapper>
      {error && <ErrorView message={error.message} />}
      {isLoading ? (
        <LoadingView />
      ) : (
        getContent()
      )}
    </Wrapper>
  )
}
