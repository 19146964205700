import styled, { css, from } from 'app/theme';

export const NavbarWrapper = styled.div`
  ${({ theme: { variables } }) => css`
    align-items: center;
    box-sizing: border-box;
    background-color: ${variables.colors.white};
    box-shadow: 0 2px 4px 0 ${variables.colors.lightGrayTransparent};
    color: ${variables.colors.black};
    display: flex;
    flex-direction: row;
    height: 50px;
    font-size: ${variables.fonts.size.smaller1};
    justify-content: center;
    left: 0;
    padding: ${variables.margins.marginBig};
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;

    ${from('large')} {
      border-radius: 4px;
      left: 20px;
      width: calc(50% - 40px);
      top: 20px;
    }

    .hide-on-desktop {
      display: block;
    }
  `}
`;

export const StylistName = styled.div`
  ${({ theme: { variables } }) => css`
    color: ${variables.colors.black};
    flex: 1;
    font-size: ${variables.fonts.size.smaller1};
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
  `}
`;

export const SignInLink = styled.div`
  ${({ theme: { variables } }) => css`
    color: ${variables.colors.primary};
    text-transform: uppercase;
    visibility: 'visible';
    width: 60px;
  `}
`;

export const LoadingUserInitials = styled.div`

  ${({ theme: { variables } }) => css`
    color: ${variables.colors.primary};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-transform: uppercase;
    visibility: 'visible';
    width: 60px;

    .loader {
      align-items: center;
      border-radius: 50%;
      display: flex;
      height: 28px;
      width: 28px;

      background: linear-gradient(180deg, #8D92A3, #ffffff);
      background-size: 400% 400%;
      -webkit-animation: Skeleton 2s ease infinite;
      -moz-animation: Skeleton 2s ease infinite;
      animation: Skeleton 2s ease infinite;
    }

    @-webkit-keyframes Skeleton {
      0%{background-position:50% 0%}
      50%{background-position:50% 100%}
      100%{background-position:50% 0%}
    }
    @-moz-keyframes Skeleton {
        0%{background-position:50% 0%}
        50%{background-position:50% 100%}
        100%{background-position:50% 0%}
    }
    @keyframes Skeleton {
        0%{background-position:50% 0%}
        50%{background-position:50% 100%}
        100%{background-position:50% 0%}
    }

  `}
`;

export const UserInitials = styled.div<{ isMissing: boolean }>`
  ${({ isMissing, theme: { variables } }) => css`
    color: ${variables.colors.primary};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-transform: uppercase;
    visibility: 'visible';
    width: 60px;

    a {
      align-items: center;
      background: ${isMissing ? variables.colors.mediumGray : variables.colors.primary};
      border-radius: 50%;
      color: ${variables.colors.white};
      display: flex;
      font-size: 11px;
      font-weight: bold;
      height: 28px;
      justify-content: center;
      width: 28px;
    }
  `}
`;
