
import styled, { css } from 'app/theme';

export const ModalContent = styled.div`
  ${({ theme: { variables } }) => css`
    border-top: 1px solid ${variables.colors.neutral1};
    font-size: 14px;
    font-weight: normal;
    padding: ${variables.margins.marginBig};

    p + p {
      margin-top: ${variables.margins.marginBig};
    }

    strong {
      font-weight: bold;
    }

    p {
      width: 100%;
    }

  `}
`;
