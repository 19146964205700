import { SocialMediaDisplayDictionary } from './Social.types';

import facebookDark from 'assets/icons/facebook-dark.svg';
import facebook from 'assets/icons/facebook.svg';
import mailDark from 'assets/icons/mail-dark.svg';
import mail from 'assets/icons/mail.svg';
import instagramDark from 'assets/icons/instagram-dark.svg';
import instagram from 'assets/icons/instagram.svg';
import pinterestDark from 'assets/icons/pinterest-dark.svg';
import pinterest from 'assets/icons/pinterest.svg';
import twitterDark from 'assets/icons/twitter-dark.svg';
import twitter from 'assets/icons/twitter.svg';

export const EMAIL_MEDIA_ITEM = {
  alt: 'Mail',
  darkIcon: mailDark,
  lightIcon: mail,
};

const FACEBOOK_MEDIA_ITEM = {
  alt: 'Facebook',
  darkIcon: facebookDark,
  lightIcon: facebook,
};

const TWITTER_MEDIA_ITEM = {
  alt: 'Twitter',
  darkIcon: twitterDark,
  lightIcon: twitter,
};

const INSTAGRAM_MEDIA_ITEM = {
  alt: 'Instagram',
  darkIcon: instagramDark,
  lightIcon: instagram,
};

const PINTEREST_MEDIA_ITEM = {
  alt: 'Pinterest',
  darkIcon: pinterestDark,
  lightIcon: pinterest,
};

export const MEDIA_ITEMS: SocialMediaDisplayDictionary = {
  facebookUrl: FACEBOOK_MEDIA_ITEM,
  instagramUrl: INSTAGRAM_MEDIA_ITEM,
  pinterestUrl: PINTEREST_MEDIA_ITEM,
  twitterUrl: TWITTER_MEDIA_ITEM,
};
