import { get, post } from "app/utils/Requests"

export const useAppointmentRepository = () => {

    const getAppointment = async (id: string) => {
        return get(`appointments/${id}`)
    }

    const cancelAppointment = async (id: string) => {
        return post(`appointments/${id}/cancel`, {})
    }

    return {
        getAppointment,
        cancelAppointment
    }

}